import React from 'react';
import { Brain } from 'lucide-react';
import MetaTags from './MetaTags';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';

const AboutPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <MetaTags 
        title="About AI Is A Tool - Your Guide to AI Tools and Solutions"
        description="Discover our mission to make AI accessible to everyone. Learn how AI Is A Tool helps you find, compare, and choose the perfect AI solutions for your needs."
        content={`
          About AI Is A Tool
          Our Mission: Making AI accessible to everyone
          What We Do: Curate and categorize the best AI tools
          Our Values: 
          - Transparency
          - Unbiased reviews
          - User experience
          - Privacy protection
          - Innovation support
        `}
      />

      <SchemaMarkup 
        type="website"
        data={{
          "@context": "https://schema.org",
          "@type": "AboutPage",
          name: "About AI Is A Tool",
          description: "Learn about our mission to make AI accessible to everyone",
          publisher: {
            "@type": "Organization",
            name: "AI Is A Tool",
            url: window.location.origin,
            logo: {
              "@type": "ImageObject",
              url: `${window.location.origin}/logo.png` // Update with actual logo path
            },
            sameAs: [
              "https://twitter.com/aiisatool", // Add your social media URLs
              "https://linkedin.com/company/aiisatool"
            ]
          },
          mainEntity: {
            "@type": "Organization",
            name: "AI Is A Tool",
            description: "Your guide to discovering and comparing AI tools",
            foundingDate: "2024", // Update with actual founding date
            email: "info@aiisatool.com",
            address: {
              "@type": "PostalAddress",
              addressCountry: "UK"
            }
          }
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <div className="max-w-3xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <Brain className="h-8 w-8 text-indigo-400" />
          <h1 className="text-3xl font-bold">About Ai Is A Tool</h1>
        </div>

        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Our Mission</h2>
            <p>
              Ai Is A Tool was created with a simple yet powerful mission: to help people navigate
              the rapidly evolving landscape of artificial intelligence tools. We believe that AI
              should be accessible to everyone, and finding the right AI tool shouldn't be a challenge.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">What We Do</h2>
            <p>
              We curate and categorize the best AI tools available, making it easy for you to
              discover, compare, and choose the perfect solution for your needs. Our platform
              provides detailed information, honest reviews, and practical insights about each tool.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Our Values</h2>
            <ul className="list-disc list-inside space-y-2">
              <li>Transparency in everything we do</li>
              <li>Unbiased, honest tool reviews and comparisons</li>
              <li>Focus on user experience and accessibility</li>
              <li>Commitment to privacy and data protection</li>
              <li>Supporting innovation in AI technology</li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Join Our Community</h2>
            <p>
              We're more than just a directory - we're a community of AI enthusiasts, professionals,
              and learners. Whether you're just starting with AI or you're an experienced user,
              there's a place for you here.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;