import React from 'react';

interface AlertProps {
  variant?: 'default' | 'destructive';
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({ variant = 'default', children }) => {
  const variantClasses = variant === 'destructive' ? 'bg-red-500 text-white' : 'bg-gray-200 text-black';

  return (
    <div className={`p-4 rounded ${variantClasses}`}>
      {children}
    </div>
  );
};

interface AlertDescriptionProps {
  children: React.ReactNode;
}

export const AlertDescription: React.FC<AlertDescriptionProps> = ({ children }) => (
  <p className="mt-2 text-sm">
    {children}
  </p>
); 