import { db, storage, auth } from '../lib/firebase';
import { collection, addDoc, getDocs, query, where, orderBy, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Tool } from '../types/Tool';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../lib/firebase';

// Retry utility interface
interface RetryConfig {
  maxAttempts: number;
  delayMs: number;
  backoff?: boolean;
}

// Retry utility function
async function withRetry<T>(
  operation: () => Promise<T>,
  config: RetryConfig = { maxAttempts: 3, delayMs: 1000, backoff: true }
): Promise<T> {
  let lastError: Error;
  
  for (let attempt = 1; attempt <= config.maxAttempts; attempt++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error as Error;
      
      if (attempt === config.maxAttempts) {
        break;
      }

      const delay = config.backoff 
        ? config.delayMs * Math.pow(2, attempt - 1)
        : config.delayMs;

      console.log(`Attempt ${attempt} failed, retrying in ${delay}ms...`);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  throw lastError!;
}

// Utility function to fetch and store screenshot
async function fetchAndStoreScreenshot(screenshotUrl: string, toolName: string): Promise<string> {
  // Fetch the screenshot with retry
  const imageBlob = await withRetry(
    async () => {
      const response = await fetch(screenshotUrl);
      if (!response.ok) throw new Error(`Failed to fetch screenshot: ${response.statusText}`);
      return await response.blob();
    },
    { maxAttempts: 3, delayMs: 2000, backoff: true }
  );

  const fileName = `${toolName.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-screenshot.jpg`;
  const imageFile = new File([imageBlob], fileName, { type: 'image/jpeg' });

  // Upload to Firebase with retry
  return await withRetry(
    async () => {
      const imageRef = ref(storage, `tool-images/${Date.now()}-${fileName}`);
      const uploadResult = await uploadBytes(imageRef, imageFile);
      return await getDownloadURL(uploadResult.ref);
    },
    { maxAttempts: 3, delayMs: 2000, backoff: true }
  );
}

export const getAllTools = async (): Promise<Tool[]> => {
  return await withRetry(async () => {
    try {
      const toolsRef = collection(db, 'tools');
      const q = query(toolsRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.().toISOString() || new Date().toISOString()
      } as Tool));
    } catch (error) {
      console.error('Error getting tools:', error);
      return [];
    }
  }, { maxAttempts: 3, delayMs: 1000, backoff: true });
};

export const getFeaturedTools = async (): Promise<Tool[]> => {
  return await withRetry(async () => {
    try {
      const toolsRef = collection(db, 'tools');
      const q = query(toolsRef, where('featured', '==', true));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.().toISOString() || new Date().toISOString()
      } as Tool));
    } catch (error) {
      console.error('Error getting featured tools:', error);
      return [];
    }
  }, { maxAttempts: 3, delayMs: 1000, backoff: true });
};

export const getToolById = async (id: string): Promise<Tool | null> => {
  return await withRetry(async () => {
    try {
      const toolsRef = collection(db, 'tools');
      const q = query(toolsRef, where('id', '==', id));
      const snapshot = await getDocs(q);
      if (snapshot.empty) return null;
      const data = snapshot.docs[0].data();
      return {
        id: snapshot.docs[0].id,
        ...data,
        createdAt: data.createdAt?.toDate?.().toISOString() || new Date().toISOString()
      } as Tool;
    } catch (error) {
      console.error('Error getting tool:', error);
      return null;
    }
  }, { maxAttempts: 3, delayMs: 1000, backoff: true });
};

export const addTool = async (toolData: Partial<Tool>, imageFile?: File | null): Promise<string> => {
  return await withRetry(async () => {
    try {
      // Check authentication
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User must be authenticated');
      }

      // Verify admin status
      const token = await user.getIdTokenResult();
      if (!token.claims.admin) {
        throw new Error('User must have admin privileges');
      }

      let imageUrl = toolData.image;

      // If URL is provided, analyze it first
      if (toolData.url && (!toolData.description || !toolData.keyFeatures || !toolData.useCases)) {
        try {
          const analyzeToolContent = httpsCallable(functions, 'analyzeToolContent');
          const analysis = await analyzeToolContent({ url: toolData.url });
          const content = analysis.data.data as string;

          // Parse the response
          const description = content.match(/(?:description:?)([^]*?)(?=key features:)/i)?.[1]?.trim();
          const features = content.match(/(?:key features:?)([^]*?)(?=use cases:)/i)?.[1]
            ?.split(/[\n•-]/)
            .map(f => f.trim())
            .filter(Boolean);
          const cases = content.match(/(?:use cases:?)([^]*?)$/i)?.[1]
            ?.split(/[\n•-]/)
            .map(c => c.trim())
            .filter(Boolean);

          // Update toolData with analysis results
          toolData = {
            ...toolData,
            description: description || toolData.description,
            keyFeatures: features?.slice(0, 5) || toolData.keyFeatures,
            useCases: cases?.slice(0, 5) || toolData.useCases
          };
        } catch (error) {
          console.error('Error analyzing URL:', error);
        }
      }

      if (imageUrl?.includes('screenshot.abstractapi.com')) {
        try {
          imageUrl = await fetchAndStoreScreenshot(imageUrl, toolData.name || 'tool');
        } catch (error) {
          console.error('Error processing Abstract API screenshot:', error);
          imageUrl = null;
        }
      } else if (imageFile) {
        try {
          const fileName = `${Date.now()}-${imageFile.name}`;
          const imageRef = ref(storage, `tool-images/${fileName}`);
          
          const uploadResult = await withRetry(
            async () => await uploadBytes(imageRef, imageFile),
            { maxAttempts: 3, delayMs: 2000, backoff: true }
          );
          
          imageUrl = await getDownloadURL(uploadResult.ref);
        } catch (error) {
          console.error('Error uploading image file:', error);
          imageUrl = null;
        }
      }

      const toolWithDefaults = {
        ...toolData,
        image: imageUrl,
        createdAt: Timestamp.now(),
        status: toolData.status || 'active',
        reviewCount: toolData.reviewCount || 0,
        rating: toolData.rating || 0,
        featured: toolData.featured || false,
        verified: toolData.verified || false,
        tags: toolData.tags || [],
        keyFeatures: toolData.keyFeatures || [],
        useCases: toolData.useCases || []
      };

      const toolsRef = collection(db, 'tools');
      const docRef = await addDoc(toolsRef, toolWithDefaults);
      return docRef.id;
    } catch (error) {
      console.error('Error adding tool:', error);
      throw new Error(error instanceof Error ? error.message : 'Failed to add tool');
    }
  }, { maxAttempts: 2, delayMs: 3000, backoff: true });
};

export const getToolsByCategory = async (category: string): Promise<Tool[]> => {
  return await withRetry(async () => {
    try {
      const toolsRef = collection(db, 'tools');
      const q = query(toolsRef, 
        where('category', '==', category),
        where('status', '==', 'active')
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.().toISOString() || new Date().toISOString()
      } as Tool));
    } catch (error) {
      console.error('Error getting tools by category:', error);
      return [];
    }
  }, { maxAttempts: 3, delayMs: 1000, backoff: true });
};

export const getToolsBySubcategory = async (subcategory: string): Promise<Tool[]> => {
  return await withRetry(async () => {
    try {
      const toolsRef = collection(db, 'tools');
      const q = query(toolsRef, 
        where('subcategory', '==', subcategory),
        where('status', '==', 'active')
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate?.().toISOString() || new Date().toISOString()
      } as Tool));
    } catch (error) {
      console.error('Error getting tools by subcategory:', error);
      return [];
    }
  }, { maxAttempts: 3, delayMs: 1000, backoff: true });
};

export default {
  getAllTools,
  getFeaturedTools,
  getToolById,
  addTool,
  getToolsByCategory,
  getToolsBySubcategory
};