import React, { useEffect } from 'react';
import { generateSitemap, generateRobotsTxt } from '../services/sitemapService';

const SitemapGenerator: React.FC = () => {
  useEffect(() => {
    const baseUrl = window.location.origin;

    // Generate and save sitemap
    generateSitemap(baseUrl).then(sitemap => {
      const blob = new Blob([sitemap], { type: 'text/xml' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('link');
      link.rel = 'sitemap';
      link.href = url;
      document.head.appendChild(link);
    });

    // Generate and save robots.txt
    generateRobotsTxt(baseUrl).then(robotsTxt => {
      const blob = new Blob([robotsTxt], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('link');
      link.rel = 'robots';
      link.href = url;
      document.head.appendChild(link);
    });
  }, []);

  return null;
};

export default SitemapGenerator;