import { Tool } from '../types/Tool';
import { categories } from '../data/categories';
import { getAllTools } from './toolService';

interface SitemapUrl {
  url: string;
  lastmod?: string;
  changefreq?: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
  priority?: number;
}

export async function generateSitemap(baseUrl: string): Promise<string> {
  const urls: SitemapUrl[] = [];

  // Add static pages
  urls.push(
    { url: '/', priority: 1.0, changefreq: 'daily' },
    { url: '/about', priority: 0.8, changefreq: 'monthly' },
    { url: '/privacy', priority: 0.5, changefreq: 'monthly' },
    { url: '/terms', priority: 0.5, changefreq: 'monthly' },
    { url: '/contact', priority: 0.7, changefreq: 'monthly' }
  );

  // Add category pages
  categories.forEach(category => {
    urls.push({
      url: `/category/${category.id}`,
      priority: 0.9,
      changefreq: 'weekly'
    });
  });

  // Add tool pages
  const tools = await getAllTools();
  tools.forEach(tool => {
    urls.push({
      url: `/tool/${tool.id}`,
      lastmod: tool.createdAt,
      priority: 0.8,
      changefreq: 'weekly'
    });
  });

  // Generate XML
  const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
${urls.map(url => `  <url>
    <loc>${baseUrl}${url.url}</loc>
    ${url.lastmod ? `<lastmod>${url.lastmod}</lastmod>` : ''}
    ${url.changefreq ? `<changefreq>${url.changefreq}</changefreq>` : ''}
    ${url.priority ? `<priority>${url.priority}</priority>` : ''}
  </url>`).join('\n')}
</urlset>`;

  return sitemap;
}

export async function generateRobotsTxt(baseUrl: string): Promise<string> {
  return `User-agent: *
Allow: /

Sitemap: ${baseUrl}/sitemap.xml`;
}