import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Brain } from 'lucide-react';
import SearchBar from './SearchBar';

const Header: React.FC = () => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <header className="bg-gray-900/50 border-b border-gray-700 sticky top-0 z-30 backdrop-blur-xl">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between gap-8">
          {/* Logo Section */}
          <Link to="/" className="flex items-center gap-2 flex-shrink-0">
            <Brain className="h-6 w-6 text-indigo-400" />
            <span className="text-xl font-bold text-white">Ai Is A Tool</span>
          </Link>
          
          {/* Centered Search Bar */}
          <div className="flex-grow max-w-full md:max-w-md">
            <SearchBar 
              value={searchValue}
              onChange={setSearchValue}
              placeholder="Find AIs using AI..."
            />
          </div>

          {/* Tagline Section */}
          <div className="hidden md:block flex-shrink-0 text-sm text-gray-400 text-center">
            Your unfair advantage isn't AI. It's how you use it
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

