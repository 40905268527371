// src/components/AdminToolList.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Search } from 'lucide-react'; // Import Search icon

interface Tool {
  id: string;
  name: string;
  description: string;
}

const AdminToolList: React.FC = () => {
  const [tools, setTools] = useState<Tool[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const toolsCollection = collection(db, "tools");
        const toolsSnapshot = await getDocs(toolsCollection);
        const toolsList = toolsSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name || "Untitled",
          description: doc.data().description || "No description available"
        }));
        setTools(toolsList);
      } catch (error) {
        console.error("Error fetching tools:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTools();
  }, []);

  const filteredTools = tools.filter(tool => 
    tool.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tool.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold">Current Tools</h2>
          <Link 
            to="/admin-console/ai-tools/new" 
            className="bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded-lg text-sm"
          >
            New Tool
          </Link>
        </div>

        {/* Search Bar */}
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search tools..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full bg-gray-800 text-white px-4 py-2 pl-10 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        {loading ? (
          <div className="text-center py-12">Loading...</div>
        ) : filteredTools.length > 0 ? (
          <ul className="space-y-6">
            {filteredTools.map((tool) => (
              <li 
                key={tool.id} 
                className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col md:flex-row gap-4 items-center"
              >
                <div className="flex-1">
                  <h3 className="text-2xl font-semibold text-indigo-400">{tool.name}</h3>
                  <p className="text-sm text-gray-300 mt-2">
                    {tool.description.substring(0, 150)}... {/* Show snippet */}
                  </p>
                  <div className="mt-4">
                    <Link
                      to={`/admin-console/ai-tools/edit/${tool.id}`}
                      className="text-indigo-500 hover:text-indigo-400 text-sm font-medium"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400 text-center mt-8">
            {searchTerm ? 'No tools found matching your search.' : 'No tools available.'}
          </p>
        )}
      </div>
    </div>
  );
};

export default AdminToolList;



