// src/components/AdminToolEdit.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { categories, categoryGroups } from '../data/categories';
import { Star } from 'lucide-react';
import { Tool } from '../types/Tool';

const AdminToolEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Tool>({
    name: '',
    description: '',
    url: '',
    imageUrl: '',
    pricing: 'free',
    priceDetails: '',
    rating: 0,
    tags: [] as string[],
    keyFeatures: [] as string[],
    useCases: [] as string[],
    category: '',
    subcategory: [] as string[],
    featured: false,
    verified: false,
    status: 'pending',
    reviewCount: 0
  });

  const [newImage, setNewImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  
  // New state for array items
  const [newTag, setNewTag] = useState('');
  const [newFeature, setNewFeature] = useState('');
  const [newUseCase, setNewUseCase] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [availableSubcategories, setAvailableSubcategories] = useState<string[]>([]);

  // Add new state for delete confirmation
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (selectedCategory) {
      const subcats = categoryGroups[selectedCategory] || [];
      setAvailableSubcategories(subcats);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const fetchTool = async () => {
      setLoading(true);
      try {
        if (!id) {
          setError('Invalid tool ID.');
          return;
        }
        const toolDoc = doc(db, 'tools', id);
        const toolSnapshot = await getDoc(toolDoc);
        if (toolSnapshot.exists()) {
          const toolData = toolSnapshot.data();
          setFormData({
            name: toolData.name || '',
            description: toolData.description || '',
            url: toolData.url || '',
            imageUrl: toolData.imageUrl || '',
            pricing: toolData.pricing || 'free',
            priceDetails: toolData.priceDetails || '',
            rating: toolData.rating || 0,
            tags: toolData.tags || [],
            keyFeatures: toolData.keyFeatures || [],
            useCases: toolData.useCases || [],
            category: toolData.category || '',
            subcategory: toolData.subcategory || [],
            featured: toolData.featured || false,
            verified: toolData.verified || false,
            status: toolData.status || 'pending',
            reviewCount: toolData.reviewCount || 0
          });
          setSelectedCategory(toolData.category || '');
          setImagePreview(toolData.imageUrl || null);
        } else {
          setError('Tool not found');
        }
      } catch (err) {
        console.error('Error loading tool:', err);
        setError('Failed to load tool.');
      } finally {
        setLoading(false);
      }
    };
    fetchTool();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: ['rating', 'reviewCount'].includes(name) ? Number(value) || 0 : value
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }
      setNewImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const addArrayItem = (field: 'tags' | 'keyFeatures' | 'useCases', value: string) => {
    if (value.trim()) {
      setFormData(prev => ({
        ...prev,
        [field]: [...prev[field], value.trim()]
      }));
      
      if (field === 'tags') setNewTag('');
      if (field === 'keyFeatures') setNewFeature('');
      if (field === 'useCases') setNewUseCase('');
    }
  };

  const removeArrayItem = (field: 'tags' | 'keyFeatures' | 'useCases', index: number) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      if (!id) {
        setError('Invalid tool ID.');
        return;
      }

      const toolDoc = doc(db, 'tools', id);
      const updateData: any = { ...formData };

      // Handle image upload first if there's a new image
      if (newImage) {
        try {
          // Create a unique filename using timestamp and original filename
          const filename = `tool-images/${Date.now()}_${newImage.name}`;
          const storageRef = ref(storage, filename);
          
          // Upload the image
          const uploadTask = await uploadBytesResumable(storageRef, newImage);
          
          // Get the download URL
          const downloadURL = await getDownloadURL(uploadTask.ref);
          
          // Add the image URL to the update data
          updateData.imageUrl = downloadURL;
          
          console.log('Image uploaded successfully:', downloadURL); // Debug log
        } catch (uploadError) {
          console.error('Error uploading image:', uploadError);
          setError('Failed to upload image.');
          setLoading(false);
          return;
        }
      }

      // Update the document in Firestore
      await updateDoc(toolDoc, updateData);
      
      // Reset the newImage state after successful upload
      setNewImage(null);
      
      alert('Tool updated successfully!');
      navigate('/admin-console/ai-tools');
    } catch (err) {
      console.error('Error saving tool:', err);
      setError('Failed to save tool.');
    } finally {
      setLoading(false);
    }
  };

  // Add delete function
  const handleDelete = async () => {
    if (!id) return;
    
    setLoading(true);
    setError(null);
    try {
      const toolDoc = doc(db, 'tools', id);
      await deleteDoc(toolDoc);
      alert('Tool deleted successfully!');
      navigate('/admin-console/ai-tools');
    } catch (err) {
      console.error('Error deleting tool:', err);
      setError('Failed to delete tool.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p className="text-white">Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold mb-8">Edit Tool</h2>

        <div className="space-y-6">
          {/* Basic Information */}
          <div className="bg-gray-800 p-6 rounded-lg space-y-4">
            <h3 className="text-xl font-semibold mb-4">Basic Information</h3>
            
            <div>
              <label className="block text-sm font-medium mb-2">Name *</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Tool name"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">URL *</label>
              <input
                type="url"
                name="url"
                value={formData.url}
                onChange={handleInputChange}
                className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="https://..."
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Description * (Markdown)</label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows={10}
                className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 font-mono"
                placeholder="Enter description in markdown format..."
                required
              />
              <p className="text-sm text-gray-400 mt-1">
                Use markdown syntax for formatting (e.g., **bold**, *italic*, ## headings, etc.)
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-2">Category *</label>
                <select
                  name="category"
                  value={formData.category}
                  onChange={(e) => {
                    handleInputChange(e);
                    setSelectedCategory(e.target.value);
                  }}
                  className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Subcategories</label>
                <div className="space-y-2">
                  <div className="grid grid-cols-2 gap-2">
                    {availableSubcategories.map((subcat) => (
                      <label 
                        key={subcat} 
                        className="flex items-center gap-2 p-2 bg-gray-700 rounded-lg hover:bg-gray-600 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          checked={formData.subcategory.includes(subcat)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFormData(prev => ({
                                ...prev,
                                subcategory: [...prev.subcategory, subcat]
                              }));
                            } else {
                              setFormData(prev => ({
                                ...prev,
                                subcategory: prev.subcategory.filter(s => s !== subcat)
                              }));
                            }
                          }}
                          className="w-4 h-4 rounded"
                        />
                        <span>{subcat}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Pricing Section */}
          <div className="bg-gray-800 p-6 rounded-lg space-y-4">
            <h3 className="text-xl font-semibold mb-4">Pricing</h3>
            
            <div>
              <label className="block text-sm font-medium mb-2">Pricing Type *</label>
              <select
                name="pricing"
                value={formData.pricing}
                onChange={handleInputChange}
                className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              >
                <option value="free">Free</option>
                <option value="freemium">Freemium</option>
                <option value="trial">Trial</option>
                <option value="paid">Paid</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium mb-2">Price Details</label>
              <textarea
                name="priceDetails"
                value={formData.priceDetails}
                onChange={handleInputChange}
                rows={2}
                className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter pricing details..."
              />
            </div>
          </div>

          {/* Features Section */}
          <div className="bg-gray-800 p-6 rounded-lg space-y-6">
            {/* Key Features */}
            <div>
              <h3 className="text-xl font-semibold mb-4">Key Features</h3>
              <div className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={newFeature}
                  onChange={(e) => setNewFeature(e.target.value)}
                  className="flex-1 bg-gray-700 text-white p-3 rounded-lg"
                  placeholder="Add a key feature"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addArrayItem('keyFeatures', newFeature);
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={() => addArrayItem('keyFeatures', newFeature)}
                  className="bg-indigo-600 px-4 rounded-lg hover:bg-indigo-700"
                >
                  Add
                </button>
              </div>
              <div className="space-y-2">
                {formData.keyFeatures.map((feature, index) => (
                  <div key={index} className="flex items-center gap-2 bg-gray-700 p-2 rounded-lg">
                    <span className="flex-1">{feature}</span>
                    <button
                      type="button"
                      onClick={() => removeArrayItem('keyFeatures', index)}
                      className="text-red-400 hover:text-red-300"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Use Cases */}
            <div>
              <h3 className="text-xl font-semibold mb-4">Use Cases</h3>
              <div className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={newUseCase}
                  onChange={(e) => setNewUseCase(e.target.value)}
                  className="flex-1 bg-gray-700 text-white p-3 rounded-lg"
                  placeholder="Add a use case"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addArrayItem('useCases', newUseCase);
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={() => addArrayItem('useCases', newUseCase)}
                  className="bg-indigo-600 px-4 rounded-lg hover:bg-indigo-700"
                >
                  Add
                </button>
              </div>
              <div className="space-y-2">
                {formData.useCases.map((useCase, index) => (
                  <div key={index} className="flex items-center gap-2 bg-gray-700 p-2 rounded-lg">
                    <span className="flex-1">{useCase}</span>
                    <button
                      type="button"
                      onClick={() => removeArrayItem('useCases', index)}
                      className="text-red-400 hover:text-red-300"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Tags */}
            <div>
              <h3 className="text-xl font-semibold mb-4">Tags</h3>
              <div className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  className="flex-1 bg-gray-700 text-white p-3 rounded-lg"
                  placeholder="Add a tag"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      addArrayItem('tags', newTag);
                    }
                  }}
                />
                <button
                  type="button"
                  onClick={() => addArrayItem('tags', newTag)}
                  className="bg-indigo-600 px-4 rounded-lg hover:bg-indigo-700"
                >
                  Add
                </button>
              </div>
              <div className="flex flex-wrap gap-2">
                {formData.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-gray-700 px-3 py-1 rounded-full flex items-center gap-2"
                  >
                    {tag}
                    <button
                      type="button"
                      onClick={() => removeArrayItem('tags', index)}
                      className="text-red-400 hover:text-red-300"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </div>

          {/* Settings Section */}
          <div className="bg-gray-800 p-6 rounded-lg space-y-4">
            <h3 className="text-xl font-semibold mb-4">Settings</h3>
            
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="featured"
                    checked={formData.featured}
                    onChange={handleCheckboxChange}
                    className="w-4 h-4 rounded"
                  />
                  <span>Featured Tool</span>
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="verified"
                    checked={formData.verified}
                    onChange={handleCheckboxChange}
                    className="w-4 h-4 rounded"
                  />
                  <span>Verified Tool</span>
                </label>
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Status</label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <option value="pending">Pending</option>
                  <option value="active">Active</option>
                  <option value="rejected">Rejected</option>
                </select>
              </div>
            </div>
          </div>

          {/* Image Upload Section */}
          <div className="bg-gray-800 p-6 rounded-lg space-y-4">
            <h3 className="text-xl font-semibold mb-4">Featured Image</h3>
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="block w-full text-sm text-gray-400
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-indigo-600 file:text-white
                hover:file:bg-indigo-700"
            />
          </div>

          {/* Rating Section */}
          <div className="bg-gray-800 p-6 rounded-lg space-y-4">
            <h3 className="text-xl font-semibold mb-4">Rating & Reviews</h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-2">
                  Rating {formData.rating > 0 && (
                    <span className="ml-2 inline-flex items-center">
                      <Star className="w-4 h-4 text-yellow-400 fill-yellow-400" />
                      <span className="ml-1">{formData.rating}/5</span>
                    </span>
                  )}
                </label>
                <input
                  type="number"
                  name="rating"
                  value={formData.rating}
                  onChange={handleInputChange}
                  className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  min="0"
                  max="5"
                  step="0.1"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Review Count</label>
                <input
                  type="number"
                  name="reviewCount"
                  value={formData.reviewCount}
                  onChange={handleInputChange}
                  className="w-full bg-gray-700 text-white p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  min="0"
                />
              </div>
            </div>
          </div>

          {/* Add this before the save button */}
          <div className="bg-gray-800 p-6 rounded-lg space-y-4 mb-6">
            <h3 className="text-xl font-semibold text-white mb-4">Danger Zone</h3>
            
            {!showDeleteConfirm ? (
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="w-full bg-red-600 text-white py-3 rounded-lg hover:bg-red-700 transition-colors"
              >
                Delete Tool
              </button>
            ) : (
              <div className="space-y-4">
                <p className="text-gray-300">
                  Are you sure you want to delete this tool? This action cannot be undone.
                </p>
                <div className="flex gap-4">
                  <button
                    onClick={handleDelete}
                    className="flex-1 bg-red-600 text-white py-3 rounded-lg hover:bg-red-700 transition-colors"
                  >
                    Yes, Delete Tool
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirm(false)}
                    className="flex-1 bg-gray-600 text-white py-3 rounded-lg hover:bg-gray-700 transition-colors"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>

          <button
            onClick={handleSave}
            disabled={loading}
            className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 disabled:opacity-50 transition-colors"
          >
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminToolEdit;




