import { Tool } from '../types/Tool';
import { Category } from '../types';
import { BlogPost } from '../types/BlogPost';
import { getAllTools } from './toolService';
import { categories } from '../data/categories';
import { getPublishedPosts } from './blogService';

export interface SearchResult {
  id: string;
  type: 'tool' | 'category' | 'blog';
  title: string;
  description: string;
  url: string;
  tags?: string[];
}

export function searchTools(tools: Tool[], query: string, pricing?: string): Tool[] {
  if (!query && !pricing) return tools;

  const searchTerms = query.toLowerCase().split(' ').filter(term => term.length > 0);
  
  return tools.filter(tool => {
    // Apply pricing filter if specified
    if (pricing && pricing !== 'all') {
      if (tool.pricing !== pricing) return false;
    }

    // If no search query, return pricing filtered results
    if (!query) return true;

    // Search through tool properties
    const searchableText = [
      tool.name,
      tool.description,
      ...(tool.tags || []),
      ...(tool.keyFeatures || []),
      ...(tool.useCases || [])
    ].join(' ').toLowerCase();

    // Tool matches if all search terms are found in the searchable text
    return searchTerms.every(term => searchableText.includes(term));
  });
}

export async function searchAll(query: string): Promise<SearchResult[]> {
  const searchTerm = query.toLowerCase().trim();
  
  if (!searchTerm) return [];

  const [tools, posts] = await Promise.all([
    getAllTools(),
    getPublishedPosts()
  ]);

  const toolResults = tools
    .filter(tool => {
      const searchableText = [
        tool.name,
        tool.description,
        ...(tool.tags || []),
        ...(tool.keyFeatures || []),
        ...(tool.useCases || [])
      ].join(' ').toLowerCase();

      return searchableText.includes(searchTerm);
    })
    .map(tool => ({
      id: tool.id,
      type: 'tool' as const,
      title: tool.name,
      description: tool.description,
      url: `/tool/${tool.id}`,
      tags: tool.tags
    }));

  const categoryResults = categories
    .filter(category => {
      const searchableText = [
        category.name,
        category.description,
        category.slug
      ].join(' ').toLowerCase();

      return searchableText.includes(searchTerm);
    })
    .map(category => ({
      id: category.id,
      type: 'category' as const,
      title: category.name,
      description: category.description,
      url: `/category/${category.id}`,
      tags: [category.slug]
    }));

  const blogResults = posts
    .filter(post => {
      const searchableText = [
        post.title,
        post.excerpt,
        ...(post.tags || []),
        post.category
      ].join(' ').toLowerCase();

      return searchableText.includes(searchTerm);
    })
    .map(post => ({
      id: post.id,
      type: 'blog' as const,
      title: post.title,
      description: post.excerpt,
      url: `/blog/${post.slug}`,
      tags: post.tags
    }));

  return [...toolResults, ...categoryResults, ...blogResults];
}

export function filterToolsByPricing(tools: Tool[], pricing?: string): Tool[] {
  if (!pricing || pricing === 'all') return tools;
  return tools.filter(tool => tool.pricing === pricing);
}

export function filterToolsByCategory(tools: Tool[], categoryId: string): Tool[] {
  return tools.filter(tool => 
    tool.category === categoryId || 
    tool.tags?.includes(categoryId)
  );
}

export function getRelatedTools(tool: Tool, allTools: Tool[], limit = 3): Tool[] {
  return allTools
    .filter(t => 
      t.id !== tool.id && (
        t.category === tool.category ||
        t.tags?.some(tag => tool.tags?.includes(tag))
      )
    )
    .slice(0, limit);
}

export function searchToolsByTags(tools: Tool[], tags: string[]): Tool[] {
  if (!tags.length) return tools;
  return tools.filter(tool =>
    tags.some(tag => tool.tags?.includes(tag))
  );
}