import React from 'react';
import app from '../lib/firebase';
import { useAuthStore } from '../store/useAuthStore';

interface FirebaseProviderProps {
  children: React.ReactNode;
}

const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children }) => {
  const initialize = useAuthStore(state => state.initialize);

  React.useEffect(() => {
    // Initialize auth state
    initialize();
  }, [initialize]);

  return <>{children}</>;
}

export default FirebaseProvider;