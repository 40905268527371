import { db, storage } from '../lib/firebase';
import { 
  collection, 
  getDocs, 
  getDoc, 
  addDoc, 
  updateDoc, 
  deleteDoc,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp
} from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { NewsPost } from '../types/NewsPost';

const NEWS_COLLECTION = 'news_posts';

export const getPublishedNews = async (): Promise<NewsPost[]> => {
  const newsQuery = query(
    collection(db, NEWS_COLLECTION),
    where('isPublished', '==', true),
    orderBy('publishedAt', 'desc')
  );
  
  const snapshot = await getDocs(newsQuery);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as NewsPost));
};

export const getAllNews = async (): Promise<NewsPost[]> => {
  const newsQuery = query(
    collection(db, NEWS_COLLECTION),
    orderBy('publishedAt', 'desc')
  );
  
  const snapshot = await getDocs(newsQuery);
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as NewsPost));
};

export const getNewsBySlug = async (slug: string): Promise<NewsPost | null> => {
  const newsQuery = query(
    collection(db, NEWS_COLLECTION),
    where('slug', '==', slug),
    where('isPublished', '==', true)
  );
  
  const snapshot = await getDocs(newsQuery);
  if (snapshot.empty) return null;
  
  const doc = snapshot.docs[0];
  return {
    id: doc.id,
    ...doc.data()
  } as NewsPost;
};

export const getNewsById = async (id: string): Promise<NewsPost | null> => {
  const docRef = doc(db, NEWS_COLLECTION, id);
  const docSnap = await getDoc(docRef);
  
  if (!docSnap.exists()) return null;
  
  return {
    id: docSnap.id,
    ...docSnap.data()
  } as NewsPost;
};

export const createNews = async (data: NewsPost) => {
  try {
    await addDoc(collection(db, 'news'), {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });
    
    // Generate new RSS feed
    await generateRssFeed();
    
    return true;
  } catch (error) {
    console.error('Error creating news:', error);
    return false;
  }
};

export const updateNews = async (id: string, data: Partial<NewsPost>) => {
  try {
    await updateDoc(doc(db, 'news', id), {
      ...data,
      updatedAt: serverTimestamp()
    });
    
    // Generate new RSS feed
    await generateRssFeed();
    
    return true;
  } catch (error) {
    console.error('Error updating news:', error);
    return false;
  }
};

export const deleteNews = async (id: string): Promise<void> => {
  const docRef = doc(db, NEWS_COLLECTION, id);
  await deleteDoc(docRef);
};

export const generateRssFeed = async (): Promise<string> => {
  const news = await getPublishedNews();
  const baseUrl = process.env.REACT_APP_BASE_URL || 'https://yoursite.com';

  const rssItems = news.map(item => `
    <item>
      <title><![CDATA[${item.title}]]></title>
      <link>${baseUrl}/news/${item.slug}</link>
      <guid>${baseUrl}/news/${item.slug}</guid>
      <pubDate>${new Date(item.publishedAt).toUTCString()}</pubDate>
      <description><![CDATA[${item.excerpt}]]></description>
      ${item.featuredImage ? `<enclosure url="${item.featuredImage}" type="image/jpeg" />` : ''}
      ${item.tags?.map(tag => `<category>${tag}</category>`).join('') || ''}
      <author>${item.author}</author>
    </item>
  `).join('');

  return `<?xml version="1.0" encoding="UTF-8"?>
    <rss version="2.0" xmlns:content="http://purl.org/rss/1.0/modules/content/">
      <channel>
        <title>Your Site News</title>
        <link>${baseUrl}/news</link>
        <description>Latest news and updates</description>
        <language>en-US</language>
        <lastBuildDate>${new Date().toUTCString()}</lastBuildDate>
        ${rssItems}
      </channel>
    </rss>
  `;
};

async function uploadImage(file: File): Promise<string> {
  // Implementation for uploading image to storage
  const storageRef = ref(storage, `news/images/${Date.now()}_${file.name}`);
  await uploadBytes(storageRef, file);
  const url = await getDownloadURL(storageRef);
  return url;
} 