import React from 'react';
import ResponsiveImage from './ResponsiveImage';

interface AdProps {
  position: number;
  ad: {
    imageUrl: string;
    linkUrl: string;
    openInNewTab?: boolean;
    rel?: string;
  };
}

const AdSlot: React.FC<AdProps> = ({ position, ad }) => {
  if (!ad.imageUrl || !ad.linkUrl) {
    return null;
  }

  return (
    <div className="w-full">
      <a
        href={ad.linkUrl}
        target={ad.openInNewTab ? "_blank" : "_self"}
        rel={`${ad.rel === 'nofollow' ? 'nofollow' : ''} ${ad.openInNewTab ? 'noopener noreferrer' : ''}`}
        className="block w-full"
      >
        <ResponsiveImage
          src={ad.imageUrl}
          alt={`Advertisement ${position}`}
          className="w-full h-auto rounded-lg"
          size="_854x480" // You can adjust this size based on your ad image requirements
        />
      </a>
    </div>
  );
};

export default AdSlot;
