// src/components/BlogPostPage.tsx

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { BlogPost, getPostBySlug } from '../services/blogService';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'; // Import rehypeRaw
import Breadcrumbs from './Breadcrumbs';
import SchemaMarkup from './SchemaMarkup';
import { generateInternalLinksWithData } from '../services/internalLinkingService';
import ResponsiveImage from './ResponsiveImage';
import MetaTags from './MetaTags';

const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [processedContent, setProcessedContent] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAndProcessPost = async () => {
      if (slug) {
        try {
          setLoading(true);
          const postData = await getPostBySlug(slug);
          if (postData) {
            setPost(postData);
            const contentWithLinks = await generateInternalLinksWithData(
              postData.content,
              `/blog/${slug}`
            );
            setProcessedContent(contentWithLinks);
          }
        } catch (err) {
          console.error('Error fetching post:', err);
          setError('Failed to load blog post');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAndProcessPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="text-center text-gray-400">Loading...</div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="text-center text-red-400">
          {error || 'Blog post not found'}
        </div>
        <Link
          to="/blog"
          className="block mt-4 text-center text-indigo-400 hover:text-indigo-300"
        >
          Return to Blog
        </Link>
      </div>
    );
  }

  const customRenderers = {
    img: ({ src, alt }: { src?: string; alt?: string }) => (
      src ? (
        <div className="my-8">
          <ResponsiveImage
            src={src}
            alt={alt || ''}
            className="w-full h-full object-cover rounded-lg"
            size="_854x480"
          />
        </div>
      ) : null
    ),
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <MetaTags 
        title={post.title}
        description={post.excerpt}
        content={post.content}
      />
      
      <SchemaMarkup
        type="article"
        data={{
          title: post.title,
          description: post.excerpt,
          image: post.featuredImage || post.coverImage,
          publishDate: post.publishedAt,
          modifyDate: post.publishedAt,
          authorName: post.author,
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <article className="max-w-4xl mx-auto">
        <Link
          to="/blog"
          className="inline-flex items-center text-gray-400 hover:text-white mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Blog
        </Link>

        <header className="mb-8">
          <h1 className="text-4xl font-bold text-white mb-4">{post.title}</h1>
          <div className="flex items-center gap-4 text-gray-400 text-sm">
            <time dateTime={post.publishedAt}>
              {new Date(post.publishedAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </time>
            <span className="text-gray-500">•</span>
            <span className="text-indigo-400">{post.author}</span>
          </div>
        </header>

        {(post.featuredImage || post.coverImage) && (
          <div className="mb-8 relative">
            <div className="aspect-video">
              <ResponsiveImage
                src={post.featuredImage || post.coverImage}
                alt={post.title}
                className="w-full h-full object-cover rounded-lg"
                size="_854x480"
              />
            </div>
          </div>
        )}

        <div className="prose prose-invert prose-lg max-w-none">
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={customRenderers}
            className="
              prose-headings:text-white prose-headings:font-bold 
              prose-h1:text-3xl prose-h1:mb-6
              prose-h2:text-2xl prose-h2:mt-8 prose-h2:mb-4
              prose-h3:text-xl prose-h3:mt-6 prose-h3:mb-3
              prose-p:text-gray-300 prose-p:leading-relaxed prose-p:mb-4
              prose-ul:text-gray-300 prose-ul:mb-4
              prose-ol:text-gray-300 prose-ol:mb-4
              prose-li:mb-2
              prose-a:text-indigo-400 prose-a:no-underline hover:prose-a:underline
              prose-strong:text-white
              prose-code:text-indigo-300 prose-code:bg-gray-800 prose-code:px-1 prose-code:rounded
              prose-pre:bg-gray-800 prose-pre:p-4 prose-pre:rounded-lg
              prose-blockquote:border-l-4 prose-blockquote:border-indigo-400 prose-blockquote:pl-4 prose-blockquote:text-gray-300
              prose-img:rounded-lg
            "
          >
            {processedContent}
          </ReactMarkdown>
        </div>

        {post.tags && post.tags.length > 0 && (
          <div className="mt-8 pt-8 border-t border-gray-800">
            <div className="flex flex-wrap gap-2">
              {post.tags
                .filter((tag) => tag.toLowerCase() !== 'uncategorized')
                .map((tag) => (
                  <span
                    key={tag}
                    className="px-3 py-1 bg-gray-800 rounded-full text-sm text-gray-300"
                  >
                    {tag}
                  </span>
                ))}
            </div>
          </div>
        )}
      </article>
    </div>
  );
};

export default BlogPostPage;
