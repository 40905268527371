import { analyzeUrl } from './urlAnalyzer';
import { db } from '../lib/firebase';
import { collection, addDoc } from 'firebase/firestore';

const BATCH_SIZE = 3;
const BATCH_DELAY = 5 * 60 * 1000; // 5 minutes in milliseconds

export async function processUrlBatch(
  urls: string[],
  onProgress: (processed: number) => void
): Promise<void> {
  let processed = 0;

  // Process URLs in batches
  for (let i = 0; i < urls.length; i += BATCH_SIZE) {
    const batch = urls.slice(i, i + BATCH_SIZE);
    
    // Process each URL in the current batch concurrently
    const batchPromises = batch.map(async (url) => {
      try {
        const analysis = await analyzeUrl(url);
        
        if (!analysis.error) {
          const toolData = {
            url,
            name: url.replace(/^https?:\/\//, '').split('/')[0],
            description: analysis.description,
            keyFeatures: analysis.keyFeatures,
            useCases: analysis.useCases,
            image: analysis.screenshot,
            createdAt: new Date(),
            status: 'pending',
            pricing: 'free', // Default value
            rating: 0,
            reviewCount: 0,
            tags: [],
            verified: false,
            importedBy: 'batch-import'
          };

          await addDoc(collection(db, 'tools'), toolData);
        }
      } catch (error) {
        console.error(`Error processing ${url}:`, error);
      }
    });

    // Wait for all URLs in the current batch to be processed
    await Promise.all(batchPromises);
    processed += batch.length;
    onProgress(processed);

    // Wait for the delay before processing the next batch
    // Skip delay for the last batch
    if (i + BATCH_SIZE < urls.length) {
      await new Promise(resolve => setTimeout(resolve, BATCH_DELAY));
    }
  }
}