import React, { useState, useEffect } from 'react';

const CookieConsent: React.FC = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!localStorage.getItem('cookiesAccepted')) {
        setVisible(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookiesDeclined', 'true');
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 bg-gray-800/95 backdrop-blur-sm text-white shadow-lg transform transition-transform duration-300 ease-in-out">
      <div className="container mx-auto p-4 md:p-6">
        <div className="max-w-4xl mx-auto space-y-4">
          <h3 className="text-lg font-semibold">Cookie Preferences</h3>
          <p className="text-sm text-gray-300">
            We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. 
            By clicking "Accept All", you consent to our use of cookies. Read more about how we use cookies in our{' '}
            <a href="/privacy" className="text-indigo-400 hover:text-indigo-300 underline">
              Privacy Policy
            </a>.
          </p>
          
          <div className="flex flex-col sm:flex-row gap-3 pt-2">
            <button
              onClick={handleAccept}
              className="px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors duration-200"
            >
              Accept All
            </button>
            <button
              onClick={handleDecline}
              className="px-6 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
            >
              Decline Non-Essential
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent; 