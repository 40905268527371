import React from 'react';
import { ScrollText } from 'lucide-react';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';
import MetaTags from './MetaTags';

const TermsPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <MetaTags 
        title="Terms of Service - AI Is A Tool"
        description="Read our Terms of Service to understand your rights and responsibilities when using AI Is A Tool. Learn about our policies, user content guidelines, and legal terms."
        content={`
          Terms of Service for AI Is A Tool
          Last updated: ${new Date().toISOString().split('T')[0]}
          Includes information about:
          - Use of Service
          - User Content
          - Privacy
          - Disclaimers
          - Liability
          - Copyright
        `}
      />

      <SchemaMarkup 
        type="website"
        data={{
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: "Terms of Service - Ai Is A Tool",
          description: "Terms and conditions for using Ai Is A Tool",
          publisher: {
            "@type": "Organization",
            name: "Ai Is A Tool",
            url: window.location.origin
          },
          inLanguage: "en-GB",
          isAccessibleForFree: true,
          datePublished: "2024-01-01", // Update with actual date
          dateModified: new Date().toISOString().split('T')[0]
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <div className="max-w-4xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <ScrollText className="h-8 w-8 text-indigo-400" />
          <h1 className="text-3xl font-bold">Terms of Service</h1>
        </div>

        <div className="prose prose-invert max-w-none space-y-6">
          <h2 className="text-3xl font-bold mb-6">Welcome to Ai Is A Tool Terms of Service</h2>
      
      <p className="mb-6">
        These Terms of Service ("Terms") govern your access to and use of the services, websites, and applications (the "Service") 
        offered by i Enterprise Ltd ("Ai Is A Tool"). Your access to and use of the Service is conditioned on your acceptance 
        of and compliance with these Terms. By accessing or using our Service, you agree to be bound by these Terms. 
        Please read them carefully.
      </p>

      <h3 className="text-2xl font-bold mt-8 mb-4">Use of the Service</h3>
      <p className="mb-4">
        You may use the Service only if you can form a binding contract with Ai Is A Tool and only in compliance with 
        these Terms and all applicable local, national, and international laws, rules, and regulations. You must provide 
        us with accurate information, including your real name, when you create your account on Ai Is A Tool.
      </p>
      <p className="mb-4">
        We may, without prior notice, change the Service; stop providing the Service or features of the Service, 
        to you or to users generally; or create usage limits for the Service.
      </p>
      <p className="mb-6">
        You are responsible for safeguarding the password that you use to access the Service and for any activities 
        or actions under your password. We encourage you to use "strong" passwords (that use a combination of upper 
        and lower case letters, numbers, and symbols) with your account. Ai Is A Tool will not be liable for any 
        loss or damage arising from your failure to comply with these requirements.
      </p>

      <h3 className="text-2xl font-bold mt-8 mb-4">Age Restriction</h3>
      <p className="mb-6">
        The Service is not intended for children under 13 years of age, and you may not use the Service or create 
        a user profile, if applicable, if you are under 13. You hereby represent and warrant that you are at 
        least 13 years of age.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Content Appearing on the Service</h2>
      <p className="mb-4">
        "Content" means any information, text, graphics, or other materials uploaded, downloaded, or appearing on 
        the Service, including but not limited to the evaluations, presentations, rankings, reviews, other information, 
        advertisements, interactive discussions, photos, videos, graphics, music, and sound contained on, distributed 
        through, linked, downloaded, or accessed from the Service, whether such Content is owned by Ai Is A Tool or 
        third parties.
      </p>
      <p className="mb-4">
        CERTAIN CONTENT THAT APPEARS ON THIS SITE COMES FROM AMAZON SERVICES LLC. THIS CONTENT IS PROVIDED 'AS IS' 
        AND IS SUBJECT TO CHANGE OR REMOVAL AT ANY TIME.
      </p>
      <p className="mb-6">
        While we aim to provide unbiased reviews and information on products and services, we may use affiliate links 
        to products and or services for which we sometimes receive compensation. Ai Is A Tool is a participant in a 
        number of affiliate advertising programmes. For instance, we participate in the Amazon Services LLC Associates 
        Programme, an affiliate advertising programme designed to provide a means for sites to earn advertising fees 
        by advertising and linking to Amazon.com.
      </p>

      <h3 className="text-2xl font-bold mt-8 mb-4">User Content</h3>
      <p className="mb-4">
        Our Service allows you to submit content. You retain ownership of any intellectual property rights that you hold 
        in that content. In short, what belongs to you stays yours.
      </p>
      <p className="mb-4">
        "User Content" means any text, music, sound, photos, video, evaluations, ratings, or graphics which you or other 
        users of the Service create, upload, download, transmit, store, or appearing on the Service. User Content is 
        publicly viewable on the Service. You agree that you are solely responsible for your User Content.
      </p>
      <p className="mb-4">
        You agree not to post, upload to, transmit, distribute, store, create or otherwise publish or send through the Service any of the following:
      </p>
      <p className="mb-6">
        User Content that is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, 
        threatening, abusive, inflammatory, fraudulent or otherwise objectionable; User Content that would constitute, encourage 
        or provide instructions for a criminal offense, violate the rights of any party or that would otherwise create liability 
        or violate any local, state, national or international law; User Content that displays, describes or encourages usage 
        of any product in a manner that could be offensive, inappropriate or harmful to Ai Is A Tool or any user or consumer 
        or that is contrary to any instructions or warnings relating to the product User Content that may impinge upon the publicity, 
        privacy or data protection rights of others, including pictures or information about another individual where you have not 
        obtained such individual's consent; User Content that may infringe any patent, trademark, trade secret, copyright or other 
        intellectual or proprietary right of any party; User Content that impersonates any person or entity or otherwise misrepresents 
        your affiliation with a person or entity; Viruses, malware of any kind, corrupted data or other harmful, disruptive or 
        destructive files or code; and User Content that, in the sole judgment of Ai Is A Tool, restricts or inhibits any other 
        person from using or enjoying the Service or which may expose Ai Is A Tool or its users to any harm or liability of any 
        type. Ai Is A Tool takes no responsibility and assumes no liability for any User Content posted, stored, or uploaded by you 
        or any third party or for any loss or damage thereto, nor is Ai Is A Tool liable for any mistakes, defamation, slander, 
        libel, omissions, falsehoods, obscenity, pornography or profanity you may encounter. In addition, these Terms do not create 
        any private right of action on the part of any third party or any reasonable expectation or promise that the Service will not 
        contain any content that is prohibited by such Terms. Although Ai Is A Tool has no obligation and may not monitor User Content 
        posted on the Service, Ai Is A Tool reserves the right, and has absolute discretion, to remove, screen or edit any User Content 
        posted or stored on the Service at any time and for any reason without notice, and you are solely responsible for creating 
        backup copies and replacing any User Content you post or store on the Service at your sole cost and expense.
      </p>
      <p className="mb-4">
        We may modify or adapt your User Content in order to transmit, display, or distribute it over computer networks and in 
        various media and/or make changes to your Content as are necessary to conform and adapt that Content to any requirements 
        or limitations of any networks, devices, services, or media.
      </p>
      <p className="mb-4">
        Your User Content will be viewable by other users of the Service and through third party services and websites. You should 
        only provide User Content that you are comfortable sharing with others under these Terms.
      </p>
      <p className="mb-6">
        All User Content, whether publicly posted or privately transmitted, is the sole responsibility of the person who originated 
        such User Content. We may not monitor or control the User Content posted via the Service. Any use of or reliance on any User 
        Content or materials posted via the Service or obtained by you through the Service is at your own risk. We do not endorse, 
        support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any User Content or communications 
        posted via the Service or endorse any opinions expressed via the Service. You understand that by using the Service, you may 
        be exposed to User Content that might be offensive, harmful, inaccurate, or otherwise inappropriate. Under no circumstances 
        will Ai Is A Tool be liable in any way for any User Content, including, but not limited to, any errors or omissions in any 
        User Content, or any loss or damage of any kind incurred as a result of the use of any User Content made available via the 
        Service or broadcast elsewhere.
      </p>
      <p className="mb-4">
        You are responsible for your use of the Service, for any User Content you provide, and for any consequences thereof, including 
        the use of your Content by other users and third party partners. You understand that your User Content may be republished 
        and if you do not have the right to submit User Content for such use, it may subject you to liability under UK law. Ai Is A Tool 
        will not be responsible or liable for any use of your User Content by Ai Is A Tool in accordance with these Terms. You represent 
        and warrant that you have all the rights, power, and authority necessary to grant the rights granted herein to any User Content 
        that you submit.
      </p>
      <p className="mb-4">
        We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute any User Content on the 
        Service and to terminate users or reclaim usernames. We also reserve the right to access, read, preserve, and disclose any 
        information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental 
        request under UK law, (ii) enforce the Terms, including investigation of potential violations hereof, (iii) detect, prevent, 
        or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, 
        property or safety of Ai Is A Tool, its users and the public.
      </p>
      <p className="mb-6">
        By submitting, posting or displaying User Content on or through the Service, you grant us a worldwide license to use, host, 
        store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we 
        make so that your content works better with our Services), communicate, publish, publicly perform, publicly display and distribute 
        such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, 
        and to develop new ones. This license continues even if you stop using our Services. Make sure you have the necessary rights to 
        grant us this license for any content that you submit to our Services. You agree that this license includes the right for Ai Is A Tool 
        to make your User Content available to others for the publication, distribution, syndication, or broadcast of such User Content on 
        other media and services, subject to our terms and conditions for such User Content use. Such additional uses by Ai Is A Tool or others 
        may be made with no compensation paid to you with respect to the User Content that you submit, post, transmit or otherwise make 
        available through the Service.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Rules</h2>
      <p className="mb-6">
        You must not do any of the following while accessing or using the Service: (i) use the Service for any unlawful purposes or 
        for promotion of illegal activities; (ii) post any Content (as defined below) or use the Service in violation of any applicable 
        law (including intellectual property laws, right of privacy or publicity laws, and any laws of a non-UK jurisdiction applicable 
        to you), or any contractual or other legal obligation; (iii) post Content that is hateful, abusive, threatening, profane, or 
        otherwise objectionable; (iv) post Content or use the Service to create an impression that you know is incorrect, misleading, 
        or deceptive, including by impersonating others or otherwise misrepresenting your affiliation with a person or entity; (v) publish 
        or post other people's private or personally identifiable information without their express authorization and permission; (vi) use 
        the Service for the purpose of spamming anyone; (vii) publish or link to malicious content intended to damage or disrupt another 
        user's browser or computer or to compromise a user's privacy; (viii) access or tamper with non-public areas of the Service, Ai Is A Tool's 
        computer systems, or the technical delivery systems of Ai Is A Tool's providers; (ix) probe, scan, or test the vulnerability of any 
        system or network or breach or circumvent any security or authentication measures; (x) access or search or attempt to access or 
        search the Service by any means (automated or otherwise) other than through the currently available, published interfaces that 
        are provided by Ai Is A Tool (and only pursuant to those terms and conditions), unless you have been specifically allowed to do so 
        in a separate agreement with Ai Is A Tool (crawling the Service is permissible in accordance with these Terms, but scraping the 
        Service without the prior consent of Ai Is A Tool except as permitted by these Terms is expressly prohibited); (xi) forge any TCP/IP 
        packet header or any part of the header information in any email or posting, or in any way use the Service to send altered, 
        deceptive or false source-identifying information; or (xii) interfere with or disrupt (or attempt to do so) the access of any 
        user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, mail-bombing the Service, 
        or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Service.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Disclaimer of Warranty; Limitation of Liability</h2>
      <p className="mb-4">
        Your access to and use of the Service or any Content is at your own risk. You understand and agree that the Service is provided 
        to you on an "AS IS" and "AS AVAILABLE" basis. Without limiting the foregoing, Ai Is A Tool AND ITS PARTNERS DISCLAIM ANY WARRANTIES, 
        EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. We make no warranty and disclaim all 
        responsibility and liability for the completeness, accuracy, availability, timeliness, security or reliability of the Service or any 
        content thereon. Ai Is A Tool will not be responsible or liable for any harm to your computer system, loss of data, or other harm 
        that results from your access to or use of the Service, or any Content. You also agree that Ai Is A Tool has no responsibility or 
        liability for the deletion of, or the failure to store or to transmit, any Content and other communications maintained by the Service. 
        We make no warranty that the Service will meet your requirements or be available on an uninterrupted, secure, or error-free basis. 
        No advice or information, whether oral or written, obtained from Ai Is A Tool or through the Service, will create any warranty not 
        expressly made herein.
      </p>
          <p className="mb-4">
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Ai Is A Tool AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND 
        LICENSORS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING WITHOUT 
        LIMITATION, LOSS OF PROFITS, DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR 
        INABILITY TO ACCESS OR USE THE SERVICE; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE, INCLUDING WITHOUT LIMITATION, 
        ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES; (iii) ANY CONTENT OBTAINED FROM THE SERVICE; AND 
        (iv) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING 
        NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT Ai Is A Tool HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN 
        IF A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
      </p>

      <p className="mb-4">
        Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential 
        or incidental damages, so the limitations above may not apply to you.
      </p>

      <p className="mb-4">
        If anyone brings a claim against us related to your actions or Content on the Service, or actions or Content by or from someone 
        using your account, you will indemnify and hold us harmless from and against all damages, losses, and expenses of any kind 
        (including reasonable legal fees and costs) related to such claim.
      </p>

      <p className="mb-4">Without limiting the foregoing:</p>

      <p className="mb-4">
        (A) YOU EXPRESSLY AGREE THAT YOUR USE OF AIISATOOL.COM IS AT YOUR OWN SOLE RISK. THE CONTENT IS NOT PROMISED OR GUARANTEED TO BE 
        CORRECT, COMPLETE OR UP-TO-DATE. AISATOOL.COM IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, 
        EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS 
        FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
      </p>

      <p className="mb-4">
        (B) NEITHER Ai Is A Tool, ITS AFFILIATES NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS 
        WARRANT THAT AIISATOOL.COM WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED 
        FROM USE OF AISATOOL.COM, OR AS TO THE ACCURACY OR RELIABILITY OF ANY CONTENT PROVIDED ON OR THROUGH AISATOOL.COM. FURTHERMORE, 
        THERE ARE NO WARRANTIES AS TO THE RESULTS OBTAINED FROM THE USE OF THE CONTENT.
      </p>

      <p className="mb-4">
        (C) IN NO EVENT WILL Ai Is A Tool, OR ANY PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR DISTRIBUTING AISATOOL.COM, BE LIABLE 
        FOR ANY DAMAGES, INCLUDING, WITHOUT LIMITATION, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES BASED UPON OR 
        ARISING OUT OF THE USE OF OR INABILITY TO USE AISATOOL.COM. YOU HEREBY ACKNOWLEDGE THAT THE PROVISIONS OF THIS SECTION SHALL APPLY 
        TO ALL CONTENT ON AIISATOOL.COM. IN ANY EVENT, Ai Is A Tool'S MAXIMUM TOTAL LIABILITY TO USER FOR ANY REASON AND UPON ANY CAUSE OF 
        ACTION SHALL BE LIMITED TO DIRECT DAMAGES NOT TO EXCEED THE GREATER OF THE PRICE, IF ANY, PAID BY USER FOR ACCESS TO AISATOOL.COM 
        OR 25 POUNDS (£25.00) UNDER UK LAW.
      </p>

      <p className="mb-4">
        (D) THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, 
        DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT, DESTRUCTION, UNAUTHORIZED 
        ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE 
        OF ACTION. THIS DISCLAIMER FURTHER APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY ERRORS, INACCURACIES, OMISSIONS, OR OTHER DEFECTS 
        IN, OR UNTIMELINESS OR UNAUTHENTICITY OF, THE CONTENT OR ANY OF THE PRODUCTS OR SERVICES REFERRED TO IN THE CONTENT. Ai Is A Tool 
        SHALL NOT BE LIABLE FOR ANY THIRD-PARTY CLAIMS OR LOSSES OF ANY NATURE, INCLUDING, BUT NOT LIMITED TO, ANY CLAIMS OR LOSSES RELATING 
        TO ANY PRODUCTS OR SERVICES REFERRED TO AT ANY TIME IN THE CONTENT, LOST PROFITS, PUNITIVE OR CONSEQUENTIAL DAMAGES UNDER UK LAW. 
        USER SPECIFICALLY ACKNOWLEDGES THAT Ai Is A Tool IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR 
        THIRD PARTIES, AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH USER.
      </p>

      <p className="mb-6">
        (E) Ai Is A Tool DOES NOT INTEND ANY REFERENCES TO ENTITIES, PRODUCTS OR SERVICES IN THE CONTENT OR ANY LINKS ON AISATOOL.COM TO BE 
        ENDORSEMENTS OF SUCH ENTITIES, PRODUCTS OR SERVICES. Ai Is A Tool IS NOT AFFILIATED WITH, OR SPONSORED OR ENDORSED BY ANY OTHER 
        CONSUMER PRODUCT RATING SERVICE, AND ANY REFERENCE TO SUCH THIRD-PARTY RATING SERVICES IS SOLELY FOR THE GENERAL INFORMATION AND 
        CONVENIENCE OF USERS USING AISTOOL.AI
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Proprietary Rights</h2>
      <p className="mb-6">
        All right, title, and interest in and to the Service (excluding User Content) are and will remain the exclusive property of 
        Ai Is A Tool and its licensors. The Service is protected by copyright, trademark, and other laws of both the United Kingdom and 
        foreign countries. Except as expressly provided herein, nothing in the Terms gives you a right to use the Ai Is A Tool name or 
        any of the Ai Is A Tool trademarks, logos, domain names, and other distinctive brand features. Any feedback, comments, or suggestions 
        you may provide regarding the Service is entirely voluntary and we will be free to use such feedback, comments or suggestions as we 
        see fit and without any obligation to you.
      </p>

      <p className="mb-6">
        The Service may include advertisements, which may be targeted to the Content or information on the Service, queries made through 
        the Service, or other information. The types and extent of advertising by Ai Is A Tool on the Service are subject to change. In 
        consideration for Ai Is A Tool granting you access to and use of the Service, you agree that Ai Is A Tool and its third party 
        providers and partners may place such advertising on the Service or in connection with the display of Content or information from 
        the Service whether submitted by you or others.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Copyright Policy</h2>
      <p className="mb-6">
        Ai Is A Tool respects the intellectual property rights of others and expects users of the Service to do the same. We will respond 
        to notices of alleged copyright infringement that comply with applicable UK law and are properly provided to us. If you believe 
        that your Content has been copied in a way that constitutes copyright infringement, please provide our copyright agent with the 
        following information in accordance with UK law: (i) a physical or electronic signature of the copyright owner or a person authorized 
        to act on their behalf; (ii) identification of the copyrighted work claimed to have been infringed; (iii) identification of the 
        material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which 
        is to be disabled, and information reasonably sufficient to permit us to locate the material; (iv) your contact information, including 
        your address, telephone number, and an email address; (v) a statement by you that you have a good faith belief that use of the material 
        in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (vi) a statement that the information 
        in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Modifying and Terminating our Services</h2>
      <p className="mb-4">
        We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or 
        stop a Service altogether.
      </p>
      <p className="mb-6">
        You can stop using our Services at any time, although we'll be sorry to see you go. Ai Is A Tool may also stop providing Services 
        to you, or add or create new limits to our Services at any time.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Feedback</h2>
      <p className="mb-6">
        We welcome feedback, comments, and suggestions for improvements to the Services. You can submit feedback by emailing us at{' '}
        <a href="mailto:info@aisatool.com" className="text-blue-600 hover:text-blue-800">info@aisatool.com</a>. You grant to us a 
        non-exclusive, irrevocable, worldwide, royalty-free license to use, modify, adapt, publish, translate, distribute, and display 
        any feedback, comments, or suggestions you provide to us regarding the Services. This license allows us to incorporate your 
        feedback into our products and services without any obligation to compensate you.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Changes to the Terms</h2>
      <p className="mb-6">
        We may revise these Terms from time to time to better reflect changes to our Services or for other legal, regulatory, or 
        operational reasons. We will notify you of any material changes to these Terms by posting the new Terms on this page or by 
        sending you an email or other notification, and the updated Terms will be effective upon posting. By continuing to access or 
        use the Services after those revisions become effective, you agree to be bound by the revised Terms.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Governing Law and Dispute Resolution</h2>
      <p className="mb-6">
        These Terms and any action related thereto will be governed by and construed in accordance with the laws of the United Kingdom 
        without regard to its conflict of laws provisions.
      </p>
      <p className="mb-6">
        Any dispute, claim, or controversy arising out of or relating to these Terms or the breach, termination, enforcement, interpretation, 
        or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined 
        by arbitration in the United Kingdom before one arbitrator. The arbitration shall be administered by JAMS pursuant to its 
        Comprehensive Arbitration Rules and Procedures. Judgment on the award may be entered in any court having jurisdiction. This clause 
        shall not preclude parties from seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Miscellaneous</h2>
      <p className="mb-4">
        These Terms constitute the entire agreement between you and Ai Is A Tool regarding the use of the Services, superseding any 
        prior agreements between you and Ai Is A Tool relating to your use of the Services.
      </p>
      <p className="mb-4">
        If any provision of these Terms is found to be unlawful, void, or for any reason unenforceable, then that provision shall be 
        deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions.
      </p>
      <p className="mb-4">
        No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent 
        breach or default.
      </p>
      <p className="mb-4">
        You may not assign, transfer, or sublicense any of your rights or obligations under these Terms without our prior written consent.
      </p>
      <p className="mb-6">
        Any heading, caption, or section title contained in these Terms is inserted only as a matter of convenience and in no way 
        defines or explains any section or provision hereof.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms, please contact us at{' '}
        <a href="mailto:info@aisatool.com" className="text-blue-600 hover:text-blue-800">info@aisatool.com</a>.
      </p>

      <p className="text-gray-600 mt-8 mb-4">
        By accessing or using the Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not 
        access or use the Services.
      </p>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;