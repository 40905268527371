import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

interface SEOMetadata {
  title: string;
  description: string;
  keywords: string[];
}

export async function generateSEOMetadata(
  pageName: string,
  content: string
): Promise<SEOMetadata> {
  try {
    const prompt = `Generate SEO metadata for a website page about AI tools.

Page: ${pageName}
Content: ${content}

Please provide:
1. An SEO-optimized title (max 60 characters)
2. A compelling meta description (max 155 characters)
3. Relevant keywords (comma-separated)

Format the response exactly like this:
Title: [title]
Description: [description]
Keywords: [keyword1, keyword2, keyword3, etc]`;

    const completion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content: "You are an SEO expert specializing in AI technology websites."
        },
        {
          role: "user",
          content: prompt
        }
      ],
      model: "gpt-4-turbo-preview",
      temperature: 0.7,
      max_tokens: 200
    });

    const response = completion.choices[0]?.message?.content || '';
    
    // Parse the response
    const title = response.match(/Title: (.*)/i)?.[1] || pageName;
    const description = response.match(/Description: (.*)/i)?.[1] || '';
    const keywords = response.match(/Keywords: (.*)/i)?.[1]?.split(',').map(k => k.trim()) || [];

    return {
      title,
      description,
      keywords
    };
  } catch (error) {
    console.error('Error generating SEO metadata:', error);
    
    // Return default metadata if generation fails
    return {
      title: pageName,
      description: `Discover the best AI tools for ${pageName.toLowerCase()}. Compare features, pricing, and capabilities.`,
      keywords: ['ai tools', 'artificial intelligence', pageName.toLowerCase()]
    };
  }
}

// Cache for storing generated metadata
const metadataCache = new Map<string, SEOMetadata>();

export async function getPageMetadata(
  pageName: string,
  content: string
): Promise<SEOMetadata> {
  // Check cache first
  const cached = metadataCache.get(pageName);
  if (cached) return cached;

  // Generate new metadata
  const metadata = await generateSEOMetadata(pageName, content);
  
  // Cache the result
  metadataCache.set(pageName, metadata);
  
  return metadata;
}