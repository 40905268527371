import React, { useState, useRef } from 'react';
import { ArrowLeft, Upload, AlertCircle } from 'lucide-react';
import { processUrlBatch } from '../services/batchProcessor';

const ImportToolsPage: React.FC = () => {
  const [urls, setUrls] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0 });
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target?.result as string;
      const lines = text.split('\n').map(line => line.trim()).filter(Boolean);
      // Remove header if exists and get URL column
      const urlList = lines.slice(1).map(line => line.split(',')[0].trim());
      setUrls(urlList);
      setProgress({ current: 0, total: urlList.length });
    };
    reader.readAsText(file);
  };

  const startProcessing = async () => {
    if (urls.length === 0) {
      setError('Please upload a CSV file first');
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      await processUrlBatch(urls, (processed) => {
        setProgress(prev => ({ ...prev, current: processed }));
      });
      
      // Reset after completion
      setUrls([]);
      setProgress({ current: 0, total: 0 });
      alert('Import completed successfully!');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred during processing');
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-white mb-8">Import AI Tools</h1>
        
        <div className="max-w-2xl mx-auto">
          <div className="bg-gray-800 rounded-lg p-6 mb-8">
            <div className="mb-6">
              <label className="block text-white mb-2">Upload CSV File</label>
              <p className="text-gray-400 text-sm mb-4">
                Upload a CSV file with a list of AI tool URLs. The first column should contain the URLs.
              </p>
              <div className="flex flex-col items-center justify-center border-2 border-dashed border-gray-700 rounded-lg p-8 hover:border-indigo-500 transition-colors">
                <Upload className="h-8 w-8 text-gray-400 mb-4" />
                <input
                  type="file"
                  ref={fileInputRef}
                  accept=".csv"
                  onChange={handleFileUpload}
                  className="hidden"
                />
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="text-indigo-400 hover:text-indigo-300"
                >
                  Choose CSV file
                </button>
                <p className="text-gray-500 text-sm mt-2">or drag and drop</p>
              </div>
            </div>

            {urls.length > 0 && (
              <div className="mb-6">
                <h3 className="text-white font-semibold mb-2">URLs to Process: {urls.length}</h3>
                <div className="bg-gray-900 rounded p-4 max-h-40 overflow-y-auto">
                  {urls.map((url, index) => (
                    <div key={index} className="text-gray-400 text-sm mb-1">{url}</div>
                  ))}
                </div>
              </div>
            )}

            {error && (
              <div className="flex items-center gap-2 text-red-400 bg-red-900/20 rounded-lg p-4 mb-6">
                <AlertCircle className="h-5 w-5" />
                <span>{error}</span>
              </div>
            )}

            {processing && progress.total > 0 && (
              <div className="mb-6">
                <div className="flex justify-between text-sm text-gray-400 mb-2">
                  <span>Processing...</span>
                  <span>{progress.current} / {progress.total}</span>
                </div>
                <div className="w-full bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
                    style={{ width: `${(progress.current / progress.total) * 100}%` }}
                  />
                </div>
              </div>
            )}

            <button
              onClick={startProcessing}
              disabled={processing || urls.length === 0}
              className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {processing ? 'Processing...' : 'Start Import'}
            </button>
          </div>

          <div className="bg-gray-800/50 rounded-lg p-6">
            <h3 className="text-white font-semibold mb-4">Instructions</h3>
            <ul className="list-disc list-inside text-gray-400 space-y-2">
              <li>Prepare a CSV file with AI tool URLs in the first column</li>
              <li>URLs will be processed in batches of 3 every 5 minutes</li>
              <li>Each tool will be analyzed and automatically submitted</li>
              <li>You can track the progress in real-time</li>
              <li>The process will continue even if you close the page</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportToolsPage;