import React, { useEffect, useState } from 'react';
import { getAllTools } from '../services/toolService';
import AICard from './AICard';
import { useNavigate } from 'react-router-dom';
import MetaTags from './MetaTags';
import SchemaMarkup from './SchemaMarkup';

// Helper function to generate unique month options from tools
const generateMonthOptions = (tools) => {
  const uniqueMonths = new Set();

  tools.forEach((tool) => {
    const date = new Date(tool.createdAt);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Format month with leading zero
    uniqueMonths.add(`${year}-${month}`);
  });

  // Convert Set to array and sort by date in descending order
  const sortedMonths = Array.from(uniqueMonths).sort((a, b) => new Date(b) - new Date(a));

  // Generate options with month names
  return sortedMonths.map((value) => {
    const [year, month] = value.split('-');
    const date = new Date(parseInt(year), parseInt(month) - 1);
    const monthName = date.toLocaleString('default', { month: 'long' });
    return { value, label: `${monthName} ${year}` };
  });
};

const AllToolsPage: React.FC = () => {
  const navigate = useNavigate();
  const [tools, setTools] = useState<any[]>([]);
  const [displayedTools, setDisplayedTools] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState<string>('');
  const [monthOptions, setMonthOptions] = useState<any[]>([]);

  useEffect(() => {
    // Fetch all tools
    const fetchTools = async () => {
      const allTools = await getAllTools();
      const sortedTools = allTools.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setTools(sortedTools);
      setMonthOptions(generateMonthOptions(sortedTools)); // Generate month options
      loadMoreTools(sortedTools);
    };

    fetchTools();
  }, []);

  // Function to load more tools
  const loadMoreTools = (sortedTools) => {
    const toolsPerPage = 10;
    const startIndex = (page - 1) * toolsPerPage;
    const endIndex = startIndex + toolsPerPage;
    setDisplayedTools((prev) => [...prev, ...sortedTools.slice(startIndex, endIndex)]);
    setPage((prev) => prev + 1);
  };

  // Handle month selection
  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonthValue = e.target.value;
    setSelectedMonth(selectedMonthValue);
    if (selectedMonthValue) {
      const filteredTools = tools.filter((tool) => {
        const toolDate = new Date(tool.createdAt);
        const [year, month] = selectedMonthValue.split('-');
        return toolDate.getFullYear() === parseInt(year) && toolDate.getMonth() === parseInt(month) - 1;
      });
      setDisplayedTools(filteredTools);
    } else {
      setDisplayedTools(tools);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <MetaTags 
        title="All AI Tools Directory"
        description="Explore our comprehensive directory of AI tools. Find and compare the best artificial intelligence solutions for your needs, from content creation to productivity tools."
        content="AI tools directory featuring the latest and most effective artificial intelligence solutions"
      />
      
      <SchemaMarkup
        type="website"
        data={{
          name: "AI Tools Directory",
          description: "Comprehensive collection of AI tools and solutions",
          // You could add more structured data about your tools collection
          itemListElement: displayedTools.map((tool, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
              "@type": "SoftwareApplication",
              "name": tool.name,
              "description": tool.description,
              "applicationCategory": tool.category,
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": tool.rating,
                "reviewCount": tool.reviewCount
              }
            }
          }))
        }}
      />

      <h1 className="text-3xl font-bold text-white mb-8">All AI Tools</h1>

      {/* Month Filter */}
      <div className="mb-4">
        <label htmlFor="month-filter" className="block text-sm text-gray-300 mb-2">Filter by Month:</label>
        <select
          id="month-filter"
          className="bg-gray-800 text-white border border-gray-700 rounded-lg px-4 py-2"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          <option value="">All Months</option>
          {monthOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
        {displayedTools.map((tool, index) => (
          <AICard
            key={index}
            tool={tool}
            onClick={() => navigate(`/tool/${tool.id}`)}
          />
        ))}
      </div>

      {/* Loader element for infinite scroll */}
      <div className="text-center text-gray-400 mt-8">
        Loading more tools...
      </div>
    </div>
  );
};

export default AllToolsPage;
