import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { getPageMetadata } from '../services/seoService';

interface MetaTagsProps {
  title?: string;
  description?: string;
  content?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({ title, description, content }) => {
  const location = useLocation();
  const [metadata, setMetadata] = useState({
    title: title || 'Ai Is A Tool - Find the Perfect AI Tool for Your Needs',
    description: description || 'Discover and compare the best AI tools for your specific needs. Your unfair advantage isn\'t AI. It\'s how you use it.',
    keywords: ['ai tools', 'artificial intelligence', 'productivity tools']
  });

  useEffect(() => {
    const generateMetadata = async () => {
      // Only generate new metadata if no custom title/description provided
      if (!title && !description) {
        try {
          // Get page name from path
          const pageName = location.pathname === '/' 
            ? 'Home'
            : location.pathname.split('/').pop()?.split('-').map(
                word => word.charAt(0).toUpperCase() + word.slice(1)
              ).join(' ') || 'Page';

          // Generate metadata using OpenAI
          const newMetadata = await getPageMetadata(pageName, content || '');
          setMetadata(newMetadata);
        } catch (error) {
          console.error('Error generating metadata:', error);
        }
      }
    };

    generateMetadata();
  }, [location.pathname, title, description, content]);

  const fullTitle = `${metadata.title} | Ai Is A Tool`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords.join(', ')} />
      
      {/* Open Graph */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={window.location.href} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={metadata.description} />
      
      {/* Additional SEO tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#111827" />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default MetaTags;