import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MetaTags from './MetaTags';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';
import { Tool } from '../types/Tool';
import { Category, categories, categoryGroups } from '../data/categories';
import { getAllTools } from '../services/toolService';
import AICard from './AICard';

const CategoryPage: React.FC = () => {
  const { categoryId, subcategoryId } = useParams();
  const navigate = useNavigate();
  const [tools, setTools] = useState<Tool[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const category = categories.find(c => c.slug === categoryId);
  const subcategories = categoryGroups[categoryId || ''] || [];

  useEffect(() => {
    const fetchTools = async () => {
      try {
        setLoading(true);
        const allTools = await getAllTools();
        
        // Debug logs
        console.log('CategoryId:', categoryId);
        console.log('SubcategoryId:', subcategoryId);
        
        // First filter by category
        let filteredTools = allTools.filter(tool => 
          tool.status === 'active' && 
          tool.category.toLowerCase() === categoryId?.toLowerCase()
        );

        // Then filter by subcategory if present
        if (subcategoryId) {
          // Convert URL subcategoryId back to proper format (e.g., "ai-tutoring" -> "AI Tutoring")
          const properSubcategoryName = categoryGroups[categoryId || '']?.find(sub => 
            sub.toLowerCase().replace(/\s+/g, '-') === subcategoryId.toLowerCase()
          );

          console.log('Looking for subcategory:', properSubcategoryName);

          filteredTools = filteredTools.filter(tool => {
            const toolSubcategories = Array.isArray(tool.subcategory) 
              ? tool.subcategory 
              : [tool.subcategory];
            
            return toolSubcategories.some(sub => 
              sub === properSubcategoryName
            );
          });
        }

        console.log('Filtered Tools:', filteredTools);
        setTools(filteredTools);
      } catch (err) {
        console.error('Error fetching tools:', err);
        setError('Failed to load tools');
      } finally {
        setLoading(false);
      }
    };

    if (categoryId) {
      fetchTools();
    }
  }, [categoryId, subcategoryId]);

  const getMetaDescription = () => {
    const baseDesc = category?.description || '';
    const toolCount = tools.length;
    const subcatInfo = subcategoryId 
      ? ` - ${subcategoryId.replace(/-/g, ' ')} tools` 
      : '';
    
    return `Explore ${toolCount} AI tools in ${category?.name}${subcatInfo}. ${baseDesc}`;
  };

  if (!category) {
    return (
      <div className="min-h-screen bg-gray-900 text-white p-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-3xl font-bold mb-4">Category Not Found</h1>
          <p className="text-gray-400">The requested category does not exist.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <MetaTags 
        title={`${category?.name} AI Tools${subcategoryId ? ` - ${subcategoryId.replace(/-/g, ' ')}` : ''} | AI Is A Tool`}
        description={getMetaDescription()}
        content={`
          Category: ${category?.name}
          Tools Available: ${tools.length}
          Subcategories: ${subcategories.join(', ')}
          ${category?.description}
        `}
      />

      <SchemaMarkup 
        type="website"
        data={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          name: `${category?.name} AI Tools`,
          description: category?.description,
          numberOfItems: tools.length,
          publisher: {
            "@type": "Organization",
            name: "AI Is A Tool",
            url: window.location.origin
          },
          mainEntity: {
            "@type": "ItemList",
            itemListElement: tools.map((tool, index) => ({
              "@type": "ListItem",
              position: index + 1,
              item: {
                "@type": "SoftwareApplication",
                name: tool.name,
                description: tool.description,
                applicationCategory: tool.category,
                offers: {
                  "@type": "Offer",
                  price: tool.pricing === 'free' ? '0' : tool.priceDetails?.replace(/[^0-9.]/g, ''),
                  priceCurrency: "USD"
                }
              }
            }))
          },
          breadcrumb: {
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@id": window.location.origin,
                  name: "Home"
                }
              },
              {
                "@type": "ListItem",
                position: 2,
                item: {
                  "@id": `${window.location.origin}/category/${categoryId}`,
                  name: category?.name
                }
              },
              ...(subcategoryId ? [{
                "@type": "ListItem",
                position: 3,
                item: {
                  "@id": `${window.location.origin}/category/${categoryId}/${subcategoryId}`,
                  name: subcategoryId.replace(/-/g, ' ')
                }
              }] : [])
            ]
          }
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold mb-4">{category.name}</h1>
          <p className="text-gray-400 mb-6">{category.description}</p>

          {/* Subcategories */}
          <div className="flex flex-wrap gap-2 mb-8">
            <button
              onClick={() => navigate(`/category/${categoryId}`)}
              className={`px-4 py-2 rounded-full text-sm ${
                !subcategoryId
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
              }`}
            >
              All
            </button>
            {subcategories.map((subcat) => (
              <button
                key={subcat}
                onClick={() => navigate(`/category/${categoryId}/${subcat.toLowerCase().replace(/\s+/g, '-')}`)}
                className={`px-4 py-2 rounded-full text-sm ${
                  subcategoryId === subcat.toLowerCase().replace(/\s+/g, '-')
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                }`}
              >
                {subcat}
              </button>
            ))}
          </div>
        </div>

        {error ? (
          <div className="text-red-500 text-center py-12">{error}</div>
        ) : loading ? (
          <div className="text-center py-12">Loading...</div>
        ) : tools.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {tools.map(tool => (
              <AICard
                key={tool.id}
                tool={tool}
                onClick={() => navigate(`/tool/${tool.id}`)}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 text-gray-400">
            No tools found in this {subcategoryId ? 'subcategory' : 'category'}.
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;