import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MetaTags from './MetaTags';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';
import { categories, categoryGroups } from '../data/categories';
import { getAllTools } from '../services/toolService';
import { Tool } from '../types/Tool';

const CategoriesPage: React.FC = () => {
  const navigate = useNavigate();
  const [toolCounts, setToolCounts] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchToolCounts = async () => {
      try {
        const allTools = await getAllTools();
        const counts: Record<string, Record<string, number>> = {};

        // Count tools for each category and subcategory
        allTools.forEach(tool => {
          if (tool.status === 'active') {
            // Count main category
            if (!counts[tool.category]) {
              counts[tool.category] = { total: 0 };
            }
            counts[tool.category].total++;

            // Count subcategories
            if (tool.subcategory) {
              const subcats = Array.isArray(tool.subcategory) 
                ? tool.subcategory 
                : [tool.subcategory];
              
              subcats.forEach(sub => {
                if (!counts[tool.category][sub]) {
                  counts[tool.category][sub] = 0;
                }
                counts[tool.category][sub]++;
              });
            }
          }
        });

        setToolCounts(counts);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tool counts:', error);
        setLoading(false);
      }
    };

    fetchToolCounts();
  }, []);

  const getTotalTools = () => {
    return Object.values(toolCounts).reduce((sum, category) => sum + (category.total || 0), 0);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <MetaTags 
        title="AI Tool Categories - Browse All AI Tools by Category | AI Is A Tool"
        description={`Explore ${getTotalTools()} AI tools across ${categories.length} categories. Find the perfect AI solution for content creation, image generation, writing, business, and more.`}
        content={`
          Total Categories: ${categories.length}
          Total Tools: ${getTotalTools()}
          Categories: ${categories.map(cat => cat.name).join(', ')}
          Browse AI tools by category and subcategory
        `}
      />

      <SchemaMarkup 
        type="website"
        data={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          name: "AI Tool Categories",
          description: "Browse all AI tools by category",
          numberOfItems: categories.length,
          publisher: {
            "@type": "Organization",
            name: "AI Is A Tool",
            url: window.location.origin
          },
          mainEntity: {
            "@type": "ItemList",
            itemListElement: categories.map((category, index) => ({
              "@type": "ListItem",
              position: index + 1,
              item: {
                "@type": "CategoryCode",
                name: category.name,
                description: category.description,
                url: `${window.location.origin}/category/${category.slug}`,
                numberOfItems: toolCounts[category.slug]?.total || 0,
                hasCategoryCode: categoryGroups[category.slug]?.map(subcategory => ({
                  "@type": "CategoryCode",
                  name: subcategory,
                  url: `${window.location.origin}/category/${category.slug}/${subcategory.toLowerCase().replace(/\s+/g, '-')}`,
                  numberOfItems: toolCounts[category.slug]?.[subcategory] || 0
                }))
              }
            }))
          },
          breadcrumb: {
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@id": window.location.origin,
                  name: "Home"
                }
              },
              {
                "@type": "ListItem",
                position: 2,
                item: {
                  "@id": `${window.location.origin}/categories`,
                  name: "Categories"
                }
              }
            ]
          }
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-8">Categories</h1>
        
        {loading ? (
          <div className="text-center py-12">Loading...</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {categories.map((category) => {
              const categoryCount = toolCounts[category.slug]?.total || 0;
              
              return (
                <div 
                  key={category.slug}
                  className="bg-gray-800 rounded-lg p-6 hover:bg-gray-700/50 transition-colors"
                >
                  <div className="flex items-center justify-between mb-4">
                    <h2 
                      className="text-2xl font-semibold cursor-pointer hover:text-indigo-400"
                      onClick={() => navigate(`/category/${category.slug}`)}
                    >
                      {category.name}
                    </h2>
                    <span className="text-sm text-gray-400">
                      {categoryCount} tools
                    </span>
                  </div>
                  
                  <p className="text-gray-400 mb-6">{category.description}</p>
                  
                  {/* Subcategories */}
                  {categoryGroups[category.slug] && (
                    <div className="space-y-2">
                      <h3 className="text-sm font-medium text-gray-300 mb-3">Subcategories:</h3>
                      <div className="flex flex-wrap gap-2">
                        {categoryGroups[category.slug].map((subcategory) => {
                          const subcategorySlug = subcategory.toLowerCase().replace(/\s+/g, '-');
                          const subcategoryCount = toolCounts[category.slug]?.[subcategory] || 0;
                          
                          return (
                            <button
                              key={subcategorySlug}
                              onClick={() => navigate(`/category/${category.slug}/${subcategorySlug}`)}
                              className="px-3 py-1 text-sm bg-gray-700 hover:bg-gray-600 rounded-full text-gray-300 hover:text-white transition-colors flex items-center gap-2"
                            >
                              <span>{subcategory}</span>
                              {subcategoryCount > 0 && (
                                <span className="text-xs bg-gray-600 px-1.5 py-0.5 rounded-full">
                                  {subcategoryCount}
                                </span>
                              )}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoriesPage;

