import React, { useState } from 'react';
import { ArrowLeft } from 'lucide-react';
import { analyzeUrl } from '../services/urlAnalyzer';
import { categories, categoryGroups } from '../data/categories';
import { addTool } from '../services/toolService';
import { Tool } from '../types/Tool';

const SubmitToolPage: React.FC = () => {
  const [url, setUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [toolName, setToolName] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategories, setSelectedSubcategories] = useState<string[]>([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [customImage, setCustomImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [rating, setRating] = useState(0);
  const [pricing, setPricing] = useState<'free' | 'freemium' | 'free trial' | 'paid'>('free');
  const [priceDetails, setPriceDetails] = useState('');
  const [description, setDescription] = useState('');
  const [keyFeatures, setKeyFeatures] = useState<string[]>([]);
  const [useCases, setUseCases] = useState<string[]>([]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }

      setCustomImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAnalyzeUrl = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      const analysis = await analyzeUrl(url);
      if (analysis.error) {
        setError(analysis.error);
      } else {
        setResult(analysis);
        setWebsiteUrl(url);
        setDescription(analysis.description || '');
        setKeyFeatures(analysis.keyFeatures || []);
        setUseCases(analysis.useCases || []);
        
        // Set the screenshot as the preview image if no custom image
        if (analysis.screenshot && !customImage) {
          setPreviewImage(analysis.screenshot);
        }
        
        const defaultName = url
          .replace(/^https?:\/\//, '')
          .split('/')[0]
          .split('.')
          .slice(0, -1)
          .join(' ')
          .replace(/-/g, ' ')
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        setToolName(defaultName);
      }
    } catch (err) {
      setError('Failed to analyze URL. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!toolName.trim() || rating === 0) {
      setError('Please fill in all required fields and set a rating');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const toolId = toolName
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-|-$/g, '');

      const toolData: Partial<Tool> = {
        id: toolId,
        name: toolName.trim(),
        description,
        url: websiteUrl || url,
        image: previewImage,
        keyFeatures,
        useCases,
        category: selectedCategory,
        subcategories: selectedSubcategories,
        featured: isFeatured,
        rating,
        pricing,
        priceDetails,
        tags: [selectedCategory, ...selectedSubcategories].filter(Boolean)
      };

      await addTool(toolData, customImage);
      alert('Tool submitted successfully!');
      
      // Reset form
      setUrl('');
      setWebsiteUrl('');
      setToolName('');
      setResult(null);
      setSelectedCategory('');
      setSelectedSubcategories([]);
      setIsFeatured(false);
      setCustomImage(null);
      setPreviewImage(null);
      setRating(0);
      setPricing('free');
      setPriceDetails('');
      setDescription('');
      setKeyFeatures([]);
      setUseCases([]);
      
    } catch (err) {
      console.error('Error submitting tool:', err);
      setError('Failed to submit tool. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const subcategories = selectedCategory ? categoryGroups[selectedCategory] || [] : [];

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Submit AI Tool</h1>
        
        <div className="bg-gray-800 rounded-lg p-6 mb-8">
          <div className="mb-6">
            <label className="block text-sm font-medium mb-2">Tool URL</label>
            <div className="flex gap-4">
              <input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="https://example.com"
                className="flex-1 bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <button
                onClick={handleAnalyzeUrl}
                disabled={loading || !url}
                className="bg-indigo-600 hover:bg-indigo-700 px-6 py-2 rounded-lg disabled:opacity-50"
              >
                {loading ? 'Analyzing...' : 'Analyze'}
              </button>
            </div>
          </div>

          {error && (
            <div className="bg-red-900/50 text-red-200 p-4 rounded-lg mb-6">
              {error}
            </div>
          )}

          {result && (
            <div className="space-y-6">
              {/* Screenshot Preview */}
              {result.screenshot && (
                <div>
                  <h2 className="text-xl font-semibold mb-2">Website Screenshot</h2>
                  <div className="relative aspect-video w-full bg-gray-700 rounded-lg overflow-hidden mb-4">
                    <img
                      src={result.screenshot}
                      alt="Website preview"
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        console.error('Screenshot failed to load');
                        e.currentTarget.style.display = 'none';
                      }}
                    />
                  </div>
                  <p className="text-sm text-gray-400">
                    This screenshot will be used as the default image for your tool listing
                  </p>
                </div>
              )}

              <div>
                <h2 className="text-xl font-semibold mb-2">Tool Name</h2>
                <input
                  type="text"
                  value={toolName}
                  onChange={(e) => setToolName(e.target.value)}
                  placeholder="Enter tool name"
                  className="w-full bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Website URL</h2>
                <input
                  type="url"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  placeholder="https://example.com"
                  className="w-full bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Rating</h2>
                <div className="flex gap-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      onClick={() => setRating(star)}
                      className={`text-2xl ${
                        rating >= star ? 'text-yellow-400' : 'text-gray-600'
                      }`}
                    >
                      ★
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Pricing</h2>
                <div className="flex gap-2">
                  {['free', 'freemium', 'free trial', 'paid'].map((option) => (
                    <button
                      key={option}
                      onClick={() => setPricing(option as typeof pricing)}
                      className={`px-4 py-2 rounded-lg ${
                        pricing === option
                          ? 'bg-indigo-600 text-white'
                          : 'bg-gray-700 text-gray-300'
                      }`}
                    >
                      {option.charAt(0).toUpperCase() + option.slice(1)}
                    </button>
                  ))}
                </div>
                {pricing === 'paid' && (
                  <input
                    type="text"
                    value={priceDetails}
                    onChange={(e) => setPriceDetails(e.target.value)}
                    placeholder="Enter pricing details"
                    className="mt-2 w-full bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                )}
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Category</h2>
                <div className="grid grid-cols-1 gap-4">
                  <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>

                  {selectedCategory && (
                    <div>
                      <h3 className="text-lg font-medium mb-2">Subcategories</h3>
                      <div className="grid grid-cols-2 gap-2">
                        {subcategories.map((subcategory) => (
                          <label key={subcategory} className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={selectedSubcategories.includes(subcategory)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedSubcategories([...selectedSubcategories, subcategory]);
                                } else {
                                  setSelectedSubcategories(
                                    selectedSubcategories.filter((sub) => sub !== subcategory)
                                  );
                                }
                              }}
                              className="rounded bg-gray-700 border-gray-600 text-indigo-600 focus:ring-indigo-500"
                            />
                            {subcategory}
                          </label>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Overview</h2>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full h-32 bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Key Features</h2>
                {keyFeatures.map((feature, index) => (
                  <input
                    key={index}
                    type="text"
                    value={feature}
                    onChange={(e) => {
                      const newFeatures = [...keyFeatures];
                      newFeatures[index] = e.target.value;
                      setKeyFeatures(newFeatures);
                    }}
                    className="w-full mb-2 bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                ))}
                <button
                  onClick={() => setKeyFeatures([...keyFeatures, ''])}
                  className="mt-2 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg"
                >
                  Add Feature
                </button>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Use Cases</h2>
                {useCases.map((useCase, index) => (
                  <input
                    key={index}
                    type="text"
                    value={useCase}
                    onChange={(e) => {
                      const newUseCases = [...useCases];
                      newUseCases[index] = e.target.value;
                      setUseCases(newUseCases);
                    }}
                    className="w-full mb-2 bg-gray-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                ))}
                <button
                  onClick={() => setUseCases([...useCases, ''])}
                  className="mt-2 px-4 py-2 bg-gray-700 hover:bg-gray-600 rounded-lg"
                >
                  Add Use Case
                </button>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-2">Custom Image (Optional)</h2>
                <div className="flex items-center gap-4">
                  <div className="flex-1">
                    {previewImage && previewImage !== result.screenshot && (
                      <img
                        src={previewImage}
                        alt="Tool preview"
                        className="w-full h-48 object-cover rounded-lg mb-4"
                      />
                    )}
<input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                      id="image-upload"
                    />
                    <label
                      htmlFor="image-upload"
                      className="inline-block bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded-lg cursor-pointer"
                    >
                      Upload Custom Image
                    </label>
                    <p className="text-sm text-gray-400 mt-2">
                      Optional: Upload a custom image to replace the screenshot.
                      Maximum file size: 5MB. Supported formats: JPG, PNG, GIF
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="featured"
                  checked={isFeatured}
                  onChange={(e) => setIsFeatured(e.target.checked)}
                  className="rounded bg-gray-700 border-gray-600 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="featured" className="text-sm text-gray-300">
                  Feature this tool
                </label>
              </div>

              <div className="mt-8">
                <button
                  onClick={handleSubmit}
                  disabled={loading}
                  className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
                >
                  {loading ? 'Submitting...' : 'Submit Tool'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubmitToolPage;