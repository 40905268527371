import { functions, auth } from '../lib/firebase';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';

export interface AnalysisResult {
  description: string;
  keyFeatures: string[];
  useCases: string[];
  screenshot?: string;
  error?: string;
}

interface AnalyzeToolResponse {
  success: boolean;
  data: string;
}

interface ScreenshotResponse {
  imageUrl: string;
}

export const analyzeUrl = async (url: string): Promise<AnalysisResult> => {
  try {
    // Check authentication
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User must be authenticated');
    }

    // Verify admin status
    const token = await user.getIdTokenResult();
    if (!token.claims.admin) {
      throw new Error('User must have admin privileges');
    }

    // Call our Firebase Function
    const analyzeToolContent = httpsCallable<{ url: string }, AnalyzeToolResponse>(
      functions, 
      'analyzeToolContent'
    );
    const result = await analyzeToolContent({ url });
    
    if (!result.data.success) {
      throw new Error('Analysis failed');
    }

    // Parse the response from our Firebase Function
    const content = result.data.data;
    
    if (!content) {
      throw new Error('No content received from analysis');
    }
    
    // Parse the response
    const description = content.match(/(?:description:?)([^]*?)(?=key features:)/i)?.[1]?.trim() || '';
    
    const features = content.match(/(?:key features:?)([^]*?)(?=use cases:)/i)?.[1]
      ?.split(/[\n•-]/)
      .map(f => f.trim())
      .filter(Boolean) || [];
      
    const cases = content.match(/(?:use cases:?)([^]*?)$/i)?.[1]
      ?.split(/[\n•-]/)
      .map(c => c.trim())
      .filter(Boolean) || [];

    // Generate screenshot using Cloud Function
    const saveScreenshot = httpsCallable<
      { apiUrl: string }, 
      ScreenshotResponse
    >(functions, 'saveScreenshot');
    
    const screenshotResult = await saveScreenshot({ 
      apiUrl: `https://screenshot.abstractapi.com/v1/?api_key=${import.meta.env.VITE_ABSTRACT_API_KEY}&url=${encodeURIComponent(url)}&width=1200&height=630&capture_full_page=false&delay=2&css=.cookie-banner{display:none}&wait_until=load`
    });

    if (!screenshotResult.data.imageUrl) {
      throw new Error('Failed to generate screenshot');
    }

    return {
      description,
      keyFeatures: features.slice(0, 5),
      useCases: cases.slice(0, 5),
      screenshot: screenshotResult.data.imageUrl
    };
  } catch (error) {
    console.error('Error analyzing URL:', error);
    return {
      description: '',
      keyFeatures: [],
      useCases: [],
      error: error instanceof Error ? error.message : 'An unexpected error occurred'
    };
  }
};