export interface Category {
  id: string;
  name: string;
  description: string;
  icon: string;
  slug: string;
}

export const categories: Category[] = [
  {
    id: 'content-creation',
    name: 'Content Creation',
    description: 'AI tools for creating and enhancing various types of content',
    icon: 'PenTool',
    slug: 'content-creation'
  },
  {
    id: 'writing',
    name: 'Writing & Text',
    description: 'AI writing assistants and text enhancement tools',
    icon: 'FileText',
    slug: 'writing'
  },
  {
    id: 'image-generation',
    name: 'Image Generation',
    description: 'AI tools for creating and editing images',
    icon: 'Image',
    slug: 'image-generation'
  },
  {
    id: 'video-creation',
    name: 'Video Creation',
    description: 'AI-powered video creation and editing tools',
    icon: 'Video',
    slug: 'video-creation'
  },
  {
    id: '3d-animation',
    name: '3D & Animation',
    description: 'Tools for 3D modeling and animation',
    icon: 'Box',
    slug: '3d-animation'
  },
  {
    id: 'business',
    name: 'Business & Marketing',
    description: 'AI solutions for business operations and marketing',
    icon: 'Briefcase',
    slug: 'business'
  },
  {
    id: 'productivity',
    name: 'Productivity & Workflow',
    description: 'Tools for improving workflow and efficiency',
    icon: 'Zap',
    slug: 'productivity'
  },
  {
    id: 'development',
    name: 'Development & Engineering',
    description: 'Tools for coding and technical tasks',
    icon: 'Code',
    slug: 'development'
  },
  {
    id: 'data-analysis',
    name: 'Data & Analytics',
    description: 'AI-powered data analysis and insights',
    icon: 'Database',
    slug: 'data-analysis'
  },
  {
    id: 'ai-assistance',
    name: 'AI Assistance & Automation',
    description: 'AI tools for automation and assistance',
    icon: 'Zap',
    slug: 'ai-assistance'
  },
  {
    id: 'audio',
    name: 'Audio & Voice',
    description: 'Tools for audio processing and voice generation',
    icon: 'Music',
    slug: 'audio'
  },
  {
    id: 'education',
    name: 'Education & Learning',
    description: 'AI tools for learning and academic purposes',
    icon: 'BookOpen',
    slug: 'education'
  }
];

export const categoryGroups: Record<string, string[]> = {
  'content-creation': [
    'Content Generation',
    'Content Optimization',
    'Content Strategy',
    'Content Planning',
    'Content Distribution',
    'Content Templates',
    'Content Briefs',
    'SEO Content',
    'Content Analysis',
    'Content Localization',
    'Brand Voice Generation',
    'Creative Direction',
    'Content Workflow',
    'Asset Management',
    'Content QA'
  ],
  'writing': [
    'AI Writing Assistant',
    'Text Generation',
    'Story Generation',
    'Script Writing',
    'Email Writing',
    'Copywriting',
    'Content Rewriting',
    'Text Summarization',
    'Language Translation',
    'Grammar & Style',
    'Technical Writing',
    'Academic Writing',
    'Creative Writing',
    'Documentation',
    'Transcription'
  ],
  'image-generation': [
    'AI Art Creation',
    'Photo Generation',
    'Image Editing',
    'Style Transfer',
    'Image Upscaling',
    'Background Removal/Generation',
    'Character Design',
    'Product Visualization',
    'Logo Design',
    'Icon Design',
    'Image Restoration',
    'Photo Enhancement',
    'Digital Art',
    'Graphic Design',
    'Image Composition'
  ],
  'video-creation': [
    'Video Generation',
    'Video Editing',
    'Video Animation',
    'Video Translation',
    'Video Synthesis',
    'Automated Editing',
    'Scene Generation',
    'Character Animation',
    'Video Enhancement',
    'Video Upscaling',
    'Voice Synthesis',
    'Lip Sync',
    'Motion Graphics',
    'Visual Effects',
    'Video Templates'
  ],
  '3d-animation': [
    '3D Modeling',
    'Character Generation',
    'Scene Creation',
    'Texture Generation',
    'Animation Synthesis',
    'Motion Generation',
    'Environment Design',
    '3D Asset Creation',
    'Material Generation',
    'Rigging & Animation',
    'Expression Generation',
    'Lighting Setup',
    'Physics Simulation',
    'Rendering',
    '3D Style Transfer'
  ],
  'business': [
    'Market Analysis',
    'Sales Intelligence',
    'Customer Analytics',
    'Business Intelligence',
    'Risk Assessment',
    'Trend Analysis',
    'Competitive Analysis',
    'Price Optimization',
    'Lead Generation',
    'Revenue Forecasting',
    'Process Automation',
    'Strategy Planning',
    'Performance Analytics',
    'Resource Management',
    'Marketing Automation'
  ],
  'productivity': [
    'Task Management',
    'Smart Scheduling',
    'Document Processing',
    'Email Management',
    'Meeting Assistant',
    'Note Taking',
    'Process Automation',
    'Time Management',
    'Project Planning',
    'Resource Allocation',
    'Workflow Optimization',
    'Knowledge Management',
    'Team Collaboration',
    'Decision Support',
    'Progress Tracking'
  ],
  'development': [
    'Code Generation',
    'Code Analysis',
    'Bug Detection',
    'Code Optimization',
    'Test Generation',
    'Documentation',
    'API Development',
    'Database Management',
    'Security Analysis',
    'Performance Optimization',
    'Architecture Design',
    'Code Review',
    'Code Completion',
    'DevOps Automation',
    'Development Workflow'
  ],
  'data-analysis': [
    'AI Detection',
    'Predictive Analytics',
    'Pattern Recognition',
    'Anomaly Detection',
    'Time Series Analysis',
    'Text Analytics',
    'Image Analytics',
    'Voice Analytics',
    'Behavioral Analytics',
    'Market Intelligence',
    'Financial Analytics',
    'Performance Metrics',
    'Risk Analytics',
    'Data Visualization',
    'Statistical Analysis',
    'Insight Generation'
  ],
  'ai-assistance': [
    'Chatbots',
    'Virtual Assistants',
    'Customer Support',
    'Query Analysis',
    'Response Generation',
    'Sentiment Analysis',
    'Intent Recognition',
    'Process Automation',
    'Task Automation',
    'Workflow Automation',
    'Decision Support',
    'Knowledge Base',
    'Support Analytics',
    'Conversation Analysis',
    'Service Optimization'
  ],
  'audio': [
    'Voice Synthesis',
    'Speech Recognition',
    'Audio Generation',
    'Music Creation',
    'Sound Effects',
    'Voice Cloning',
    'Audio Enhancement',
    'Noise Reduction',
    'Voice Translation',
    'Accent Transfer',
    'Audio Editing',
    'Audio Separation',
    'Music Analysis',
    'Podcast Tools',
    'Audio Mastering'
  ],
  'education': [
    'AI Tutoring',
    'Learning Analytics',
    'Educational Content',
    'Assessment Tools',
    'Skill Development',
    'Study Planning',
    'Learning Paths',
    'Question Generation',
    'Interactive Learning',
    'Concept Explanation',
    'Practice Generation',
    'Progress Tracking',
    'Personalized Learning',
    'Course Creation',
    'Educational Games'
  ]
};