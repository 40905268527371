import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  // Map paths to readable names and URLs
  const getPathInfo = (path: string, index: number, fullPath: string[]) => {
    // Handle special cases and map generic paths to proper URLs
    switch(path) {
      case 'tool':
        return {
          name: 'Tools',
          url: '/tools' // This would be your tools listing page
        };
      case 'category':
        return {
          name: 'Categories',
          url: '/categories' // This would be your categories listing page
        };
      case 'blog':
        // If it's just /blog, return the blog listing page
        // If it's a blog post, show "Blog" as the parent
        return {
          name: 'Blog',
          url: '/blog'
        };
      default:
        // Remove slug formatting for display
        const name = path.split('-').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');

        // For the last item in the path, don't make it clickable
        const isLast = index === fullPath.length - 1;
        const url = isLast ? null : `/${fullPath.slice(0, index + 1).join('/')}`;

        return { name, url };
    }
  };

  return (
    <nav className="text-sm text-gray-400" aria-label="Breadcrumb">
      <ol className="list-none p-0 inline-flex">
        <li className="flex items-center">
          <Link to="/" className="hover:text-white transition-colors">
            Home
          </Link>
        </li>
        {pathnames.map((value, index) => {
          const { name, url } = getPathInfo(value, index, pathnames);

          return (
            <li key={index} className="flex items-center">
              <ChevronRight className="h-4 w-4 mx-2" />
              {url ? (
                <Link
                  to={url}
                  className="hover:text-white transition-colors"
                >
                  {name}
                </Link>
              ) : (
                <span className="text-white">{name}</span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;