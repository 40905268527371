import React from 'react';
import { Category } from '../types/Category';
import ResponsiveImage from './ResponsiveImage';
import { LucideIcon } from 'lucide-react';

interface CategoryCardProps {
  category: Category;
  onClick: () => void;
  icon?: LucideIcon;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category, onClick, icon: Icon }) => {
  return (
    <div
      onClick={onClick}
      className="group relative bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden cursor-pointer border border-gray-700/50 hover:border-indigo-500/50 transition-all duration-300"
    >
      <div className="p-6">
        <div className="flex items-start gap-4">
          {Icon && (
            <div className="p-3 bg-indigo-500/10 rounded-lg">
              <Icon className="w-6 h-6 text-indigo-400" />
            </div>
          )}
          <div>
            <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-indigo-400 transition-colors">
              {category.name}
            </h3>
            <p className="text-sm text-gray-400 line-clamp-2">
              {category.description}
            </p>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 bg-gradient-to-br from-indigo-500/5 to-purple-500/5 opacity-0 group-hover:opacity-100 transition-opacity" />
    </div>
  );
};

export default CategoryCard;