import React, { useState } from 'react';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import {
  Brain,
  Upload,
  Plus,
  LogOut,
  LayoutDashboard,
  BookOpen,
  Tag,
  FileText,
  Database,
  X,
  PlusCircle,
  Newspaper,
} from 'lucide-react';
import { auth } from '../lib/firebase';

const AdminDashboard: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/admin-console');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col md:flex-row">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-gray-800 border-r border-gray-700 transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 transition-transform duration-300 ease-in-out`}
      >
        <div className="flex items-center justify-between p-6 border-b border-gray-700">
          <div className="flex items-center gap-2">
            <Brain className="h-6 w-6 text-indigo-400" />
            <span className="text-xl font-bold text-white">Admin Console</span>
          </div>
          {/* Close Menu Button for Mobile */}
          <button
            onClick={toggleSidebar}
            className="md:hidden p-2 rounded-lg hover:bg-gray-700 transition-colors"
          >
            <X className="h-6 w-6 text-white" />
          </button>
        </div>

        <nav className="p-4">
          <ul className="space-y-2">
            <li>
              <Link
                to="/admin-console/dashboard"
                className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                onClick={() => setIsSidebarOpen(false)}
              >
                <LayoutDashboard className="h-5 w-5" />
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/admin-console/import"
                className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                onClick={() => setIsSidebarOpen(false)}
              >
                <Upload className="h-5 w-5" />
                Import Tools
              </Link>
            </li>
            <li>
              <Link
                to="/admin-console/submit"
                className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                onClick={() => setIsSidebarOpen(false)}
              >
                <Plus className="h-5 w-5" />
                Submit Tool
              </Link>
            </li>
            <li>
              <Link
                to="/admin-console/blog"
                className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                onClick={() => setIsSidebarOpen(false)}
              >
                <BookOpen className="h-5 w-5" />
                Blog Posts
              </Link>
            </li>
            
            {/* News Section */}
            <li className="pt-4">
              <div className="px-4 py-2 text-sm font-semibold text-gray-400">News Management</div>
              <ul className="space-y-1 pl-2">
                <li>
                  <Link
                    to="/admin-console/news"
                    className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <PlusCircle className="h-5 w-5" />
                    Create News
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin-console/news-posts"
                    className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Newspaper className="h-5 w-5" />
                    News List
                  </Link>
                </li>
              </ul>
            </li>

            {/* Tools Section */}
            <li className="pt-4">
              <div className="px-4 py-2 text-sm font-semibold text-gray-400">Tools Management</div>
              <ul className="space-y-1 pl-2">
                <li>
                  <Link
                    to="/admin-console/ai-tools"
                    className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Database className="h-5 w-5" />
                    Current Tools
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin-console/ai-tools/new"
                    className="flex items-center gap-2 px-4 py-2 text-indigo-400 hover:text-indigo-300 hover:bg-gray-700 rounded-lg"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <PlusCircle className="h-5 w-5" />
                    Add New Tool
                  </Link>
                </li>
              </ul>
            </li>

            {/* Blog Section */}
            <li className="pt-4">
              <div className="px-4 py-2 text-sm font-semibold text-gray-400">Blog Management</div>
              <ul className="space-y-1 pl-2">
                <li>
                  <Link
                    to="/admin-console/blog-posts"
                    className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <FileText className="h-5 w-5" />
                    Current Posts
                  </Link>
                </li>
                <li>
                  <Link
                    to="/admin-console/blog"
                    className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Plus className="h-5 w-5" />
                    New Post
                  </Link>
                </li>
              </ul>
            </li>

            {/* Settings Section */}
            <li className="pt-4">
              <div className="px-4 py-2 text-sm font-semibold text-gray-400">Settings</div>
              <ul className="space-y-1 pl-2">
                <li>
                  <Link
                    to="/admin-console/manage-ads"
                    className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg"
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Tag className="h-5 w-5" />
                    Manage Ads
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>

        <div className="absolute bottom-0 left-0 right-0 p-4">
          <button
            onClick={handleLogout}
            className="flex items-center gap-2 px-4 py-2 text-gray-300 hover:text-white hover:bg-gray-700 rounded-lg w-full"
          >
            <LogOut className="h-5 w-5" />
            Sign Out
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 ml-0 md:ml-64 p-4">
        {/* Mobile Menu Toggle */}
        <button
          onClick={toggleSidebar}
          className="block md:hidden mb-4 text-gray-300"
        >
          {isSidebarOpen ? 'Close Menu' : 'Open Menu'}
        </button>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboard;

