import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Clock, User, Tag } from 'lucide-react';
import { NewsPost } from '../types/NewsPost';
import { getNewsBySlug } from '../services/newsService';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Breadcrumbs from './Breadcrumbs';
import SchemaMarkup from './SchemaMarkup';
import ResponsiveImage from './ResponsiveImage';
import MetaTags from './MetaTags';

const NewsPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [article, setArticle] = useState<NewsPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!slug) return;
      
      try {
        setLoading(true);
        const articleData = await getNewsBySlug(slug);
        if (articleData) {
          setArticle(articleData);
        } else {
          setError('Article not found');
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        setError('Failed to load article');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="text-center text-gray-400">Loading article...</div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="text-center text-red-400 mb-4">
          {error || 'Article not found'}
        </div>
        <Link
          to="/news"
          className="inline-flex items-center text-indigo-400 hover:text-indigo-300"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to News
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <MetaTags 
        title={article.title}
        description={article.excerpt}
        content={article.content}
      />
      
      <SchemaMarkup
        type="NewsArticle"
        data={{
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          headline: article.title,
          description: article.excerpt,
          image: article.featuredImage,
          datePublished: article.publishedAt,
          dateModified: article.updatedAt,
          author: {
            "@type": "Person",
            name: article.author
          },
          publisher: {
            "@type": "Organization",
            name: "Your Site Name",
            url: window.location.origin
          },
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": window.location.href
          }
        }}
      />

      <div className="container mx-auto px-4 py-12">
        <div className="mb-6">
          <Breadcrumbs />
        </div>

        <article className="max-w-4xl mx-auto">
          <Link
            to="/news"
            className="inline-flex items-center text-gray-400 hover:text-white mb-8"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back to News
          </Link>

          <header className="mb-8">
            {article.urgency && (
              <span className={`
                inline-block px-3 py-1 rounded-full text-sm mb-4
                ${article.urgency === 'high' ? 'bg-red-500/20 text-red-400' :
                  article.urgency === 'medium' ? 'bg-yellow-500/20 text-yellow-400' :
                  'bg-green-500/20 text-green-400'}
              `}>
                {article.urgency.charAt(0).toUpperCase() + article.urgency.slice(1)} Priority
              </span>
            )}

            <h1 className="text-4xl font-bold text-white mb-4">{article.title}</h1>
            
            <div className="flex flex-wrap gap-4 text-sm text-gray-400">
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4" />
                <time dateTime={article.publishedAt}>
                  {new Date(article.publishedAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}
                </time>
              </div>

              <div className="flex items-center gap-2">
                <User className="w-4 h-4" />
                <span>{article.author}</span>
              </div>

              {article.source && (
                <div className="flex items-center gap-2">
                  <Tag className="w-4 h-4" />
                  <span>Source: {article.source}</span>
                </div>
              )}
            </div>
          </header>

          {article.featuredImage && (
            <div className="mb-8">
              <ResponsiveImage
                src={article.featuredImage}
                alt={article.title}
                className="w-full rounded-lg"
                size="_1280x720"
              />
            </div>
          )}

          <div className="prose prose-invert prose-lg max-w-none">
            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              className="
                prose-headings:text-white prose-headings:font-bold 
                prose-h1:text-3xl prose-h1:mb-6
                prose-h2:text-2xl prose-h2:mt-8 prose-h2:mb-4
                prose-h3:text-xl prose-h3:mt-6 prose-h3:mb-3
                prose-p:text-gray-300 prose-p:leading-relaxed prose-p:mb-4
                prose-ul:text-gray-300 prose-ul:mb-4
                prose-ol:text-gray-300 prose-ol:mb-4
                prose-li:mb-2
                prose-a:text-indigo-400 prose-a:no-underline hover:prose-a:underline
                prose-strong:text-white
                prose-code:text-indigo-300 prose-code:bg-gray-800 prose-code:px-1 prose-code:rounded
                prose-pre:bg-gray-800 prose-pre:p-4 prose-pre:rounded-lg
                prose-blockquote:border-l-4 prose-blockquote:border-indigo-400 prose-blockquote:pl-4 prose-blockquote:text-gray-300
                prose-img:rounded-lg
              "
            >
              {article.content}
            </ReactMarkdown>
          </div>

          {article.tags && article.tags.length > 0 && (
            <div className="mt-8 pt-8 border-t border-gray-800">
              <div className="flex flex-wrap gap-2">
                {article.tags.map((tag) => (
                  <span
                    key={tag}
                    className="px-3 py-1 bg-gray-800 rounded-full text-sm text-gray-300"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}
        </article>
      </div>
    </div>
  );
};

export default NewsPostPage; 