import React from 'react';
import { Shield } from 'lucide-react';
import MetaTags from './MetaTags';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <MetaTags 
        title="Privacy Policy - AI Is A Tool"
        description="Learn about how AI Is A Tool collects, uses, and protects your personal information. Our privacy policy explains your rights and our data protection practices."
        content={`
          Privacy Policy for AI Is A Tool
          Last updated: ${new Date().toISOString().split('T')[0]}
          Includes information about:
          - Data Collection
          - Information Usage
          - Cookie Policy
          - User Rights
          - GDPR Compliance
          - Contact Information
        `}
      />

      <SchemaMarkup 
        type="website"
        data={{
          "@context": "https://schema.org",
          "@type": "PrivacyPolicy",
          name: "AI Is A Tool Privacy Policy",
          description: "Privacy Policy and data protection information for AI Is A Tool users",
          publisher: {
            "@type": "Organization",
            name: "AI Is A Tool",
            url: window.location.origin
          },
          inLanguage: "en-GB",
          datePublished: "2024-01-01", // Update with actual date
          dateModified: new Date().toISOString().split('T')[0],
          author: {
            "@type": "Organization",
            name: "i Enterprise Ltd",
            address: {
              "@type": "PostalAddress",
              streetAddress: "The Deep Business Centre, Tower Street",
              addressLocality: "Hull",
              postalCode: "HU1 4BG",
              addressCountry: "UK"
            }
          }
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <div className="max-w-3xl mx-auto">
        <div className="flex items-center gap-3 mb-8">
          <Shield className="h-8 w-8 text-indigo-400" />
          <h1 className="text-3xl font-bold">Privacy Policy</h1>
        </div>

        <div className="space-y-8 text-gray-300">
          <section>
            <h2 className="text-2xl font-semibold text-white mb-4">Information We Collect</h2>
            <p className="mb-6">
        We acknowledge that your privacy is of utmost importance and take it very seriously. This Privacy Policy outlines 
        Ai Is A Tool's policies and procedures regarding the collection, use, and disclosure of your information when you 
        utilize the Ai Is A Tool Service, through the Ai Is A Tool website, your mobile phone, or one of Ai Is A Tool's 
        applications. We shall not use or share your information with anyone except as described in this Privacy Policy. 
        This Privacy Policy does not apply to information we collect through other means (including offline) or from other 
        sources. Capitalized terms not defined in this Privacy Policy have the meaning given to them in our Terms of Service.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Information Collection and Use</h2>
      <p className="mb-6">
        Ai Is A Tool uses information we collect to analyze how the Service is used, diagnose service or technical issues, 
        maintain security, personalize content, remember information to help you efficiently access your account, monitor 
        aggregate metrics such as the total number of visitors, traffic, and demographic patterns, and track User Content 
        and users as necessary to comply with the Copyright, Designs and Patents Act 1988 and other applicable UK laws.
      </p>

      <h3 className="text-xl font-bold mt-6 mb-3">User-Provided Information:</h3>
      <p className="mb-4">
        You provide us with information about yourself, such as your name and email address, if you register for a member 
        account with the Service. Your name and other information you choose to add to your profile will be available for 
        public viewing on the Service. You may provide us with your mobile phone number if you choose to submit questions 
        and receive answers via text message. We may use your email address to send you Service-related notices (including 
        any notices required by law, in lieu of communication by postal mail). You can control the receipt of certain 
        Service-related messages on your Settings page. We may also use your contact information to send you marketing 
        email messages. If you do not wish to receive such messages, you may opt-out by following the instructions in 
        the message. If you correspond with us by email, we may retain the content of your email messages, your email 
        address, and our responses.
      </p>

      <p className="mb-4">
        If you use your Facebook, Twitter, or other social networking site ("SNS") account information to sign in to 
        Ai Is A Tool, we will collect and store your SNS user ID.
      </p>

      <p className="mb-4">
        You also provide us with information in User Content you post to the Service. Your questions, answers, and other 
        contributions on the Service, and metadata about them (such as when you posted them), are publicly viewable on 
        the Service, along with your name (unless the Service permits you to post anonymously). This information may be 
        searched by search engines and republished elsewhere on the Web in accordance with our Terms of Service.
      </p>

      <p className="mb-6">
        If you choose to use our invitation service to invite a friend to the Service, we will ask for that person's 
        email address and automatically send an email invitation. You may also use our contact importer tool to help 
        you upload your friends' email addresses so you can find friends who are already on Ai Is A Tool or invite 
        friends to the Service. Ai Is A Tool stores this information to send invitations, to register your friend if 
        your invitation is accepted, to track the success of our invitation service, and to identify your friends on Ai Is A Tool.
      </p>

      <h3 className="text-xl font-bold mt-6 mb-3">Information Collected Automatically:</h3>
      <p className="mb-4">When you use the Service, we employ persistent and session cookies and other tracking technologies to:</p>
      
      <ul className="list-disc pl-8 mb-4">
        <li>store your username and password;</li>
        <li>analyze the usage of the Service;</li>
        <li>customize the Service to your preferences; and</li>
        <li>control the advertising displayed by the Service.</li>
      </ul>

      <p className="mb-4">
        A persistent cookie remains on your computer after you close your browser so that it can be used by your browser 
        on subsequent visits to the Service. Persistent cookies can be removed by following your web browser's directions. 
        A session cookie is temporary and disappears after you close your browser. You can reset your web browser to 
        refuse all cookies or to indicate when a cookie is being sent. However, some features of the Service may not 
        function properly if the ability to accept cookies is disabled.
      </p>

      <p className="mb-4">
        We use Google Analytics for aggregated, anonymized website traffic analysis. To track your session usage, Google 
        drops a cookie (_ga) with a randomly-generated ClientID in your browser. This ID is anonymized and contains no 
        identifiable information like email, phone number, name, etc. We also send Google your IP Address. We use GA to 
        track aggregated website behavior, such as what pages you looked at, for how long, and so on. This information 
        is important to us for improving the user experience and determining site effectiveness. If you would like to 
        access what browsing information we have – or ask us to delete any GA data – please delete your _ga cookies, 
        reach out to us via this form, and/or install the Google Analytics Opt-Out Browser Add-On.
      </p>

      <p className="mb-4">
        We may also use other Internet technologies, log files, clear GIFs, Flash technologies, and other similar 
        technologies, to deliver or communicate with cookies and track your use of the Service. We also may include 
        clear GIFs in HTML-based emails sent to our users to determine whether the message has been opened. As we 
        adopt additional technology, we may also gather additional information through other methods.
      </p>

      <p className="mb-4">
        We use these automated technologies to collect and analyze certain types of information we consider to be 
        non-personal information, including:
      </p>

      <ul className="list-disc pl-8 mb-4">
        <li className="mb-2">
          information related to the devices you use to access or interact with the Service, such as: IP addresses, 
          geolocation information, unique device identifiers, and other information about your mobile phone or other 
          mobile device(s), browser types, browser language; and
        </li>
        <li className="mb-2">
          information related to the ways in which you interact with the Service, such as: referring and exit pages 
          and URLs, platform type, the number of clicks, domain names, landing pages, pages viewed and the order of 
          those pages, the amount of time spent on particular pages, the date and time you used the Service, and 
          other similar information.
        </li>
      </ul>

      <p className="mb-4">We may also capture other data, such as search criteria and results.</p>

      <p className="mb-4">
        We may collect different types of information about your location, including general information (e.g., IP 
        address, postcode) and more specific information (e.g., GPS-based functionality on mobile devices used to 
        access the Service) and may use that information to customize the Service with location-based information 
        and features. If you access the Service through a mobile device and you do not want your device to provide 
        us with location-tracking information, you can disable the GPS or other location-tracking functions on your 
        device, provided your device allows you to do this.
      </p>

      <p className="mb-4">
        Ai Is A Tool may allow third parties to serve advertisements on the Service and use other third parties to 
        help us collect and analyze information about your use of the service, generate aggregate site usage statistics 
        and provide content sharing services to support the Service. These third parties may also use cookies and 
        similar technologies to collect similar non-personal information about your use of the Service. Ai Is A Tool 
        does not control the third parties' use of such technologies and their use is governed by those parties' 
        privacy policies. For more information about third-party ad networks that use these technologies, and to 
        opt-out of their collection of information, see http://www.networkadvertising.org/managing/opt_out.asp.
      </p>

      <p className="mb-6">We do not sell any client or consumer data to any third parties.</p>

      <h2 className="text-2xl font-bold mt-8 mb-4">How We Share Your Information</h2>
      
      <h3 className="text-xl font-bold mt-6 mb-3">Personally Identifiable Information:</h3>
      <p className="mb-4">
        Ai Is A Tool may share your personally identifiable information with third parties for the purpose of providing 
        the Service to you or with third parties offering combined services with Ai Is A Tool. If we do this, such 
        third parties' use of your information will be bound by this Privacy Policy. We may store personal information 
        in locations outside the direct control of Ai Is A Tool (for instance, on servers or databases co-located with 
        hosting providers).
      </p>

      <p className="mb-4">
        As we develop our business, we may buy or sell assets or business offerings. Customer, email, and visitor 
        information is generally one of the transferred business assets in these types of transactions. We may also 
        transfer or assign such information in the course of corporate divestitures, mergers, or dissolution.
      </p>

      <p className="mb-4">
        We may share or disclose your information with your consent, such as if you choose to sign on to the Service 
        through a third-party service. We cannot control third parties' use of your information.
      </p>

      <p className="mb-6">
        Ai Is A Tool may disclose your personal information if required to do so by law or subpoena or if we believe 
        that it is reasonably necessary to comply with a law, regulation, or legal request; to protect the safety of 
        any person; to address fraud, security, or technical issues; or to protect Ai Is A Tool's rights or property.
      </p>

      <h3 className="text-xl font-bold mt-6 mb-3">Non-Personally Identifiable Information:</h3>
      <p className="mb-6">
        We may aggregate and/or anonymize information collected through the Service so that the information does not 
        identify you. We may use aggregated, anonymized, and other non-personally identifiable information for any 
        purpose, including for research and marketing purposes, and our use and disclosure of such information are not 
        subject to any restrictions under this Privacy Policy.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">How We Protect Your Information</h2>
      <p className="mb-4">
        Ai Is A Tool uses commercially reasonable physical, managerial, and technical safeguards to preserve the 
        integrity and security of your personal information. We cannot, however, ensure or warrant the security of any 
        information you transmit to Ai Is A Tool or guarantee that your information on the Service may not be accessed, 
        disclosed, altered, or destroyed by the breach of any of our physical, technical, or managerial safeguards.
      </p>

      <p className="mb-6">
        In the event that personal information is compromised as a result of a breach of security, Ai Is A Tool will 
        promptly notify those persons whose personal information has been compromised, in accordance with the notification 
        procedures set forth in this Privacy Policy or as otherwise required by applicable law.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Your Choices About Your Information</h2>
      <p className="mb-6">
        You may, of course, decline to submit personally identifiable information through the Service, in which case 
        Ai Is A Tool may not be able to provide certain services to you. You may update or correct your account 
        information and email preferences at any time by logging in to your account.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Children's Privacy</h2>
      <p className="mb-6">
        Protecting the privacy of young children is especially important. Ai Is A Tool does not knowingly collect or 
        solicit personal information from anyone under the age of 13 or knowingly allow such persons to register with 
        the Service. If we become aware that we have collected personal information from a child under age 13 without 
        verification of parental consent, we take steps to remove that information.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Links to Other Web Sites</h2>
      <p className="mb-6">
        We are not responsible for the practices employed by websites linked to or from the Service, nor the information 
        or content contained therein. Please remember that when you use a link to go from the Service to another website, 
        our Privacy Policy is no longer in effect. Your browsing and interaction on any other website, including those 
        that have a link on our website, is subject to that website's own rules and policies.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Integration and Development of Generative Pre-trained Transformer (GPT) Models</h2>
      <p className="mb-6">
        In our pursuit of technological innovation, we have developed and integrated multiple Generative Pre-trained 
        Transformer (GPT) models. These advanced AI models are not only a part of our website's interactive features 
        but are also utilized in various other applications to enhance user experience and provide sophisticated 
        conversational capabilities. When interacting with any of our GPT models, whether on our website or through 
        other platforms, please be aware that your interactions may be processed and analyzed to improve the model's 
        accuracy and responsiveness. We are committed to maintaining the highest standards of data privacy and 
        confidentiality. Personal information gathered during interactions with our GPT models is not stored or used 
        beyond the scope of the specific interaction, adhering strictly to our privacy policy and relevant data 
        protection regulations. Our commitment to data security and privacy extends across all our GPT applications. 
        For further information or inquiries about how your data is managed in relation to our GPT models, please 
        feel free to contact us.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Changes to Our Privacy Policy</h2>
      <p className="mb-6">
        If we change our privacy policies and procedures, we will post those changes on this page to keep you aware 
        of what information we collect, how we use it, and under what circumstances we may disclose it. Changes to 
        this Privacy Policy are effective when they are posted on this page.
      </p>

      <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
      <p className="mb-4">
        If you have any questions about our practices, Terms and Conditions, or this Privacy Policy, please contact us 
        using{' '}
        <a href="mailto:info@aiisatool.com" className="text-blue-600 hover:text-blue-800">
          info@aiisatool.com
        </a>{' '}
        or send mail to:
      </p>

      <p className="mb-6">
        Ai Is A Tool, Attn: Privacy at i Enterprise Ltd, The Deep Business Centre, Tower Street, Hull HU1 4BG
      </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;