import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Newspaper, Rss } from 'lucide-react';
import { NewsPost } from '../types/NewsPost';
import { getPublishedNews } from '../services/newsService';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';
import ResponsiveImage from './ResponsiveImage';
import MetaTags from './MetaTags';

const NewsPage: React.FC = () => {
  const [news, setNews] = useState<NewsPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        setLoading(true);
        setError(null);
        const fetchedNews = await getPublishedNews();
        setNews(fetchedNews);
      } catch (error) {
        console.error('Error fetching news:', error);
        setError('Failed to load news articles');
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center gap-3 mb-8">
              <Newspaper className="h-8 w-8 text-indigo-400" />
              <h1 className="text-3xl font-bold">News</h1>
            </div>
            <div className="text-center text-gray-400">
              <div className="animate-pulse">Loading news...</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <MetaTags 
        title="Latest News & Updates"
        description="Stay updated with our latest news and announcements"
        content={`Latest News Articles: ${news.length} articles available`}
      />
      
      <SchemaMarkup 
        type="CollectionPage"
        data={{
          "@context": "https://schema.org",
          "@type": "CollectionPage",
          name: "News",
          description: "Latest news and updates",
          publisher: {
            "@type": "Organization",
            name: "Your Site Name",
            url: window.location.origin
          },
          mainEntity: {
            "@type": "ItemList",
            itemListElement: news.map((item, index) => ({
              "@type": "ListItem",
              position: index + 1,
              item: {
                "@type": "NewsArticle",
                headline: item.title,
                description: item.excerpt,
                image: item.featuredImage,
                datePublished: item.publishedAt,
                dateModified: item.updatedAt,
                author: {
                  "@type": "Person",
                  name: item.author
                }
              }
            }))
          }
        }}
      />

      <div className="container mx-auto px-4 py-12">
        <div className="mb-6">
          <Breadcrumbs />
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-3">
              <Newspaper className="h-8 w-8 text-indigo-400" />
              <h1 className="text-3xl font-bold">News</h1>
            </div>
            
            <a 
              href="/rss/news.xml"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-indigo-400 hover:text-indigo-300"
              onClick={(e) => {
                if (!news.length) {
                  e.preventDefault();
                  alert('RSS feed is being generated. Please try again in a moment.');
                }
              }}
            >
              <Rss className="h-4 w-4" />
              <span>RSS Feed</span>
            </a>
          </div>

          {error ? (
            <div className="bg-red-900/20 border border-red-500/20 rounded-lg p-4">
              <p className="text-red-400">{error}</p>
            </div>
          ) : news.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-400">No news articles available yet.</p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {news.map((article) => (
                <article 
                  key={article.id} 
                  className="bg-gray-800/50 border border-gray-700 rounded-lg overflow-hidden hover:bg-gray-800/70 transition-all duration-200"
                >
                  <Link 
                    to={`/news/${article.slug}`}
                    className="block group"
                  >
                    {article.featuredImage && (
                      <div className="aspect-video w-full">
                        <ResponsiveImage
                          src={article.featuredImage}
                          alt={article.title}
                          className="w-full h-full object-cover"
                          size="_640x360"
                          loading="lazy"
                        />
                      </div>
                    )}
                    
                    <div className="p-4">
                      {article.urgency && (
                        <span className={`
                          inline-block px-2 py-1 rounded-full text-xs mb-2
                          ${article.urgency === 'high' ? 'bg-red-500/20 text-red-400' :
                            article.urgency === 'medium' ? 'bg-yellow-500/20 text-yellow-400' :
                            'bg-green-500/20 text-green-400'}
                        `}>
                          {article.urgency.charAt(0).toUpperCase() + article.urgency.slice(1)} Priority
                        </span>
                      )}

                      <h2 className="text-xl font-semibold text-white group-hover:text-indigo-400 transition-colors mb-2">
                        {article.title}
                      </h2>
                      
                      <div className="text-sm text-gray-400 mb-3">
                        <time dateTime={article.publishedAt}>
                          {new Date(article.publishedAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        </time>
                      </div>

                      <p className="text-sm text-gray-300 mb-4 line-clamp-3">
                        {article.excerpt}
                      </p>

                      {article.tags && article.tags.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                          {article.tags.slice(0, 3).map((tag) => (
                            <span
                              key={tag}
                              className="px-2 py-0.5 bg-gray-700/50 rounded-full text-xs text-gray-300"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </Link>
                </article>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsPage; 