import { functions, auth } from '../lib/firebase';
import { httpsCallable } from 'firebase/functions';
import { BlogPost } from '../types/BlogPost';
import { db } from '../lib/firebase';
import { collection, addDoc } from 'firebase/firestore';

export async function generateBlogPost(title: string): Promise<BlogPost> {
  try {
    // Check if user is authenticated
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User must be authenticated');
    }

    // Get user token to verify admin status
    const token = await user.getIdTokenResult();
    if (!token.claims.admin) {
      throw new Error('User must have admin privileges');
    }

    const generateBlogContent = httpsCallable(functions, 'generateBlogContent');
    const result = await generateBlogContent({ 
      type: 'general',
      title
    });
    
    if (!result.data) {
      throw new Error('No content received from generation function');
    }
    
    const content = result.data.data as string;
    
    // Generate a slug from the title
    const slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');

    // Extract first paragraph as excerpt
    const excerpt = content.split('\n\n')[0].replace(/[#*_]/g, '').trim();

    return {
      id: '', // Will be set by Firestore
      title,
      slug,
      content,
      excerpt,
      category: '',
      tags: [],
      author: 'Ai Is A Tool Team',
      publishedAt: new Date().toISOString(),
      status: 'draft',
      createdBy: user.uid, // Track who created the post
      createdAt: new Date().toISOString()
    };
  } catch (error) {
    console.error('Error generating blog post:', error);
    // More detailed error message
    throw new Error(`Failed to generate blog post: ${error.message}`);
  }
}

export async function generateReinforcementLearningPost(): Promise<void> {
  try {
    // Check if user is authenticated
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User must be authenticated');
    }

    // Get user token to verify admin status
    const token = await user.getIdTokenResult();
    if (!token.claims.admin) {
      throw new Error('User must have admin privileges');
    }

    const generateBlogContent = httpsCallable(functions, 'generateBlogContent');
    const result = await generateBlogContent({ type: 'rl' });

    if (!result.data) {
      throw new Error('No content received from generation function');
    }

    const content = result.data.data as string;

    if (content) {
      await addDoc(collection(db, 'blog_posts'), {
        title: 'Reinforcement Learning Algorithms: Beginner\'s Tutorial 2024',
        content,
        category: 'AI Development',
        tags: ['reinforcement learning', 'AI', 'machine learning', 'algorithms'],
        author: 'Ai Is A Tool Team',
        status: 'draft',
        createdBy: user.uid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      });
    }
  } catch (error) {
    console.error('Error generating blog post:', error);
    throw new Error(`Failed to generate RL post: ${error.message}`);
  }
}