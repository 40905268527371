import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Advertisement } from '../types/Advertisement';

export const getActiveAds = async (): Promise<Advertisement[]> => {
  try {
    const adsRef = collection(db, 'advertisements');
    const q = query(
      adsRef,
      where('active', '==', true),
      orderBy('position')
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as Advertisement));
  } catch (error) {
    console.error('Error fetching ads:', error);
    return [];
  }
}; 