import React, { useState, useEffect } from 'react';
import { Upload, Trash2, Loader2, ExternalLink } from 'lucide-react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../lib/firebase';
import { Advertisement } from '../types/Advertisement';

interface AdState {
  uploadedImageUrl: string;
  linkUrl: string;
  openInNewTab: boolean;
  rel: string;
  imageUploaded: boolean;
  imageFile: File | null;
}

const AdminAdsPage: React.FC = () => {
  const [ads, setAds] = useState<Advertisement[]>([]);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState<number | null>(null);
  const [adStates, setAdStates] = useState<{ [position: number]: AdState }>({
    1: { uploadedImageUrl: '', linkUrl: '', openInNewTab: false, rel: 'dofollow', imageUploaded: false, imageFile: null },
    2: { uploadedImageUrl: '', linkUrl: '', openInNewTab: false, rel: 'dofollow', imageUploaded: false, imageFile: null },
    3: { uploadedImageUrl: '', linkUrl: '', openInNewTab: false, rel: 'dofollow', imageUploaded: false, imageFile: null }
  });

  useEffect(() => {
    fetchAds();
  }, []);

  const fetchAds = async () => {
    setLoading(true);
    try {
      const adsRef = collection(db, 'advertisements');
      const snapshot = await getDocs(adsRef);
      const adsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Advertisement));
      setAds(adsData);
    } catch (error) {
      console.error('Error fetching ads:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageSelect = (file: File, position: number) => {
    // Create a local URL for preview
    const imageUrl = URL.createObjectURL(file);
    setAdStates(prev => ({
      ...prev,
      [position]: {
        ...prev[position],
        imageFile: file,
        uploadedImageUrl: imageUrl,
        imageUploaded: false
      }
    }));
  };

  const handleSaveAd = async (position: number) => {
    const adState = adStates[position];
    if (!adState.imageFile) return;

    setUploading(position);
    try {
      // Upload image to Firebase Storage
      const imageRef = ref(storage, `ad-images/${Date.now()}-${adState.imageFile.name}`);
      const uploadResult = await uploadBytes(imageRef, adState.imageFile);
      const imageUrl = await getDownloadURL(uploadResult.ref);

      const adData: Omit<Advertisement, 'id'> = {
        imageUrl,
        linkUrl: adState.linkUrl || '',
        toolId: null,
        position,
        active: true,
        createdAt: new Date().toISOString(),
        openInNewTab: adState.openInNewTab,
        rel: adState.rel
      };

      await addDoc(collection(db, 'advertisements'), adData);
      await fetchAds();

      // Reset adState for this position
      setAdStates(prev => ({
        ...prev,
        [position]: {
          uploadedImageUrl: '',
          linkUrl: '',
          openInNewTab: false,
          rel: 'dofollow',
          imageUploaded: false,
          imageFile: null
        }
      }));
    } catch (error) {
      console.error('Error saving ad:', error);
    } finally {
      setUploading(null);
    }
  };

  const handleDelete = async (adId: string) => {
    try {
      await deleteDoc(doc(db, 'advertisements', adId));
      await fetchAds();
    } catch (error) {
      console.error('Error deleting ad:', error);
    }
  };

  const handleUpdateAd = async (adId: string, updatedData: Partial<Advertisement>) => {
    try {
      await updateDoc(doc(db, 'advertisements', adId), updatedData);
      await fetchAds();
    } catch (error) {
      console.error('Error updating ad:', error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-white mb-6">Manage Advertisements</h1>

      <div className="space-y-6">
        {[1, 2, 3].map((position) => {
          const ad = ads.find(ad => ad.position === position);
          const adState = adStates[position];

          return (
            <div key={position} className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-lg font-semibold text-white mb-4">
                Advertisement Slot {position}
              </h2>

              {ad ? (
                <div className="relative rounded-lg overflow-hidden">
                  <img 
                    src={ad.imageUrl}
                    alt={`Advertisement ${position}`}
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <button
                    onClick={() => handleDelete(ad.id)}
                    className="absolute top-2 right-2 p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
                  >
                    <Trash2 className="h-4 w-4 text-white" />
                  </button>
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="relative">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          handleImageSelect(file, position);
                        }
                      }}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      disabled={uploading === position}
                    />
                    {adState.uploadedImageUrl ? (
                      <img
                        src={adState.uploadedImageUrl}
                        alt={`Ad Preview ${position}`}
                        className="w-full h-auto object-cover rounded-lg"
                      />
                    ) : (
                      <div className="w-32 h-32 border-2 border-dashed border-gray-700 rounded-lg flex items-center justify-center">
                        <div className="text-center">
                          <Upload className="h-6 w-6 text-gray-400 mx-auto mb-2" />
                          <span className="text-sm text-gray-400">
                            Click to upload image
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <button
                    onClick={() => handleSaveAd(position)}
                    disabled={!adState.imageFile || uploading === position}
                    className={`w-full px-4 py-2 rounded-lg transition-colors ${
                      !adState.imageFile || uploading === position
                        ? 'bg-gray-600 cursor-not-allowed'
                        : 'bg-green-600 hover:bg-green-700'
                    } text-white flex items-center justify-center gap-2`}
                  >
                    {uploading === position ? (
                      <>
                        <Loader2 className="h-4 w-4 animate-spin" />
                        Saving...
                      </>
                    ) : (
                      'Save Advertisement'
                    )}
                  </button>
                </div>
              )}

              <div className="flex gap-4 mt-4">
                <input
                  type="text"
                  value={ad ? ad.linkUrl : adState.linkUrl}
                  onChange={(e) => {
                    if (ad) {
                      handleUpdateAd(ad.id, { linkUrl: e.target.value });
                    } else {
                      setAdStates(prev => ({
                        ...prev,
                        [position]: {
                          ...prev[position],
                          linkUrl: e.target.value
                        }
                      }));
                    }
                  }}
                  placeholder="Enter link URL..."
                  className="flex-grow px-4 py-2 bg-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                {(ad ? ad.linkUrl : adState.linkUrl) && (
                  <a
                    href={ad ? ad.linkUrl : adState.linkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
                  >
                    <ExternalLink className="h-5 w-5 text-gray-400" />
                  </a>
                )}
              </div>

              <div className="flex items-center gap-4 mt-2">
                <label className="flex items-center text-sm text-gray-400">
                  <input
                    type="checkbox"
                    checked={ad ? ad.openInNewTab : adState.openInNewTab}
                    onChange={(e) => {
                      if (ad) {
                        handleUpdateAd(ad.id, { openInNewTab: e.target.checked });
                      } else {
                        setAdStates(prev => ({
                          ...prev,
                          [position]: {
                            ...prev[position],
                            openInNewTab: e.target.checked
                          }
                        }));
                      }
                    }}
                  />
                  <span className="ml-2">Open in new tab</span>
                </label>
                <label className="flex items-center text-sm text-gray-400">
                  <span className="mr-2">Link Type:</span>
                  <select
                    value={ad ? ad.rel : adState.rel}
                    onChange={(e) => {
                      if (ad) {
                        handleUpdateAd(ad.id, { rel: e.target.value });
                      } else {
                        setAdStates(prev => ({
                          ...prev,
                          [position]: {
                            ...prev[position],
                            rel: e.target.value
                          }
                        }));
                      }
                    }}
                    className="bg-gray-700 rounded-lg text-white px-2 py-1"
                  >
                    <option value="dofollow">DoFollow</option>
                    <option value="nofollow">NoFollow</option>
                  </select>
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminAdsPage;


