interface Organization {
  '@type': 'Organization';
  name: string;
  url: string;
  logo: string;
  sameAs: string[];
}

interface WebSite {
  '@type': 'WebSite';
  name: string;
  url: string;
  potentialAction: {
    '@type': 'SearchAction';
    target: string;
    'query-input': string;
  };
}

interface Article {
  '@type': 'Article';
  headline: string;
  description: string;
  image: string;
  datePublished: string;
  dateModified: string;
  author: {
    '@type': 'Person';
    name: string;
  };
  publisher: Organization;
}

interface SoftwareApplication {
  '@type': 'SoftwareApplication';
  name: string;
  description: string;
  applicationCategory: string;
  operatingSystem: string;
  offers: {
    '@type': 'Offer';
    price: string;
    priceCurrency: string;
  };
  aggregateRating?: {
    '@type': 'AggregateRating';
    ratingValue: string;
    reviewCount: string;
  };
}

export function generateOrganizationSchema(): Organization {
  return {
    '@type': 'Organization',
    name: 'Ai Is A Tool',
    url: 'https://aiisatool.com',
    logo: 'https://aiisatool.com/logo.png',
    sameAs: [
      'https://twitter.com/aiisatool',
      'https://facebook.com/aiisatool',
      'https://linkedin.com/company/aiisatool'
    ]
  };
}

export function generateWebsiteSchema(): WebSite {
  return {
    '@type': 'WebSite',
    name: 'Ai Is A Tool',
    url: 'https://aiisatool.com',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://aiisatool.com/search?q={search_term_string}',
      'query-input': 'required name=search_term_string'
    }
  };
}

export function generateArticleSchema(
  title: string,
  description: string,
  image: string,
  publishDate: string,
  modifyDate: string,
  authorName: string
): Article {
  return {
    '@type': 'Article',
    headline: title,
    description: description,
    image: image,
    datePublished: publishDate,
    dateModified: modifyDate,
    author: {
      '@type': 'Person',
      name: authorName
    },
    publisher: generateOrganizationSchema()
  };
}

export function generateToolSchema(
  name: string,
  description: string,
  category: string,
  price: string = '0',
  rating?: number,
  reviewCount?: number
): SoftwareApplication {
  const schema: SoftwareApplication = {
    '@type': 'SoftwareApplication',
    name,
    description,
    applicationCategory: category,
    operatingSystem: 'Web-based',
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency: 'USD'
    }
  };

  if (rating && reviewCount) {
    schema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: rating.toString(),
      reviewCount: reviewCount.toString()
    };
  }

  return schema;
}