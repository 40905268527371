import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sparkles } from 'lucide-react';
import AICard from './AICard';
import CategoryCard from './CategoryCard';
import { Tool } from '../types/Tool';
import { getAllTools } from '../services/toolService';
import { categories } from '../data/categories';
import SchemaMarkup from './SchemaMarkup';
import { 
  PenTool,          // Content Creation
  FileText,         // Writing & Text
  Image,            // Image Generation
  Video,            // Video Creation
  Box,              // 3D & Animation
  Briefcase,        // Business & Marketing
  Clock,            // Productivity & Workflow
  Bot,              // AI Assistance & Automation
  Code,             // Development & Engineering
  BarChart2,        // Data & Analytics
  Mic,              // Audio & Voice
  GraduationCap,    // Education & Learning
  LucideIcon        // Import the type
} from 'lucide-react';
import MetaTags from './MetaTags';

// Utility function to shuffle array
function shuffleArray<T>(array: T[]): T[] {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [featuredTools, setFeaturedTools] = useState<Tool[]>([]);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const allTools = await getAllTools();
        if (!Array.isArray(allTools)) {
          console.error('getAllTools() did not return an array');
          setFeaturedTools([]);
          return;
        }

        // Get featured tools
        const featured = allTools.filter(tool => tool.featured);
        
        // If more than 10 featured tools, randomly select exactly 10
        const displayedFeatured = featured.length > 10 
          ? shuffleArray(featured).slice(0, 10)
          : featured.slice(0, 10);

        setFeaturedTools(displayedFeatured);
      } catch (error) {
        console.error('Error fetching tools:', error);
        setFeaturedTools([]);
      }
    };

    fetchTools();
  }, []);

  // Updated categoryIcons mapping to reflect new main categories
  const categoryIcons: { [key: string]: LucideIcon } = {
    'content-creation': PenTool,
    'writing': FileText,
    'image-generation': Image,
    'video-creation': Video,
    '3d-animation': Box,
    'business': Briefcase,
    'productivity': Clock,
    'ai-assistance': Bot,
    'development': Code,
    'data-analysis': BarChart2,
    'audio': Mic,
    'education': GraduationCap
  };

  return (
    <>
      <MetaTags 
        title="AI Is A Tool - Find the Perfect AI Tool for Your Needs"
        description="Discover and compare the best AI tools for your workflow. From content creation to data analysis, find the right artificial intelligence solutions to enhance your productivity."
        content={`
          Browse AI tools by categories:
          ${categories.map(cat => cat.name).join(', ')}
          Featured tools include:
          ${featuredTools.map(tool => tool.name).join(', ')}
        `}
      />

      <SchemaMarkup 
        type="website"
        data={{
          name: "Ai Is A Tool",
          description: "Your unfair advantage isn't AI. It's how you use it",
          url: window.location.origin,
          potentialAction: {
            "@type": "SearchAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": `${window.location.origin}/search?q={search_term_string}`
            },
            "query-input": "required name=search_term_string"
          },
          mainEntity: {
            "@type": "ItemList",
            itemListElement: categories.map((category, index) => ({
              "@type": "ListItem",
              position: index + 1,
              item: {
                "@type": "Thing",
                name: category.name,
                description: category.description,
                url: `${window.location.origin}/category/${category.id}`
              }
            }))
          }
        }}
      />
      
      {/* Hero Section with Grid Background */}
      <div className="relative overflow-hidden">
        {/* Base Background */}
        <div className="absolute inset-0">
          {/* Gradient Background */}
          <div className="absolute inset-0 bg-gradient-to-br from-purple-600/30 via-blue-600/30 to-indigo-600/30" />
          
          {/* Grid Pattern */}
          <div 
            className="absolute inset-0" 
            style={{
              backgroundImage: `
                linear-gradient(to right, rgba(99, 102, 241, 0.1) 1px, transparent 1px),
                linear-gradient(to bottom, rgba(99, 102, 241, 0.1) 1px, transparent 1px)
              `,
              backgroundSize: '4rem 4rem',
              maskImage: 'linear-gradient(to bottom, white 50%, transparent 100%)'
            }}
          />
          
          {/* Subtle Glow Effects */}
          <div className="absolute top-10 left-1/4 w-96 h-96 bg-purple-500/20 rounded-full blur-3xl" />
          <div className="absolute top-20 right-1/4 w-96 h-96 bg-blue-500/20 rounded-full blur-3xl" />
        </div>

        {/* Content */}
        <div className="relative container mx-auto px-4 py-20">
          <div className="max-w-4xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 drop-shadow-lg">
              Discover the Perfect AI Tools for Your Workflow
            </h1>
            <p className="text-lg md:text-xl text-gray-200 mb-8 max-w-2xl mx-auto leading-relaxed drop-shadow">
              Explore our curated collection of AI tools to enhance your productivity. 
              From content creation to data analysis, find the right AI solution for your needs.
            </p>
          </div>
        </div>

        {/* Bottom Fade */}
        <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-gray-900 to-transparent" />
      </div>

      <main className="container mx-auto px-4 py-8">
        {/* Featured Tools - Exactly 2 rows of 5 */}
        {featuredTools.length > 0 && (
          <section className="mb-12">
            <div className="flex items-center gap-2 mb-6">
              <Sparkles className="h-5 w-5 text-yellow-400" />
              <h2 className="text-xl font-semibold text-white">Featured AI Tools</h2>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
              {/* First Row */}
              {featuredTools.slice(0, 5).map((tool) => (
                <AICard
                  key={tool.id}
                  tool={tool}
                  onClick={() => navigate(`/tool/${tool.id}`)}
                />
              ))}
              {/* Second Row */}
              {featuredTools.slice(5, 10).map((tool) => (
                <AICard
                  key={tool.id}
                  tool={tool}
                  onClick={() => navigate(`/tool/${tool.id}`)}
                />
              ))}
            </div>
          </section>
        )}

        {/* Categories */}
        <section className="mt-16">
          <h2 className="text-2xl font-semibold text-white mb-8">Browse by Category</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-6">
            {categories.map((category) => (
              <CategoryCard
                key={category.id}
                category={category}
                onClick={() => navigate(`/category/${category.id}`)}
                icon={categoryIcons[category.id]}
              />
            ))}
          </div>
        </section>
      </main>
    </>
  );
};

export default HomePage;