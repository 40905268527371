import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Bot, Home, RefreshCcw } from 'lucide-react';

export default function Custom404() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex items-center justify-center p-4">
      <div className="text-center space-y-8 max-w-2xl mx-auto">
        <div className="relative">
          <Bot className="w-24 h-24 mx-auto text-blue-500 animate-bounce" />
          <div className="absolute -top-2 -right-2">
            <div className="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-blue-400 opacity-75"></div>
            <div className="relative inline-flex rounded-full h-4 w-4 bg-blue-500"></div>
          </div>
        </div>
        
        <div>
          <h1 className="text-6xl font-bold text-white mb-4">Error 404</h1>
          <p className="text-2xl text-gray-300 mb-2">My AI algorithms cannot find this page</p>
          <p className="text-gray-400">The page you're looking for might have been moved, deleted, or never existed.</p>
        </div>

        <div className="flex justify-center gap-4">
          <button 
            onClick={() => navigate('/')}
            className="inline-flex items-center px-4 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          >
            <Home className="w-5 h-5 mr-2" />
            Go Home
          </button>
          <button 
            onClick={() => window.location.reload()}
            className="inline-flex items-center px-4 py-2 rounded-lg bg-gray-700 text-white hover:bg-gray-600 transition-colors"
          >
            <RefreshCcw className="w-5 h-5 mr-2" />
            Try Again
          </button>
        </div>

        <div className="mt-8 text-gray-500 text-sm">
          Error Code: NEURAL_NETWORK_MISALIGNMENT_404
        </div>
      </div>
    </div>
  );
}