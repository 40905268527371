// src/components/AdminBlogEdit.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  doc,
  getDoc,
  updateDoc,
  DocumentData,
  DocumentReference,
  deleteDoc,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../firebase';
import { Star } from 'lucide-react';

const AdminBlogEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: '',
    content: '',
    imageUrl: '',
    publishedAt: '',
    slug: '',
    rating: 0,
  });
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [newImage, setNewImage] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        if (!id) {
          setError('Invalid blog ID.');
          return;
        }
        const blogDoc = doc(db, 'blog_posts', id);
        const blogSnapshot = await getDoc(blogDoc);
        if (blogSnapshot.exists()) {
          const blogData = blogSnapshot.data();
          setFormData({
            title: blogData.title || '',
            content: blogData.content || '',
            imageUrl: blogData.imageUrl || '',
            publishedAt: blogData.publishedAt || new Date().toISOString(),
            slug: blogData.slug || '',
            rating: blogData.rating || 0,
          });
          setImagePreview(blogData.imageUrl || null);
        } else {
          setError('Blog post not found');
        }
      } catch (err) {
        console.error('Error loading blog post:', err);
        setError('Failed to load blog post.');
      } finally {
        setLoading(false);
      }
    };
    fetchBlogPost();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      setNewImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImageAndGetUrl = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `blog_images/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        null,
        (error) => {
          console.error('Image upload error:', error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      if (!id) {
        setError('Invalid blog ID.');
        return;
      }
      const blogDoc = doc(db, 'blog_posts', id);
      const updateData = {
        ...formData
      };

      if (newImage) {
        const uploadedImageUrl = await uploadImageAndGetUrl(newImage);
        updateData.imageUrl = uploadedImageUrl;
      }

      await updateDoc(blogDoc, updateData);
      alert('Blog post updated successfully!');
      navigate('/admin-console/blog-posts');
    } catch (err) {
      console.error('Error saving blog post:', err);
      setError('Failed to save blog post.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!id || !showDeleteConfirm) return;
    
    try {
      setLoading(true);
      const blogDoc = doc(db, 'blog_posts', id);
      await deleteDoc(blogDoc);
      alert('Blog post deleted successfully!');
      navigate('/admin-console/blog-posts');
    } catch (err) {
      console.error('Error deleting blog post:', err);
      setError('Failed to delete blog post.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-white mb-8">Edit Blog Post</h2>

        <form className="space-y-6">
          {error && (
            <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
              <p className="text-red-400">{error}</p>
            </div>
          )}

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Publish Date</label>
            <input
              type="datetime-local"
              name="publishedAt"
              value={formData.publishedAt.slice(0, 16)}
              onChange={handleInputChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Content</label>
            <textarea
              name="content"
              value={formData.content}
              onChange={handleInputChange}
              rows={15}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Featured Image</label>
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="block w-full text-sm text-gray-400
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-indigo-600 file:text-white
                hover:file:bg-indigo-700"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-medium mb-2">Rating (0-5)</label>
            <div className="flex items-center gap-4">
              <input
                type="number"
                name="rating"
                min="0"
                max="5"
                step="0.1"
                value={formData.rating}
                onChange={handleInputChange}
                className="w-full bg-gray-800 border border-gray-700 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-indigo-500"
              />
              <div className="flex items-center gap-1">
                {[...Array(5)].map((_, index) => (
                  <button
                    key={index}
                    type="button"
                    onClick={() => setFormData(prev => ({ ...prev, rating: index + 1 }))}
                    className={`p-1 ${
                      index < formData.rating 
                        ? 'text-yellow-400' 
                        : 'text-gray-500'
                    }`}
                  >
                    <Star className="h-5 w-5 fill-current" />
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="flex gap-4">
            <button
              type="button"
              onClick={handleSave}
              disabled={loading}
              className="flex-1 bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
            
            {!showDeleteConfirm ? (
              <button
                type="button"
                onClick={() => setShowDeleteConfirm(true)}
                className="px-6 py-3 bg-red-600/20 text-red-400 rounded-lg hover:bg-red-600/30"
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                onClick={handleDelete}
                className="px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Confirm Delete
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminBlogEdit;





