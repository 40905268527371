import { Link } from 'react-router-dom';
import { Tool } from '../types/Tool';
import ResponsiveImage from './ResponsiveImage';
import { getWebPUrl } from '../utils/imageUtils';

interface AICardProps {
  tool: Tool;
  onClick: () => void;
}

const AICard: React.FC<AICardProps> = ({ tool, onClick }) => {
  const subcategorySlug = tool.subcategory?.[0]?.toLowerCase().replace(/\s+/g, '-') || '';
  
  return (
    <div
      onClick={onClick}
      className="bg-gray-800 rounded-lg overflow-hidden cursor-pointer hover:bg-gray-700 transition-colors"
    >
      {tool.imageUrl && (
        <div className="aspect-video relative bg-gray-700">
          <ResponsiveImage
            src={tool.imageUrl}
            alt={tool.name}
            className="w-full h-full object-cover"
            size="_320x180"
            loading="lazy"
          />
        </div>
      )}
      <div className="p-4">
        <h3 className="font-medium text-white mb-1 line-clamp-1">{tool.name}</h3>
        <p className="text-sm text-gray-400 line-clamp-2">{tool.description}</p>
        {subcategorySlug && (
          <Link 
            to={`/category/${tool.category}/${subcategorySlug}`}
            className="text-sm text-gray-400 hover:text-white mt-2 block"
            onClick={(e) => e.stopPropagation()}
          >
            {tool.subcategory?.[0]}
          </Link>
        )}
      </div>
    </div>
  );
};

export default AICard;