import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Star, Facebook, X, ExternalLink, BookOpen } from 'lucide-react';
import { getToolById, getAllTools } from '../services/toolService';
import { Tool } from '../types/Tool';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';
import { generateInternalLinksWithData } from '../services/internalLinkingService';
import AdSlot from './AdSlot';
import FeaturedToolCard from './FeaturedToolCard';
import { getActiveAds } from '../services/adService';
import { Advertisement } from '../types/Advertisement';
import ResponsiveImage from './ResponsiveImage';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getWebPUrl } from '../utils/imageUtils';
import MetaTags from './MetaTags';

const ProductPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [tool, setTool] = useState<Tool | null>(null);
  const [featuredTools, setFeaturedTools] = useState<Tool[]>([]);
  const [processedDescription, setProcessedDescription] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [ads, setAds] = useState<Advertisement[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          setLoading(true);
          const [toolData, allTools, activeAds] = await Promise.all([
            getToolById(id), 
            getAllTools(),
            getActiveAds()
          ]);
          
          console.log('Fetched tool data:', toolData);
          console.log('Fetched ads:', activeAds);
          setAds(activeAds);
          if (toolData) {
            setTool(toolData);
            const descriptionWithLinks = await generateInternalLinksWithData(
              toolData.description,
              `/tool/${id}`
            );
            setProcessedDescription(descriptionWithLinks);

            const availableFeaturedTools = allTools.filter(t => 
              t.id !== id && 
              t.featured && 
              t.status === 'active'
            );
            
            const randomFeatured = availableFeaturedTools
              .sort(() => Math.random() - 0.5)
              .slice(0, 5);
            
            setFeaturedTools(randomFeatured);
          }
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Failed to load data');
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [id]);

  const handleShare = (platform: 'twitter' | 'facebook') => {
    if (!tool) return;
    const url = window.location.href;
    const text = `Check out ${tool.name} on Ai Is A Tool!`;
    const shareUrl =
      platform === 'twitter'
        ? `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`
        : `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(shareUrl);
  };

  if (loading) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="text-center text-gray-400">Loading...</div>
      </div>
    );
  }

  if (error || !tool) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="text-center text-red-400">{error || 'Tool not found'}</div>
        <Link to="/" className="block mt-4 text-center text-indigo-400 hover:text-indigo-300">
          Return to Home
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <MetaTags 
        title={`${tool.name} - AI Tool Review`}
        description={`${tool.description.substring(0, 155)}...`}
        content={`
          ${tool.name} - ${tool.category} AI Tool
          Rating: ${tool.rating}/5
          Pricing: ${tool.pricing}
          Key Features: ${tool.keyFeatures.join(', ')}
          ${tool.description}
        `}
      />

      <SchemaMarkup
        type="tool"
        data={{
          name: tool.name,
          description: tool.description,
          category: tool.category,
          price: tool.priceDetails,
          priceCurrency: "USD",
          rating: tool.rating,
          reviewCount: tool.reviewCount,
          image: tool.featuredImage || tool.imageUrl,
          url: tool.url,
          offers: {
            price: tool.pricing === 'free' ? '0' : tool.priceDetails?.replace(/[^0-9.]/g, ''),
            priceCurrency: "USD",
            availability: "https://schema.org/InStock"
          },
          applicationCategory: tool.category,
          operatingSystem: "Web-based",
          features: tool.keyFeatures,
          usageType: tool.pricing === 'free' ? 'Free' : 'Paid'
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
        <div className="lg:col-span-6">
          <div className="bg-gray-800 rounded-lg p-6 mb-6">
            <h1 className="text-2xl font-bold text-white mb-4">{tool.name}</h1>
            {(tool.featuredImage || tool.imageUrl) && (
              <div className="mb-8 relative">
                <div className="aspect-video">
                  <ResponsiveImage
                    src={tool.featuredImage || tool.imageUrl}
                    alt={tool.name}
                    className="w-full h-full object-cover rounded-lg"
                    size="_854x480"
                    loading="lazy"
                  />
                </div>
              </div>
            )}
            <div className="block lg:hidden mb-8">
              <div className="bg-gradient-to-br from-gray-800 to-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl p-6 shadow-lg">
                <div className="flex items-center justify-between mb-4">
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-400">Category:</span>
                      <span className="px-3 py-1 bg-gray-700/50 rounded-full text-sm text-gray-300">
                        {tool.category}
                      </span>
                    </div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-400">Rating:</span>
                      <div className="flex items-center gap-1">
                        <Star className="h-4 w-4 text-yellow-400 fill-yellow-400" />
                        <span className="text-sm text-gray-300">{tool.rating}/5</span>
                      </div>
                    </div>
                  </div>
                  <span className="px-3 py-1 bg-indigo-600/20 text-indigo-400 rounded-full text-sm">
                    {tool.pricing}
                  </span>
                </div>

                <div className="flex items-center justify-between mb-4">
                  <span className="text-sm text-gray-400">
                    {tool.pricing === 'free' ? 'Free' : `From ${tool.priceDetails || '$0'}`}
                  </span>
                </div>

                <a
                  href={tool.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center gap-2 w-full px-4 py-3 bg-indigo-600 text-white text-center rounded-xl hover:bg-indigo-700 transition-all duration-300 shadow-lg hover:shadow-indigo-600/20"
                >
                  <ExternalLink className="h-5 w-5" />
                  Visit website
                </a>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <section className="bg-gradient-to-br from-gray-800 to-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl p-6 shadow-lg">
                <h2 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
                  <div className="p-2 bg-indigo-600/20 rounded-lg">
                    <Star className="h-5 w-5 text-indigo-400" />
                  </div>
                  Use Cases
                </h2>
                <ul className="space-y-3">
                  {tool.useCases.map((useCase, index) => (
                    <li key={index} className="flex items-start gap-3 text-gray-300">
                      <div className="mt-1.5">
                        <div className="h-1.5 w-1.5 bg-indigo-400 rounded-full"></div>
                      </div>
                      <span>{useCase}</span>
                    </li>
                  ))}
                </ul>
              </section>
              <section className="bg-gradient-to-br from-gray-800 to-gray-800/50 backdrop-blur-sm border border-gray-700/50 rounded-xl p-6 shadow-lg">
                <h2 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
                  <div className="p-2 bg-indigo-600/20 rounded-lg">
                    <Star className="h-5 w-5 text-indigo-400" />
                  </div>
                  Key Features
                </h2>
                <ul className="space-y-3">
                  {tool.keyFeatures.map((feature, index) => (
                    <li key={index} className="flex items-start gap-3 text-gray-300">
                      <div className="mt-1.5">
                        <div className="h-1.5 w-1.5 bg-indigo-400 rounded-full"></div>
                      </div>
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </section>
            </div>
            <section className="mt-6">
              <h2 className="text-xl font-semibold text-white mb-4 flex items-center gap-2">
                <div className="p-2 bg-indigo-600/20 rounded-lg">
                  <BookOpen className="h-5 w-5 text-indigo-400" />
                </div>
                Overview
              </h2>
              <div className="text-gray-300 prose prose-invert max-w-none">
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {processedDescription}
                </ReactMarkdown>
              </div>
            </section>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <button
              onClick={() => handleShare('twitter')}
              className="flex items-center justify-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
            >
              <X className="h-4 w-4" />
              Share on X
            </button>
            <button
              onClick={() => handleShare('facebook')}
              className="flex items-center justify-center gap-2 px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors"
            >
              <Facebook className="h-4 w-4" />
              Share on Facebook
            </button>
          </div>
        </div>

        <div className="lg:col-span-3 hidden lg:block">
          <div className="sticky top-24 space-y-4">
            <div className="bg-gray-800 rounded-lg p-4">
              <div className="flex items-center justify-between mb-4">
                <span className="text-sm text-gray-400">
                  {tool.pricing === 'free' ? 'Free' : `From ${tool.priceDetails || '$0'}`}
                </span>
                <span className="px-2 py-1 bg-indigo-600/20 text-indigo-400 rounded-full text-sm">{tool.pricing}</span>
              </div>

              <a
                href={tool.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center gap-2 w-full px-4 py-3 bg-indigo-600 text-white text-center rounded-lg hover:bg-indigo-700 transition-colors mb-4"
              >
                <ExternalLink className="h-5 w-5" />
                Visit website
              </a>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-sm font-medium text-gray-400 mb-2">Category</h3>
                  <div className="text-white">{tool.category}</div>
                </div>
                <div>
                  <h3 className="text-sm font-medium text-gray-400 mb-2">Rating</h3>
                  <div className="flex items-center gap-1">
                    <Star className="h-5 w-5 text-yellow-400 fill-yellow-400" />
                    <span className="text-white">{tool.rating}/5</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              {[1, 2, 3].map((position) => {
                const ad = ads.find(a => a.position === position);
                return ad ? (
                  <AdSlot 
                    key={position} 
                    position={position} 
                    ad={{
                      imageUrl: ad.imageUrl,
                      linkUrl: ad.linkUrl,
                      openInNewTab: ad.openInNewTab,
                      rel: ad.rel
                    }} 
                  />
                ) : (
                  <div key={position} className="h-32 bg-gray-800 rounded-lg flex items-center justify-center">
                    <span className="text-gray-600">Ad Space {position}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="lg:col-span-3">
          <div className="sticky top-24">
            <div className="bg-gray-800 rounded-lg p-4">
              <h2 className="text-lg font-semibold text-white mb-4">Featured</h2>
              <div className="space-y-3">
                {featuredTools.map((featuredTool) => (
                  <FeaturedToolCard
                    key={featuredTool.id}
                    tool={featuredTool}
                    onClick={() => navigate(`/tool/${featuredTool.id}`)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

