import React from 'react';
import { Link } from 'react-router-dom';
import { FaBrain, FaTwitter, FaFacebook, FaPinterest, FaTiktok, FaYoutube, FaLinkedin, FaEnvelope } from 'react-icons/fa'; // Import icons from react-icons
import NewsletterForm from './NewsletterForm';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const categories = [
    { name: 'Content Creation', id: 'content-creation' },
    { name: 'Writing & Text', id: 'writing' },
    { name: 'Image Generation', id: 'image-generation' },
    { name: 'Video Creation', id: 'video-creation' },
    { name: '3D & Animation', id: '3d-animation' },
    { name: 'Business & Marketing', id: 'business' },
    { name: 'Development', id: 'development' },
    { name: 'Data Analysis', id: 'data-analysis' },
    { name: 'Audio & Voice', id: 'audio' },
    { name: 'Education', id: 'education' },
  ];

  const resources = [
    { name: 'Blog', href: '/blog' },
    { name: 'News', href: '/news' },
    { name: 'All Tools List', href: '/tools' },
    { name: 'About Us', href: '/about' },
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Service', href: '/terms' },
    { name: 'Contact', href: '/contact' },
  ];

  return (
    <footer className="bg-black border-t border-gray-800">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Brand Column */}
          <div>
            <div className="flex items-center gap-2 mb-4">
              <FaBrain className="h-6 w-6 text-indigo-400" />
              <span className="text-xl font-bold text-white">Ai Is A Tool</span>
            </div>
            <p className="text-gray-200 mb-6">
              Discover and compare the best AI tools for your specific needs. Your gateway to AI-powered solutions.
            </p>
            <div className="flex items-center gap-4">
              <a
                href="https://www.facebook.com/aiisatool/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <FaFacebook className="h-5 w-5" />
              </a>
              <a
                href="https://x.com/AiIsATool"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <FaTwitter className="h-5 w-5" /> {/* Using Twitter icon for X */}
              </a>
              <a
                href="https://www.pinterest.co.uk/aiisatool/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <FaPinterest className="h-5 w-5" />
              </a>
              <a
                href="https://www.tiktok.com/@aiisatool"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <FaTiktok className="h-5 w-5" />
              </a>
              <a
                href="https://www.youtube.com/@aiisatool"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <FaYoutube className="h-5 w-5" />
              </a>
              <a
                href="https://www.linkedin.com/company/bestaitools"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white/80 hover:text-white transition-colors"
              >
                <FaLinkedin className="h-5 w-5" />
              </a>
              <a
                href="mailto:info@aiisatool.com"
                className="text-white/80 hover:text-white transition-colors"
              >
                <FaEnvelope className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Categories Column */}
          <div>
            <h3 className="text-white font-semibold mb-4">Popular Categories</h3>
            <ul className="space-y-2">
              {categories.map((category) => (
                <li key={category.id}>
                  <Link
                    to={`/category/${category.id}`}
                    className="text-gray-200 hover:text-white transition-colors text-sm"
                  >
                    {category.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Resources Column */}
          <div>
            <h3 className="text-white font-semibold mb-4">Resources</h3>
            <ul className="space-y-2">
              {resources.map((resource) => (
                <li key={resource.name}>
                  <Link
                    to={resource.href}
                    className="text-gray-200 hover:text-white transition-colors text-sm"
                  >
                    {resource.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Newsletter Column */}
          <div>
            <h3 className="text-white font-semibold mb-4">Stay Updated</h3>
            <p className="text-gray-200 text-sm mb-4">
              Get the latest AI tools and updates delivered to your inbox.
            </p>
            <NewsletterForm />
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <div className="text-sm text-gray-200">
              © {currentYear} Ai Is A Tool. All rights reserved.
            </div>
            <div className="flex items-center gap-6">
              <Link
                to="/privacy"
                className="text-sm text-gray-200 hover:text-white transition-colors"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                className="text-sm text-gray-200 hover:text-white transition-colors"
              >
                Terms of Service
              </Link>
              <Link
                to="/contact"
                className="text-sm text-gray-200 hover:text-white transition-colors"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

