import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  generateOrganizationSchema,
  generateWebsiteSchema,
  generateArticleSchema,
  generateToolSchema
} from '../services/schemaService';

interface SchemaMarkupProps {
  type: 'website' | 'article' | 'tool';
  data?: any;
}

const SchemaMarkup: React.FC<SchemaMarkupProps> = ({ type, data }) => {
  let schema;

  switch (type) {
    case 'website':
      schema = {
        '@context': 'https://schema.org',
        ...generateWebsiteSchema(),
        organization: generateOrganizationSchema()
      };
      break;

    case 'article':
      if (!data) break;
      schema = {
        '@context': 'https://schema.org',
        ...generateArticleSchema(
          data.title,
          data.description,
          data.image,
          data.publishDate,
          data.modifyDate,
          data.authorName
        )
      };
      break;

    case 'tool':
      if (!data) break;
      schema = {
        '@context': 'https://schema.org',
        ...generateToolSchema(
          data.name,
          data.description,
          data.category,
          data.price,
          data.rating,
          data.reviewCount
        )
      };
      break;
  }

  if (!schema) return null;

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

export default SchemaMarkup;