import React, { useState } from 'react';
import { BlogPost } from '../types/BlogPost';
import { createBlogPost } from '../services/blogService';
import { generateBlogPost } from '../services/blogGenerator';

const AdminBlogPage: React.FC = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [customImage, setCustomImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }

      setCustomImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGenerate = async () => {
    if (!title) {
      setError('Please enter a title');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const post = await generateBlogPost(title);
      setContent(post.content);
    } catch (err) {
      setError('Failed to generate blog post');
      console.error('Error generating post:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (!title || !content) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      await createBlogPost(title, content, category, customImage || undefined);
      
      // Reset form
      setTitle('');
      setContent('');
      setCategory('');
      setCustomImage(null);
      setImagePreview(null);

      alert('Blog post submitted successfully!');
    } catch (err) {
      setError('Failed to submit blog post');
      console.error('Error submitting post:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold text-white mb-8">Create Blog Post</h1>

      <div className="bg-gray-800 rounded-lg p-6">
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Title
          </label>
          <div className="flex gap-4">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="flex-1 bg-gray-700 rounded-lg px-4 py-2 text-white"
              placeholder="Enter blog post title"
            />
            <button
              onClick={handleGenerate}
              disabled={loading || !title}
              className="bg-indigo-600 text-white px-6 py-2 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Generating...' : 'Generate'}
            </button>
          </div>
        </div>

        {error && (
          <div className="mb-6 text-red-400 bg-red-900/20 rounded-lg p-4">
            {error}
          </div>
        )}

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Category (Optional)
          </label>
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full bg-gray-700 rounded-lg px-4 py-2 text-white"
            placeholder="Enter category"
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Content
          </label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={20}
            className="w-full bg-gray-700 rounded-lg px-4 py-2 text-white"
            placeholder="Blog post content will appear here after generation"
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Featured Image
          </label>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              className="w-full h-48 object-cover rounded-lg mb-4"
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="block w-full text-sm text-gray-400
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-indigo-600 file:text-white
              hover:file:bg-indigo-700"
          />
        </div>

        <button
          onClick={handleSubmit}
          disabled={loading || !title || !content}
          className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {loading ? 'Submitting...' : 'Submit Blog Post'}
        </button>
      </div>
    </div>
  );
};

export default AdminBlogPage;