import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { X } from 'lucide-react';

const NewsletterPopup: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [hasSeenPopup, setHasSeenPopup] = useState(false);

  useEffect(() => {
    const lastPopup = localStorage.getItem('newsletterPopupShown');
    if (lastPopup) {
      const lastShownDate = new Date(lastPopup);
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      
      if (lastShownDate > sevenDaysAgo) {
        setHasSeenPopup(true);
      }
    }

    const handleMouseLeave = (e: MouseEvent) => {
      if (!hasSeenPopup && e.clientY <= 0) {
        setIsVisible(true);
        localStorage.setItem('newsletterPopupShown', new Date().toISOString());
        setHasSeenPopup(true);
      }
    };

    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [hasSeenPopup]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!email) {
      setError('Please enter your email address');
      setLoading(false);
      return;
    }

    try {
      await addDoc(collection(db, 'newsletter_subscribers'), {
        email,
        subscribedAt: new Date().toISOString(),
        source: 'exit_popup'
      });
      
      setSuccess(true);
      setEmail('');
      
      setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    } catch (err) {
      console.error('Error subscribing to newsletter:', err);
      setError('Failed to subscribe. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-900 border border-gray-800 rounded-lg max-w-md w-full p-6 relative animate-fade-in">
        <button
          onClick={() => setIsVisible(false)}
          className="absolute top-4 right-4 text-gray-400 hover:text-white"
        >
          <X className="h-5 w-5" />
        </button>

        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-white mb-2">
            Stay Updated with AI Innovation
          </h2>
          <p className="text-gray-300">
            Join our newsletter for exclusive updates on the latest AI tools and news. 
            No spam, just valuable insights delivered to your inbox.
          </p>
        </div>

        {success ? (
          <div className="text-center text-green-400 py-4">
            Thanks for subscribing! 🎉
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              {error && (
                <p className="mt-2 text-sm text-red-400">{error}</p>
              )}
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 disabled:opacity-50 transition-colors"
            >
              {loading ? 'Subscribing...' : 'Subscribe Now'}
            </button>

            <p className="text-xs text-gray-400 text-center">
              By subscribing, you agree to receive newsletter emails. You can unsubscribe at any time.
            </p>
          </form>
        )}
      </div>
    </div>
  );
};

export default NewsletterPopup; 