import React, { useState } from 'react';
import { getWebPUrl } from '../utils/imageUtils';

interface ResponsiveImageProps {
  src: string;
  alt: string;
  className?: string;
  size?: '_854x480' | '_320x180';
  loading?: 'lazy' | 'eager';
}

const ResponsiveImage: React.FC<ResponsiveImageProps> = ({ 
  src, 
  alt, 
  className = '',
  size = '_854x480',
  loading = 'lazy'
}) => {
  const [imgSrc, setImgSrc] = useState<string>(getWebPUrl(src, size));
  const [fallbackTriggered, setFallbackTriggered] = useState(false);

  const handleError = () => {
    if (!fallbackTriggered) {
      // If WebP fails, try original image
      setImgSrc(src);
      setFallbackTriggered(true);
    }
  };

  return (
    <picture>
      <source srcSet={imgSrc} type="image/webp" />
      <img
        src={src} // Original format as fallback
        alt={alt}
        onError={handleError}
        className={className}
        loading={loading}
      />
    </picture>
  );
};

export default ResponsiveImage; 