import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getNewsById, updateNews, deleteNews } from '../services/newsService';
import { NewsPost } from '../types/NewsPost';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from './ui/alert';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../lib/firebase';  // Adjust path as needed

const AdminNewsEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    excerpt: '',
    content: '',
    imageUrl: '',
    publishedAt: new Date().toISOString().slice(0, 16),
    author: '',
    source: '',
    urgency: 'low' as 'low' | 'medium' | 'high',
    tags: '',
    isPublished: false,
    contentPart1: '',
    contentPart2: '',
    contentPart3: '',
    contentImage1: null as File | null,
    contentImage2: null as File | null,
    contentImage3: null as File | null,
  });

  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [contentImagePreviews, setContentImagePreviews] = useState({
    image1: null as string | null,
    image2: null as string | null,
    image3: null as string | null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    const fetchNews = async () => {
      if (!id) return;

      try {
        const newsData = await getNewsById(id);
        if (newsData) {
          const contentParts = newsData.content.split(/!\[.*?\]\(.*?\)/);
          const imageUrls = newsData.content.match(/!\[.*?\]\((.*?)\)/g)?.map(img => {
            const match = img.match(/\((.*?)\)/);
            return match ? match[1] : null;
          }) || [];

          setFormData({
            title: newsData.title,
            slug: newsData.slug,
            excerpt: newsData.excerpt,
            content: newsData.content,
            imageUrl: newsData.featuredImage || '',
            publishedAt: newsData.publishedAt.slice(0, 16),
            author: newsData.author,
            source: newsData.source || '',
            urgency: newsData.urgency || 'low',
            tags: newsData.tags?.join(', ') || '',
            isPublished: newsData.isPublished,
            contentPart1: contentParts[0]?.trim() || '',
            contentPart2: contentParts[1]?.trim() || '',
            contentPart3: contentParts[2]?.trim() || '',
            contentImage1: null,
            contentImage2: null,
            contentImage3: null
          });

          setContentImagePreviews({
            image1: imageUrls[0] || null,
            image2: imageUrls[1] || null,
            image3: imageUrls[2] || null
          });

          setImagePreview(newsData.featuredImage || null);
        }
      } catch (err) {
        console.error('Error loading news article:', err);
        setError('Failed to load news article');
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, [id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    }));
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }

      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleContentImageUpload = (e: React.ChangeEvent<HTMLInputElement>, imageNumber: number) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }

      setFormData(prev => ({
        ...prev,
        [`contentImage${imageNumber}`]: file
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        setContentImagePreviews(prev => ({
          ...prev,
          [`image${imageNumber}`]: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveContentImage = (imageNumber: number) => {
    setFormData(prev => ({
      ...prev,
      [`contentImage${imageNumber}`]: null
    }));
    setContentImagePreviews(prev => ({
      ...prev,
      [`image${imageNumber}`]: null
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!id) return;

    setError(null);
    
    try {
      setLoading(true);

      const contentImage1Url = formData.contentImage1 
        ? await uploadImage(formData.contentImage1) 
        : contentImagePreviews.image1;
      const contentImage2Url = formData.contentImage2 
        ? await uploadImage(formData.contentImage2) 
        : contentImagePreviews.image2;
      const contentImage3Url = formData.contentImage3 
        ? await uploadImage(formData.contentImage3) 
        : contentImagePreviews.image3;

      const fullContent = `
${formData.contentPart1}

${contentImage1Url ? `![Content Image 1](${contentImage1Url})` : ''}

${formData.contentPart2}

${contentImage2Url ? `![Content Image 2](${contentImage2Url})` : ''}

${formData.contentPart3}

${contentImage3Url ? `![Content Image 3](${contentImage3Url})` : ''}
`.trim();

      const newsData = {
        title: formData.title,
        slug: formData.slug,
        excerpt: formData.excerpt,
        content: fullContent,
        publishedAt: formData.publishedAt,
        author: formData.author,
        source: formData.source,
        urgency: formData.urgency,
        tags: formData.tags.split(',').map(tag => tag.trim()).filter(Boolean),
        isPublished: formData.isPublished,
        featuredImage: formData.imageUrl
      };

      await updateNews(id, newsData, image || undefined);
      navigate('/admin-console/news');
    } catch (err) {
      console.error('Error updating news article:', err);
      setError('Failed to update news article');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!id || !showDeleteConfirm) return;
    
    try {
      setLoading(true);
      await deleteNews(id);
      navigate('/admin-console/news');
    } catch (err) {
      console.error('Error deleting news article:', err);
      setError('Failed to delete news article');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 p-8">
        <div className="max-w-4xl mx-auto text-center text-gray-400">
          Loading...
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold text-white mb-8">Edit News Article</h1>

        <form onSubmit={handleSubmit} className="space-y-6">
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Slug</label>
            <input
              type="text"
              name="slug"
              value={formData.slug}
              onChange={handleInputChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Excerpt</label>
            <textarea
              name="excerpt"
              value={formData.excerpt}
              onChange={handleInputChange}
              rows={3}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="space-y-6">
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Content Part 1 (Markdown)</label>
              <textarea
                name="contentPart1"
                value={formData.contentPart1}
                onChange={handleInputChange}
                rows={8}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter first part of content in Markdown format"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Content Image 1</label>
              {contentImagePreviews.image1 && (
                <div className="relative">
                  <img
                    src={contentImagePreviews.image1}
                    alt="Content Preview 1"
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveContentImage(1)}
                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600"
                  >
                    ×
                  </button>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleContentImageUpload(e, 1)}
                className="block w-full text-sm text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-indigo-600 file:text-white
                  hover:file:bg-indigo-700"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Content Part 2 (Markdown)</label>
              <textarea
                name="contentPart2"
                value={formData.contentPart2}
                onChange={handleInputChange}
                rows={8}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter second part of content in Markdown format"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Content Image 2</label>
              {contentImagePreviews.image2 && (
                <div className="relative">
                  <img
                    src={contentImagePreviews.image2}
                    alt="Content Preview 2"
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveContentImage(2)}
                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600"
                  >
                    ×
                  </button>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleContentImageUpload(e, 2)}
                className="block w-full text-sm text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-indigo-600 file:text-white
                  hover:file:bg-indigo-700"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Content Part 3 (Markdown)</label>
              <textarea
                name="contentPart3"
                value={formData.contentPart3}
                onChange={handleInputChange}
                rows={8}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
                placeholder="Enter third part of content in Markdown format"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Content Image 3</label>
              {contentImagePreviews.image3 && (
                <div className="relative">
                  <img
                    src={contentImagePreviews.image3}
                    alt="Content Preview 3"
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveContentImage(3)}
                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600"
                  >
                    ×
                  </button>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleContentImageUpload(e, 3)}
                className="block w-full text-sm text-gray-400
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-indigo-600 file:text-white
                  hover:file:bg-indigo-700"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Author</label>
              <input
                type="text"
                name="author"
                value={formData.author}
                onChange={handleInputChange}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Source</label>
              <input
                type="text"
                name="source"
                value={formData.source}
                onChange={handleInputChange}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Publish Date</label>
              <input
                type="datetime-local"
                name="publishedAt"
                value={formData.publishedAt}
                onChange={handleInputChange}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
              />
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-200">Urgency</label>
              <select
                name="urgency"
                value={formData.urgency}
                onChange={handleInputChange}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Tags (comma-separated)</label>
            <input
              type="text"
              name="tags"
              value={formData.tags}
              onChange={handleInputChange}
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:ring-2 focus:ring-indigo-500"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-200">Featured Image</label>
            {imagePreview && (
              <div className="relative">
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
                <button
                  type="button"
                  onClick={() => {
                    setImage(null);
                    setImagePreview(null);
                    setFormData(prev => ({ ...prev, imageUrl: '' }));
                  }}
                  className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600"
                >
                  ×
                </button>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="block w-full text-sm text-gray-400
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-indigo-600 file:text-white
                hover:file:bg-indigo-700"
            />
          </div>

          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="isPublished"
              checked={formData.isPublished}
              onChange={handleInputChange}
              className="w-4 h-4 bg-gray-800 border-gray-700 rounded text-indigo-600 focus:ring-indigo-500"
            />
            <label className="text-sm font-medium text-gray-200">
              Published
            </label>
          </div>

          <div className="flex gap-4">
            <button
              type="submit"
              disabled={loading}
              className="flex-1 bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
            
            {!showDeleteConfirm ? (
              <button
                type="button"
                onClick={() => setShowDeleteConfirm(true)}
                className="px-6 py-3 bg-red-600/20 text-red-400 rounded-lg hover:bg-red-600/30"
              >
                Delete
              </button>
            ) : (
              <button
                type="button"
                onClick={handleDelete}
                className="px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Confirm Delete
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminNewsEdit; 