import { Tool } from '../types/Tool';
import { BlogPost } from '../types/BlogPost';
import { getAllTools } from '../services/toolService';
import { getPublishedPosts } from '../services/blogService';
import { categories } from '../data/categories';

interface LinkTarget {
  url: string;
  title: string;
  keywords: string[];
  type: 'tool' | 'category' | 'blog';
  importance: number; // 1-10, higher means more important
}

interface InternalLink {
  keyword: string;
  url: string;
  title: string;
  type: 'tool' | 'category' | 'blog';
}

// Cache for storing processed content
const processedCache = new Map<string, string>();

export async function generateInternalLinksWithData(
  content: string,
  currentUrl: string
): Promise<string> {
  try {
    // Check cache first
    const cacheKey = `${content}-${currentUrl}`;
    if (processedCache.has(cacheKey)) {
      return processedCache.get(cacheKey)!;
    }

    // Fetch data from Firebase
    const [tools, posts] = await Promise.all([
      getAllTools(),
      getPublishedPosts()
    ]);

    // Validate tools - ensure active status and required fields
    const validTools = tools.filter(tool => 
      tool.status === 'active' &&
      tool.name &&
      tool.description &&
      Array.isArray(tool.tags) &&
      Array.isArray(tool.keyFeatures) &&
      Array.isArray(tool.useCases)
    );

    // Validate posts - ensure published status and required fields
    const validPosts = posts.filter(post => 
      post.status === 'published' &&
      post.title &&
      post.slug &&
      post.excerpt &&
      Array.isArray(post.tags)
    );

    const processedContent = generateInternalLinks(
      content,
      currentUrl,
      validTools,
      validPosts
    );

    // Cache the result
    processedCache.set(cacheKey, processedContent);
    return processedContent;

  } catch (error) {
    console.error('Error generating internal links:', error);
    return content;
  }
}

function generateInternalLinks(
  content: string,
  currentUrl: string,
  tools: Tool[],
  posts: BlogPost[]
): string {
  try {
    // Build link targets database
    const linkTargets: LinkTarget[] = [
      // Add tool targets with type checking
      ...tools.map(tool => ({
        url: `/tool/${tool.id}`,
        title: tool.name,
        keywords: [
          tool.name,
          ...(Array.isArray(tool.tags) ? tool.tags : []),
          ...(Array.isArray(tool.keyFeatures) ? tool.keyFeatures : []),
          ...(Array.isArray(tool.useCases) ? tool.useCases : []),
          tool.category,
          tool.subcategory
        ]
        .filter((keyword): keyword is string => 
          typeof keyword === 'string' && 
          keyword.length > 3
        )
        .map(keyword => keyword.toLowerCase()),
        type: 'tool' as const,
        importance: tool.featured ? 9 : (tool.verified ? 8 : 6)
      })),

      // Add category targets with type checking
      ...categories.map(category => ({
        url: `/category/${category.id}`,
        title: category.name,
        keywords: [
          category.name,
          category.description,
          ...(category.id.split('-'))
        ]
        .filter((keyword): keyword is string => 
          typeof keyword === 'string' && 
          keyword.length > 3
        )
        .map(keyword => keyword.toLowerCase()),
        type: 'category' as const,
        importance: 7
      })),

      // Add blog post targets with type checking
      ...posts.map(post => ({
        url: `/blog/${post.slug}`,
        title: post.title,
        keywords: [
          post.title,
          ...(Array.isArray(post.tags) ? post.tags : []),
          post.category,
          post.excerpt
        ]
        .filter((keyword): keyword is string => 
          typeof keyword === 'string' && 
          keyword.length > 3
        )
        .map(keyword => keyword.toLowerCase()),
        type: 'blog' as const,
        importance: 5
      }))
    ];

    // Process content with validated keywords
    const sections = content.split(/(\n#{1,6}\s|(?=\n[*-]\s)|(?=\n\d+\.\s))/);
    let linkCount = 0;
    const maxLinks = Math.max(1, Math.ceil(content.split(' ').length / 500) * 3);

    const processedSections = sections.map((section, index) => {
      if (!section || linkCount >= maxLinks) return section || '';

      // Skip processing if section is a markdown header or list marker
      if (/^(\n#{1,6}\s|\n[*-]\s|\n\d+\.\s)/.test(section)) {
        return section;
      }

      // Find potential links for this section
      const potentialLinks = findRelevantLinks(section, linkTargets, currentUrl);
      
      // Apply linking rules
      return addLinksToContent(
        section,
        potentialLinks,
        index === 0,
        () => linkCount++
      );
    });

    return processedSections.join('');

  } catch (error) {
    console.error('Error in generateInternalLinks:', error);
    return content;
  }
}

function findRelevantLinks(
  content: string,
  targets: LinkTarget[],
  currentUrl: string
): InternalLink[] {
  try {
    // Validate content
    if (!content || typeof content !== 'string') {
      console.warn('Invalid content passed to findRelevantLinks:', content);
      return [];
    }

    const contentLower = content.toLowerCase();
    const links: InternalLink[] = [];
    const usedKeywords = new Set<string>();

    // Sort targets by importance
    const sortedTargets = [...targets].sort((a, b) => b.importance - a.importance);

    for (const target of sortedTargets) {
      // Skip invalid targets
      if (!target || target.url === currentUrl) continue;

      // Ensure keywords is an array and filter out invalid entries
      const validKeywords = (target.keywords || []).filter(
        (keyword): keyword is string => 
          typeof keyword === 'string' && 
          keyword.length > 3
      );

      // Find matching keywords
      for (const keyword of validKeywords) {
        try {
          if (contentLower.includes(keyword.toLowerCase()) && !usedKeywords.has(keyword)) {
            links.push({
              keyword,
              url: target.url,
              title: target.title,
              type: target.type
            });
            usedKeywords.add(keyword);
            break;
          }
        } catch (keywordError) {
          console.warn('Error processing keyword:', keyword, keywordError);
          continue;
        }
      }
    }

    return links;
  } catch (error) {
    console.error('Error in findRelevantLinks:', error);
    return [];
  }
}

function addLinksToContent(
  content: string,
  links: InternalLink[],
  isFirstSection: boolean,
  incrementLinkCount: () => void
): string {
  try {
    let processedContent = content;
    const maxLinksPerSection = isFirstSection ? 2 : 1;
    let sectionLinkCount = 0;

    for (const link of links) {
      if (sectionLinkCount >= maxLinksPerSection) break;

      // Skip if we're inside a markdown link already
      if (processedContent.includes(`<a href="${link.url}"`)) continue;

      const regex = new RegExp(`\\b${escapeRegExp(link.keyword)}\\b(?![^<]*>)`, 'i');
      if (regex.test(processedContent)) {
        processedContent = processedContent.replace(
          regex,
          `<a href="${link.url}" class="text-indigo-400 hover:text-indigo-300" title="${escapeHtml(link.title)}">${link.keyword}</a>`
        );
        sectionLinkCount++;
        incrementLinkCount();
      }
    }

    return processedContent;
  } catch (error) {
    console.error('Error in addLinksToContent:', error);
    return content;
  }
}

function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function escapeHtml(unsafe: string): string {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export function clearLinkCache(): void {
  processedCache.clear();
}