import { db, storage } from '../lib/firebase';
import { 
  collection, 
  addDoc, 
  getDocs, 
  query, 
  where, 
  orderBy, 
  doc, 
  updateDoc 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { BlogPost } from '../types/BlogPost';

export const getPublishedPosts = async (): Promise<BlogPost[]> => {
  try {
    // Check if db is properly initialized
    if (!db) {
      throw new Error('Firebase DB is not initialized');
    }

    console.log('BlogService: Starting to fetch published posts...');
    
    // Get reference to collection
    const postsRef = collection(db, 'blog_posts');
    console.log('BlogService: Collection reference created');

    // Create query
    const q = query(
      postsRef,
      where('status', '==', 'published'),
      orderBy('publishedAt', 'desc')
    );
    console.log('BlogService: Query created');

    // Execute query with error handling
    let snapshot;
    try {
      snapshot = await getDocs(q);
    } catch (queryError) {
      console.error('BlogService: Query execution failed:', queryError);
      throw new Error(`Failed to execute query: ${queryError instanceof Error ? queryError.message : 'Unknown query error'}`);
    }
    
    console.log('BlogService: Query executed, documents found:', snapshot.size);

    // Process the documents
    const posts = snapshot.docs.map(doc => {
      const data = doc.data();
      try {
        return {
          id: doc.id,
          title: data.title || '',
          slug: data.slug || '',
          excerpt: data.excerpt || '',
          content: data.content || '',
          category: data.category || 'Uncategorized',
          tags: Array.isArray(data.tags) ? data.tags : [],
          author: data.author || 'Ai Is A Tool Team',
          publishedAt: data.publishedAt || '',
          status: data.status as 'draft' | 'published',
          createdAt: data.createdAt || '',
          featuredImage: data.featuredImage || undefined,
          coverImage: data.coverImage || undefined
        } as BlogPost;
      } catch (parseError) {
        console.error('BlogService: Error parsing document:', doc.id, parseError);
        return null;
      }
    })
    .filter((post): post is BlogPost => post !== null);

    console.log('BlogService: Successfully processed posts:', posts.length);
    return posts;
    
  } catch (error) {
    // Detailed error logging
    console.error('BlogService: Detailed error information:', {
      errorType: error?.constructor?.name,
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : 'No stack trace',
      raw: error
    });
    
    // Re-throw the error to be handled by the component
    throw new Error(`Failed to fetch blog posts: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};

export const getPostBySlug = async (slug: string): Promise<BlogPost | null> => {
  try {
    if (!db) {
      throw new Error('Firebase DB is not initialized');
    }

    console.log('BlogService: Fetching post by slug:', slug);
    
    const postsRef = collection(db, 'blog_posts');
    const q = query(postsRef, where('slug', '==', slug));
    
    let snapshot;
    try {
      snapshot = await getDocs(q);
    } catch (queryError) {
      console.error('BlogService: Query execution failed:', queryError);
      throw new Error(`Failed to fetch post: ${queryError instanceof Error ? queryError.message : 'Unknown query error'}`);
    }

    if (snapshot.empty) {
      console.log('BlogService: No post found for slug:', slug);
      return null;
    }

    const doc = snapshot.docs[0];
    const data = doc.data();

    try {
      const post = {
        id: doc.id,
        title: data.title || '',
        slug: data.slug || '',
        excerpt: data.excerpt || '',
        content: data.content || '',
        category: data.category || 'Uncategorized',
        tags: Array.isArray(data.tags) ? data.tags : [],
        author: data.author || 'Ai Is A Tool Team',
        publishedAt: data.publishedAt || '',
        status: data.status as 'draft' | 'published',
        createdAt: data.createdAt || '',
        featuredImage: data.featuredImage || undefined,
        coverImage: data.coverImage || undefined
      } as BlogPost;

      console.log('BlogService: Successfully fetched post:', post.id);
      return post;
    } catch (parseError) {
      console.error('BlogService: Error parsing document:', doc.id, parseError);
      throw new Error(`Failed to parse post data: ${parseError instanceof Error ? parseError.message : 'Unknown parse error'}`);
    }
  } catch (error) {
    console.error('BlogService: Error getting post by slug:', {
      errorType: error?.constructor?.name,
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : 'No stack trace',
      slug,
      raw: error
    });
    throw error;
  }
};

export const createBlogPost = async (
  title: string,
  content: string,
  category: string = 'Uncategorized',
  coverImage?: File,
  initialStatus: 'draft' | 'published' = 'draft'
): Promise<string> => {
  try {
    if (!db) {
      throw new Error('Firebase DB is not initialized');
    }

    console.log('BlogService: Creating new blog post:', { title, category, initialStatus });

    // Format content to preserve Markdown line breaks
    const formattedContent = content
      .replace(/\n# /g, '\n\n# ')
      .replace(/\n## /g, '\n\n## ')
      .replace(/\n\n\n/g, '\n\n')
      .trim();

    // Generate slug
    const slug = title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');

    // Upload image if provided
    let featuredImage = '';
    if (coverImage) {
      try {
        console.log('BlogService: Uploading cover image...');
        const imageRef = ref(storage, `blog-images/${Date.now()}-${coverImage.name}`);
        const uploadResult = await uploadBytes(imageRef, coverImage);
        featuredImage = await getDownloadURL(uploadResult.ref);
        console.log('BlogService: Cover image uploaded successfully');
      } catch (uploadError) {
        console.error('BlogService: Error uploading cover image:', uploadError);
        throw new Error(`Failed to upload cover image: ${uploadError instanceof Error ? uploadError.message : 'Unknown upload error'}`);
      }
    }

    // Generate excerpt
    const excerpt = content
      .split('\n')[0]
      .replace(/^#+ /, '')
      .slice(0, 150) + 
      (content.split('\n')[0].length > 150 ? '...' : '');

    const now = new Date().toISOString();

    const postData = {
      title,
      slug,
      content: formattedContent,
      excerpt,
      category,
      tags: category ? [category.toLowerCase()] : ['uncategorized'],
      featuredImage,
      author: 'Ai Is A Tool Team',
      status: initialStatus,
      createdAt: now,
      publishedAt: initialStatus === 'published' ? now : null,
    };

    try {
      console.log('BlogService: Saving post to Firestore:', postData);
      const postsRef = collection(db, 'blog_posts');
      const docRef = await addDoc(postsRef, postData);
      console.log('BlogService: Post created successfully with ID:', docRef.id);
      return docRef.id;
    } catch (saveError) {
      console.error('BlogService: Error saving post to Firestore:', saveError);
      throw new Error(`Failed to save blog post: ${saveError instanceof Error ? saveError.message : 'Unknown save error'}`);
    }
  } catch (error) {
    console.error('BlogService: Error creating blog post:', {
      errorType: error?.constructor?.name,
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : 'No stack trace',
      raw: error
    });
    throw error;
  }
};

export const publishPost = async (postId: string): Promise<void> => {
  try {
    if (!db) {
      throw new Error('Firebase DB is not initialized');
    }

    console.log('BlogService: Publishing post:', postId);
    
    const postRef = doc(db, 'blog_posts', postId);
    await updateDoc(postRef, {
      status: 'published',
      publishedAt: new Date().toISOString()
    });

    console.log('BlogService: Post published successfully');
  } catch (error) {
    console.error('BlogService: Error publishing post:', {
      errorType: error?.constructor?.name,
      message: error instanceof Error ? error.message : 'Unknown error',
      stack: error instanceof Error ? error.stack : 'No stack trace',
      postId,
      raw: error
    });
    throw new Error(`Failed to publish post: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
};