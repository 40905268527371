import React from 'react';
import { Tool } from '../types/Tool';
import { getAllTools } from '../services/toolService';
import AICard from './AICard';
import { useNavigate } from 'react-router-dom';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';

const ToolsPage: React.FC = () => {
  const navigate = useNavigate();
  const [tools, setTools] = React.useState<Tool[]>([]);

  React.useEffect(() => {
    const fetchTools = async () => {
      const allTools = await getAllTools();
      setTools(allTools);
    };
    fetchTools();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <SchemaMarkup 
        type="website"
        data={{
          name: "AI Tools Directory",
          description: "Browse our comprehensive directory of AI tools and solutions"
        }}
      />

      <div className="mb-6">
        <Breadcrumbs />
      </div>

      <h1 className="text-3xl font-bold text-white mb-8">AI Tools Directory</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {tools.map((tool) => (
          <AICard
            key={tool.id}
            tool={tool}
            onClick={() => navigate(`/tool/${tool.id}`)}
          />
        ))}
      </div>
    </div>
  );
};

export default ToolsPage;