// src/components/AdminBlogList.tsx
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Search } from 'lucide-react';

interface BlogPost {
  id: string;
  title: string;
  content: string;
}

const AdminBlogList: React.FC = () => {
  const [blogs, setBlogs] = useState<BlogPost[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogCollection = collection(db, "blog_posts");
        const blogSnapshot = await getDocs(blogCollection);
        const blogList = blogSnapshot.docs.map(doc => ({
          id: doc.id,
          title: doc.data().title || "Untitled",
          content: doc.data().content || ""
        }));
        setBlogs(blogList);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const filteredBlogs = blogs.filter(blog => 
    blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    blog.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold">Current Blog Posts</h2>
          <Link 
            to="/admin-console/blog" 
            className="bg-indigo-600 hover:bg-indigo-700 px-4 py-2 rounded-lg text-sm"
          >
            New Post
          </Link>
        </div>

        {/* Search Bar */}
        <div className="relative mb-6">
          <input
            type="text"
            placeholder="Search blog posts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full bg-gray-800 text-white px-4 py-2 pl-10 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>

        {loading ? (
          <div className="text-center py-12">Loading...</div>
        ) : filteredBlogs.length > 0 ? (
          <ul className="space-y-6">
            {filteredBlogs.map((blog) => (
              <li 
                key={blog.id} 
                className="bg-gray-800 p-6 rounded-lg shadow-lg flex flex-col md:flex-row gap-4 items-center"
              >
                <div className="flex-1">
                  <h3 className="text-2xl font-semibold text-indigo-400">{blog.title}</h3>
                  <p className="text-sm text-gray-300 mt-2">
                    {blog.content.substring(0, 150)}... {/* Show snippet */}
                  </p>
                  <div className="mt-4">
                    <Link
                      to={`/admin-console/blog-posts/edit/${blog.id}`}
                      className="text-indigo-500 hover:text-indigo-400 text-sm font-medium"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400 text-center mt-8">
            {searchTerm ? 'No blog posts found matching your search.' : 'No blog posts available.'}
          </p>
        )}
      </div>
    </div>
  );
};

export default AdminBlogList;



