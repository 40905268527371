import React, { useState, useEffect } from 'react';
import { db } from '../lib/firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { Tool } from '../types/Tool';
import { BlogPost } from '../types/BlogPost';
import { Pencil, Check, X } from 'lucide-react';
import EditToolModal from './EditToolModal';

const DashboardHome: React.FC = () => {
  const [pendingTools, setPendingTools] = useState<Tool[]>([]);
  const [pendingPosts, setPendingPosts] = useState<BlogPost[]>([]);
  const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    loadPendingItems();
  }, []);

  const loadPendingItems = async () => {
    // Load pending tools
    const toolsQuery = query(collection(db, 'tools'), where('status', '==', 'pending'));
    const toolsSnapshot = await getDocs(toolsQuery);
    const tools = toolsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Tool));
    setPendingTools(tools);

    // Load pending blog posts
    const postsQuery = query(collection(db, 'blog_posts'), where('status', '==', 'draft'));
    const postsSnapshot = await getDocs(postsQuery);
    const posts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as BlogPost));
    setPendingPosts(posts);
  };

  const handleToolStatusUpdate = async (toolId: string, status: 'approved' | 'rejected') => {
    const toolRef = doc(db, 'tools', toolId);
    await updateDoc(toolRef, { status });
    await loadPendingItems();
  };

  const handlePostStatusUpdate = async (postId: string, status: 'published' | 'rejected') => {
    const postRef = doc(db, 'blog_posts', postId);
    await updateDoc(postRef, { 
      status: status === 'published' ? 'published' : 'draft',
      publishedAt: status === 'published' ? new Date().toISOString() : null
    });
    await loadPendingItems();
  };

  const handleEdit = (tool: Tool) => {
    setSelectedTool(tool);
    setIsEditModalOpen(true);
  };

  const handleEditComplete = async (updatedTool: Tool) => {
    const toolRef = doc(db, 'tools', updatedTool.id);
    await updateDoc(toolRef, updatedTool);
    setIsEditModalOpen(false);
    setSelectedTool(null);
    await loadPendingItems();
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold text-white mb-8">Dashboard Overview</h1>

      {/* Pending Tools Section */}
      <div className="bg-gray-800 rounded-lg p-6 mb-8">
        <h2 className="text-xl font-semibold text-white mb-6">Pending Tool Submissions</h2>
        
        {pendingTools.length === 0 ? (
          <p className="text-gray-400">No pending tool submissions to review.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left text-gray-400 border-b border-gray-700">
                  <th className="pb-4">Tool Name</th>
                  <th className="pb-4">Description</th>
                  <th className="pb-4">Submitted</th>
                  <th className="pb-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {pendingTools.map((tool) => (
                  <tr key={tool.id} className="border-b border-gray-700/50">
                    <td className="py-4 text-white">{tool.name}</td>
                    <td className="py-4 text-gray-300 max-w-md truncate">
                      {tool.description}
                    </td>
                    <td className="py-4 text-gray-400">
                      {new Date(tool.createdAt).toLocaleDateString()}
                    </td>
                    <td className="py-4">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleEdit(tool)}
                          className="p-2 text-gray-400 hover:text-white"
                          title="Edit"
                        >
                          <Pencil className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleToolStatusUpdate(tool.id, 'approved')}
                          className="p-2 text-green-400 hover:text-green-300"
                          title="Approve"
                        >
                          <Check className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handleToolStatusUpdate(tool.id, 'rejected')}
                          className="p-2 text-red-400 hover:text-red-300"
                          title="Reject"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Pending Blog Posts Section */}
      <div className="bg-gray-800 rounded-lg p-6">
        <h2 className="text-xl font-semibold text-white mb-6">Pending Blog Posts</h2>
        
        {pendingPosts.length === 0 ? (
          <p className="text-gray-400">No pending blog posts to review.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left text-gray-400 border-b border-gray-700">
                  <th className="pb-4">Title</th>
                  <th className="pb-4">Excerpt</th>
                  <th className="pb-4">Created</th>
                  <th className="pb-4">Actions</th>
                </tr>
              </thead>
              <tbody>
                {pendingPosts.map((post) => (
                  <tr key={post.id} className="border-b border-gray-700/50">
                    <td className="py-4 text-white">{post.title}</td>
                    <td className="py-4 text-gray-300 max-w-md truncate">
                      {post.excerpt}
                    </td>
                    <td className="py-4 text-gray-400">
                      {new Date(post.publishedAt).toLocaleDateString()}
                    </td>
                    <td className="py-4">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handlePostStatusUpdate(post.id, 'published')}
                          className="p-2 text-green-400 hover:text-green-300"
                          title="Publish"
                        >
                          <Check className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => handlePostStatusUpdate(post.id, 'rejected')}
                          className="p-2 text-red-400 hover:text-red-300"
                          title="Reject"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {isEditModalOpen && selectedTool && (
        <EditToolModal
          tool={selectedTool}
          onSave={handleEditComplete}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedTool(null);
          }}
        />
      )}
    </div>
  );
};

export default DashboardHome;