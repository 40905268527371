import { Feed } from 'feed';
import { getPublishedNews } from './newsService';
import { storage } from '../lib/firebase';
import { ref, uploadString } from 'firebase/storage';

// Helper function to sanitize text for XML
function sanitizeXML(text: string): string {
  if (!text) return '';
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;')
    // Remove any non-valid XML characters
    .replace(/[\x00-\x08\x0B\x0C\x0E-\x1F\x7F]/g, '');
}

export async function generateRssFeed() {
  try {
    const news = await getPublishedNews();
    const siteURL = 'https://aiisatool.com';
    const date = new Date();

    const feed = new Feed({
      title: sanitizeXML('AI is a Tool - Latest AI News'),
      description: sanitizeXML('Latest artificial intelligence news, updates, and announcements'),
      id: siteURL,
      link: siteURL,
      language: 'en',
      image: `${siteURL}/logo.png`,
      favicon: `${siteURL}/favicon.ico`,
      copyright: sanitizeXML(`All rights reserved ${date.getFullYear()}, AI is a Tool`),
      updated: date,
      generator: 'AI is a Tool News Feed',
      feedLinks: {
        rss2: `${siteURL}/rss/news.xml`
      },
      author: {
        name: 'AI is a Tool',
        email: 'info@aiisatool.com',
        link: siteURL
      }
    });

    news.forEach(article => {
      feed.addItem({
        title: sanitizeXML(article.title),
        id: `${siteURL}/news/${article.slug}`,
        link: `${siteURL}/news/${article.slug}`,
        description: sanitizeXML(article.excerpt),
        content: sanitizeXML(article.content),
        author: [{
          name: sanitizeXML(article.author),
          email: 'info@aiisatool.com',
          link: siteURL
        }],
        date: new Date(article.publishedAt),
        category: article.tags?.map(tag => ({
          name: sanitizeXML(tag)
        }))
      });
    });

    // Generate RSS 2.0 feed
    const rssXml = feed.rss2();
    
    // Upload to Firebase Storage
    const rssRef = ref(storage, 'public/rss/news.xml');
    await uploadString(rssRef, rssXml, 'raw', {
      contentType: 'application/rss+xml; charset=UTF-8'
    });

    return true;
  } catch (error) {
    console.error('Error generating RSS feed:', error);
    return false;
  }
} 