import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './index.css';
import FirebaseProvider from './components/FirebaseProvider';

const root = document.getElementById('root');
if (!root) throw new Error('Root element not found');

createRoot(root).render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <FirebaseProvider>
          <App />
        </FirebaseProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);