import React from 'react';
import { Tool } from '../types/Tool';
import ResponsiveImage from './ResponsiveImage';
import { getWebPUrl } from '../utils/imageUtils';

interface FeaturedToolCardProps {
  tool: Tool;
  onClick: () => void;
}

const FeaturedToolCard: React.FC<FeaturedToolCardProps> = ({ tool, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-gray-700 rounded-lg p-2 cursor-pointer hover:bg-gray-600 transition-colors"
    >
      <div className="flex items-center gap-3">
        {tool.imageUrl && (
          <div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0">
            <ResponsiveImage
              src={tool.imageUrl}
              alt={tool.name}
              className="w-full h-full object-cover"
              size="_854x480"
              loading="lazy"
            />
          </div>
        )}
        <div className="flex-grow">
          <h3 className="text-white font-medium">{tool.name}</h3>
          <p className="text-sm text-gray-400">{tool.category}</p>
        </div>
      </div>
    </div>
  );
};

export default FeaturedToolCard;

