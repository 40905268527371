import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BookOpen } from 'lucide-react';
import { BlogPost } from '../types/BlogPost';
import { getPublishedPosts } from '../services/blogService';
import SchemaMarkup from './SchemaMarkup';
import Breadcrumbs from './Breadcrumbs';
import ResponsiveImage from './ResponsiveImage';
import MetaTags from './MetaTags';

const BlogPage: React.FC = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        console.log('BlogPage: Starting to fetch posts...');
        
        // Check if we're already loading
        if (!loading) {
          setLoading(true);
          setError(null);
        }

        const fetchedPosts = await getPublishedPosts();
        
        // Validate the response
        if (!Array.isArray(fetchedPosts)) {
          throw new Error('Invalid response format: expected an array of posts');
        }

        console.log('BlogPage: Successfully fetched posts:', fetchedPosts.length);
        setPosts(fetchedPosts);
        
      } catch (error) {
        console.error('BlogPage: Detailed error information:', {
          errorType: error?.constructor?.name,
          message: error instanceof Error ? error.message : 'Unknown error',
          stack: error instanceof Error ? error.stack : 'No stack trace',
          raw: error
        });
        
        setError(error instanceof Error ? error.message : 'Failed to load blog posts');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center gap-3 mb-8">
              <BookOpen className="h-8 w-8 text-indigo-400" />
              <h1 className="text-3xl font-bold">Blog</h1>
            </div>
            <div className="text-center text-gray-400">
              <div className="animate-pulse">Loading posts...</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 text-white">
        <div className="container mx-auto px-4 py-12">
          <div className="max-w-4xl mx-auto">
            <div className="flex items-center gap-3 mb-8">
              <BookOpen className="h-8 w-8 text-indigo-400" />
              <h1 className="text-3xl font-bold">Blog</h1>
            </div>
            <div className="bg-red-900/20 border border-red-500/20 rounded-lg p-4">
              <p className="text-red-400">
                {error}
              </p>
              <button 
                onClick={() => window.location.reload()}
                className="mt-4 px-4 py-2 bg-red-500/20 hover:bg-red-500/30 rounded-lg text-red-300 text-sm"
              >
                Try Again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <MetaTags 
        title="AI Tools Blog - Latest Articles & Insights | AI Is A Tool"
        description="Explore the latest articles about AI tools, development tips, implementation guides, and industry insights. Stay updated with the newest AI technology trends."
        content={`
          Latest Blog Posts
          Total Articles: ${posts.length}
          Topics: ${Array.from(new Set(posts.flatMap(post => post.tags || []))).join(', ')}
          Updated: ${posts[0]?.publishedAt ? new Date(posts[0].publishedAt).toISOString().split('T')[0] : 'regularly'}
        `}
      />

      <SchemaMarkup 
        type="website"
        data={{
          "@context": "https://schema.org",
          "@type": "Blog",
          name: "AI Is A Tool Blog",
          description: "Latest articles about AI tools, development, and implementation",
          publisher: {
            "@type": "Organization",
            name: "AI Is A Tool",
            url: window.location.origin,
            logo: {
              "@type": "ImageObject",
              url: `${window.location.origin}/logo.png` // Update with actual logo path
            }
          },
          blogPost: posts.map(post => ({
            "@type": "BlogPosting",
            headline: post.title,
            description: post.excerpt,
            image: post.featuredImage,
            author: {
              "@type": "Person",
              name: post.author
            },
            datePublished: post.publishedAt,
            dateModified: post.updatedAt || post.publishedAt,
            keywords: post.tags?.join(', '),
            url: `${window.location.origin}/blog/${post.slug}`,
            isAccessibleForFree: true,
            inLanguage: "en-GB"
          })),
          breadcrumb: {
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                item: {
                  "@id": window.location.origin,
                  name: "Home"
                }
              },
              {
                "@type": "ListItem",
                position: 2,
                item: {
                  "@id": `${window.location.origin}/blog`,
                  name: "Blog"
                }
              }
            ]
          }
        }}
      />

      <div className="container mx-auto px-4 py-12">
        <div className="mb-6">
          <Breadcrumbs />
        </div>

        <div className="max-w-8xl mx-auto">
          <div className="flex items-center gap-3 mb-8">
            <BookOpen className="h-8 w-8 text-indigo-400" />
            <h1 className="text-3xl font-bold">Blog</h1>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-6">
            {posts.length === 0 ? (
              <div className="text-center py-12 col-span-full">
                <div className="text-gray-400 mb-4">No blog posts available yet.</div>
                <p className="text-sm text-gray-500">Check back soon for new content!</p>
              </div>
            ) : (
              posts.map((post) => (
                <article 
                  key={post.id} 
                  className="bg-gray-800/50 border border-gray-700 rounded-lg overflow-hidden hover:bg-gray-800/70 transition-all duration-200"
                >
                  <Link 
                    to={`/blog/${post.slug}`}
                    className="block group"
                  >
                    {post.featuredImage && (
                      <div className="aspect-video w-full">
                        <ResponsiveImage
                          src={post.featuredImage}
                          alt={post.title}
                          className="w-full h-full object-cover"
                          size="_320x180"
                          loading="lazy"
                        />
                      </div>
                    )}
                    
                    <div className="p-4">
                      <h3 className="text-lg font-semibold text-white group-hover:text-indigo-400 transition-colors mb-2">
                        {post.title}
                      </h3>
                      
                      <div className="text-xs text-gray-400 mb-3">
                        <time dateTime={post.publishedAt}>
                          {new Date(post.publishedAt).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })}
                        </time>
                        <span className="mx-2">•</span>
                        <span>{post.author}</span>
                      </div>

                      <p className="text-sm text-gray-300 mb-3 line-clamp-2">
                        {post.excerpt}
                      </p>

                      {post.tags && post.tags.length > 0 && (
                        <div className="flex flex-wrap gap-1">
                          {post.tags
                            .filter(tag => 
                              tag.toLowerCase() !== 'uncategorized' && 
                              tag.toLowerCase() !== 'uncategorised'
                            )
                            .slice(0, 3)
                            .map((tag) => (
                              <span
                                key={tag}
                                className="px-2 py-0.5 bg-gray-700/50 rounded-full text-xs text-gray-300"
                              >
                                {tag}
                              </span>
                            ))}
                        </div>
                      )}
                    </div>
                  </Link>
                </article>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;