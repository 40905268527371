import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import CookieConsent from './components/CookieConsent';
import Header from './components/Header';
import HomePage from './components/HomePage';
import ProductPage from './components/ProductPage';
import CategoryPage from './components/CategoryPage';
import ToolsPage from './components/ToolsPage';
import AllToolsPage from './components/AllToolsPage';
import CategoriesPage from './components/CategoriesPage';
import Footer from './components/Footer';
import MetaTags from './components/MetaTags';
import UserMenu from './components/UserMenu';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import AdminRoute from './components/AdminRoute';
import DashboardHome from './components/DashboardHome';
import ImportToolsPage from './components/ImportToolsPage';
import SubmitToolPage from './components/SubmitToolPage';
import AboutPage from './components/AboutPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import TermsPage from './components/TermsPage';
import ContactPage from './components/ContactPage';
import BlogPage from './components/BlogPage';
import BlogPostPage from './components/BlogPostPage';
import AdminBlogPage from './components/AdminBlogPage';
import SitemapGenerator from './components/SitemapGenerator';
import AdminAdsPage from './components/AdminAdsPage';
import AdminBlogList from './components/AdminBlogList';
import AdminToolList from './components/AdminToolList';
import AdminBlogEdit from './components/AdminBlogEdit';
import AdminToolEdit from './components/AdminToolEdit';
import AdminToolForm from './components/AdminToolForm';
import Custom404 from './components/Custom404';
import NewsPage from './components/NewsPage';
import NewsPostPage from './components/NewsPostPage';
import AdminNewsList from './components/AdminNewsList';
import AdminNewsCreate from './components/AdminNewsCreate';
import AdminNewsEdit from './components/AdminNewsEdit';
import NewsletterPopup from './components/NewsletterPopup';
import './styles/globals.css';
import { generateRssFeed } from './services/rssService';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

function App() {
  useEffect(() => {
    // Add reCAPTCHA script dynamically
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LdDJY0qAAAAAAWgqwOSxp38z4eMvLPv4l0SrWqo';
    script.async = true;
    document.head.appendChild(script);

    // Generate RSS feed when app loads
    const generateInitialFeed = async () => {
      try {
        await generateRssFeed();
        console.log('RSS feed generated successfully');
      } catch (error) {
        console.error('Failed to generate RSS feed:', error);
      }
    };

    generateInitialFeed();

    return () => {
      // Cleanup
      document.head.removeChild(script);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Lf9kPspAAAAAB4ONEgpx9wygCXn6WT7KUZmh1tz"
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'head',
      }}
    >
      <div className="min-h-screen bg-gray-900 text-white">
        <SitemapGenerator />
        <ScrollToTop />
        <CookieConsent />
        <NewsletterPopup />
        <Routes>
          {/* Admin Routes */}
          <Route path="/admin-console" element={<AdminLogin />} />
          <Route
            path="/admin-console/*"
            element={
              <AdminRoute>
                <AdminDashboard />
              </AdminRoute>
            }
          >
            {/* Primary Admin Routes */}
            <Route path="dashboard" element={<DashboardHome />} />
            <Route path="import" element={<ImportToolsPage />} />
            <Route path="submit" element={<SubmitToolPage />} />
            <Route path="blog" element={<AdminBlogPage />} />
            
            {/* News Admin Routes */}
            <Route path="news" element={<AdminNewsCreate />} />
            <Route path="news/edit/:id" element={<AdminNewsEdit />} />
            <Route path="news-posts" element={<AdminNewsList />} />

            {/* Additional Admin Routes */}
            <Route path="manage-ads" element={<AdminAdsPage />} />
            <Route path="blog-posts" element={<AdminBlogList />} />
            <Route path="ai-tools" element={<AdminToolList />} />

            {/* Edit Routes for Blog and Tools */}
            <Route path="blog-posts/edit/:id" element={<AdminBlogEdit />} />
            <Route path="ai-tools/edit/:id" element={<AdminToolEdit />} />
            <Route path="ai-tools/new" element={<AdminToolForm />} />

            {/* Admin 404 Route */}
            <Route path="*" element={<Custom404 />} />
          </Route>

          {/* Public Routes */}
          <Route
            path="/*"
            element={
              <>
                <MetaTags />
                <Header userMenu={<UserMenu />} />
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/tools" element={<ToolsPage />} />
                  <Route path="/all-tools" element={<AllToolsPage />} />
                  <Route path="/tool/:id" element={<ProductPage />} />
                  <Route path="/categories" element={<CategoriesPage />} />
                  <Route path="/category/:categoryId" element={<CategoryPage />} />
                  <Route path="/category/:categoryId/:subcategoryId" element={<CategoryPage />} />
                  <Route path="/blog" element={<BlogPage />} />
                  <Route path="/blog/:slug" element={<BlogPostPage />} />
                  
                  {/* News Public Routes */}
                  <Route path="/news" element={<NewsPage />} />
                  <Route path="/news/:slug" element={<NewsPostPage />} />
                  
                  <Route path="/about" element={<AboutPage />} />
                  <Route path="/privacy" element={<PrivacyPolicyPage />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  
                  {/* Public 404 Route - Must be last */}
                  <Route path="*" element={<Custom404 />} />
                </Routes>
                <Footer />
              </>
            }
          />
        </Routes>
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;


