export const getWebPUrl = (originalUrl: string, preferredSize?: string): string => {
  try {
    // Safety check for empty or invalid URLs
    if (!originalUrl || !originalUrl.includes('/')) {
      return originalUrl;
    }

    // If URL already contains _[dimensions].webp, it's already a WebP variant
    if (originalUrl.includes('_854x480.webp') || originalUrl.includes('_320x180.webp')) {
      return originalUrl;
    }

    const url = new URL(originalUrl);
    
    // Check if this is a Firebase Storage URL
    if (!url.pathname.includes('/o/')) {
      return originalUrl;
    }

    const pathSegments = url.pathname.split('/o/');
    if (pathSegments.length < 2) return originalUrl;

    const filePath = decodeURIComponent(pathSegments[1]);
    
    // Handle case where the URL might not have an extension
    const lastDotIndex = filePath.lastIndexOf('.');
    const filePathWithoutExtension = lastDotIndex !== -1 
      ? filePath.substring(0, lastDotIndex)
      : filePath;
    
    // Add size suffix if not already present
    const sizeVariant = preferredSize || '_854x480';
    const webpPath = `${filePathWithoutExtension}${sizeVariant}.webp`;
    
    // Construct the new URL
    const bucketPath = url.pathname.split('/b/')[1].split('/o/')[0];
    return `${url.origin}/v0/b/${bucketPath}/o/${encodeURIComponent(webpPath)}${url.search}`;
  } catch (error) {
    console.error('Error in getWebPUrl:', error, 'Original URL:', originalUrl);
    return originalUrl;
  }
}; 